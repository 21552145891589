import initState1 from '../../softdata.json';
import { ADD_COMPLAIN } from "./../action/reportAction";

const initState = {DataComplain:[...initState1[0].report]}


const reportReducer = (state = initState, action) => {
    switch (action.type) {
        
        case ADD_COMPLAIN:
            const ID=state.DataComplain.length>0 ? String(parseInt(state.DataComplain[state.DataComplain.length-1].id)+1) : "1"
            state.DataComplain=[...state.DataComplain,{id:ID,...action.data}];
            return state

        default:
            return state; 
    }
   
}
export default reportReducer;