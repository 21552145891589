import React, { useState,Fragment,  useMemo} from 'react';
import { NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
//import {useSearchParams} from 'react-router-dom'

  
function PlaceList(props) {

    
        const [place]=useState(props.place)
        const [listTowns,setListowns]=useState(props.towns.map(t=>t.name.toLowerCase()))
        useMemo(()=>{
            setListowns(props.towns.map(t=>t.name.toLowerCase()))
       },[props.towns])
    
        const generateLink=name=>{
            const mylink=`/all-listings-list?pl=${name.replaceAll('&','_')}`;
            return mylink
        }
        //pl=place
        const count=(pl)=>{
            
            return Object.values(props.list).filter(item=>item.location===pl).length
 
         }
      
 
        return (
            <Fragment>
                <div className="col-lg-12">
                                <div className="place-list-wrapper">
                                     <ul className="list-unstyled">
            {
                Object.values(place).filter(l=>{return listTowns.includes(l.location?.toLowerCase())}).slice(0, 4).map((value, key) => {
                    const {location } = value;
                    return (                       
                            
                        <li key={key}><NavLink to={generateLink(location)}>{location}({count(location)})</NavLink></li>                 
                    )
                })
            }
              </ul>
                  </div>
                </div>
            </Fragment>

        )
    }

const mapStateToProps =  (state) => {
    return {
        place : state.place,
        list:state.list.Listing,
        country:state.country
        
    }
}
export default connect(mapStateToProps)(PlaceList)

