const initState = {detailsProfile:[]}

export const SET_DETAIL_PROFIL='SET_DETAIL_PROFIL'

const authorProfileReducer = (state = initState, action) => {
    switch(action.type){
          
        case SET_DETAIL_PROFIL:
            
            const info=state.detailsProfile.findIndex(d=>d.currentUserId===action.payload.currentUserId)
           
            if(info>-1)
            {
             state.detailsProfile[info]=action.payload
            }
            else{
             state.detailsProfile=[...state.detailsProfile,action.payload]
            }
            
           return state  
       
        default:
        return state;
    }
}
export default authorProfileReducer




// // var userDetails=localStorage.getItem('user_details')

// const initState = 
//      {
//     defaultPicture:"./assets/img/author-profile.jpg",       
//     profilePicture:[],
//     detailsProfile:[]
//         }

// export const SET_PROFIL='SET_PROFIL'
// export const DELETE_PROFIL='DELETE_PROFIL'
// export const SET_DETAIL_PROFIL='SET_DETAIL_PROFIL'
// const authorProfileReducer = (state = initState, action) => {
//     switch(action.type){
//         case SET_PROFIL :   
//          state.profilePicture=[...state.profilePicture,action.data]
//         return state 
//         case DELETE_PROFIL:
//         state.profilePicture=Object.values(initState.profilePicture).filter(p=>p.userId!==action.payload)     
//         return state     
//         case SET_DETAIL_PROFIL:
//             localStorage.setItem('user_details',action.data)
//             return JSON.parse(localStorage.getItem('user_details'));   
//         default:
//         return state;
//     }
// }
// export default authorProfileReducer