import React,{Fragment}from 'react'
import { Footer } from '../layout/footer';
import { BreadcrumbWraper } from '../content/element/breadcrumb';
import Header from '../layout/header';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

 function Advantages(props) {
     const {about}=props
    const {About2}=about
    const {t}=useTranslation()
        const light = props.logo[0].light;
        return (
            <Fragment>
                {/* Header section start */}
                <section className="header-breadcrumb bgimage overlay overlay--dark">
                    <div className="bg_image_holder"><img src="./assets/img/breadcrumb1.jpg" alt="" /></div>
                    <div className="mainmenu-wrapper">
                        <Header logo={light} class="menu--dark" />                    
                    </div>
                    {/* <!-- ends: .mainmenu-wrapper --> */}
                    <BreadcrumbWraper title="Advantages" />
                </section>
                {/* Header section end */}
    
                <section className="about-contents section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 contents-wrapper">
                            <div className="contents">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 col-sm-6">
                                            <h1>{t('Why List on')} <span>HOGO Places</span></h1>
                                            <ul className="list-unstyled list-features p-top-15">
                                                
                                               {
                                               Object.values(About2.list).map((item,i) => {

                                                    return ( 
                                                        <li key={i}>
                                                            <div className="list-count"><span>{item.id}</span></div>
                                                            <div className="list-content">
                                                                <h4>{item.title}</h4>
                                                                <p>{item.content}</p>
                                                            </div>
                                                        </li>
                                                        )

                                               })
                                                
                                                }
                                                
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 offset-lg-1 text-right col-sm-6 mt-5 mt-md-0">
                                            <img src={About2.image3} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               <Footer />
            </Fragment>
        )
    }
    
    const mapStateToProps = state => {
        return {        
            logo: state.logo,
            about:state.aboutPage
        }
    }
    
    export default connect(mapStateToProps)(Advantages);