import { t } from 'i18next';
import React, { Fragment } from 'react';

export  const ContentStory= ({data})=> {

        return (
            <Fragment>
                <div className="atbd_content_module atbd_listing_details">
                    <div className="atbd_content_module__tittle_area">
                        <div className="atbd_area_title">
                            <h4><span className="la la-file-text-o"></span>{t('Listing.details.description')}</h4>
                        </div>
                    </div>
                    { data[0].description!=='' ?
                    (<div className="atbdb_content_module_contents">
                        <p>{data[0].description}</p>
                    </div>) : (
                       
                       <div className="container">
                           <div className="alert alert-danger" role="alert">
                               {t('Listing.details.alertmessagedescription')}
                           </div>                                
                       </div>                        
                  
               )
                    }
                </div>
            </Fragment>
        )
    
}




export const DefinitionStory= ({data})=> {

    return (
        <Fragment>
            <div className="atbd_content_module atbd_listing_details">
                <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                        <h4><span className="la la-file-text-o"></span>{t('Listing.details.pricepolicy')}</h4>
                    </div>
                </div>
                { data[0].shortdescription!=='' ?
                (<div className="atbdb_content_module_contents">
                    <p>{data[0].shortdescription}</p>
                </div>) : (
                   
                   <div className="container">
                       <div className="alert alert-danger" role="alert">
                           {t('Listing.details.alertmessageshortdescription')}
                       </div>                                
                   </div>                        
              
           )
                }
            </div>
        </Fragment>
    )

}


