import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import {Provider} from 'react-redux';
import reactStore from './store/index';
import './i18n';


const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
    <React.Suspense fallback="loading...">
        <Provider store={reactStore}>
            <App />
        </Provider>
    </React.Suspense>
)

