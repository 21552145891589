import {combineReducers} from 'redux';
import catReducer from './catReducer';
import listReducer from './listingReducer';
import placeReducer from './placeReducer';
import testiReducer from './testiReducer';
import clientReducer from './clientReducer';
import galleryReducer from './galleryReducer';
import gallery2Reducer from './gallery2Reducer';
import blogReducer from './blogReducer';
import usersReducer from './usersReducer';
import loginReducer from './loginReducer';
import logoReducer from './logoReducer';
import setupReducer from './setupReducer';
import socialmediaReducer from "./socialmediaReducer";
import claimReducer from "./claimReducer";
import statesReducer from './statesReducer';
import citiesReducer from './citiesReducer';
import pricingrangeReducer from "./princingrangeReducer";
import featureReducer from './featureReducer';
import favouriteReducer from './favouriteReducer';
import reportReducer from './reportReducer';
import newsletterReducer from './newsletterReducer';
import aboutReducer from './aboutPageReducer';
import countryReducer from './countryReducer';
import searchReducer from './searchReducer';
import reviewReducer from './reviewReducer';
import countReducer from './countReducer';
import authorProfileReducer from './author-profilReducer';
import ProfileReducer from './profileReducer';
import commentReducer from './commentReducer';


const rootReducer = combineReducers({
    category: catReducer,
    list: listReducer, //new
    place: placeReducer,
    testimonial: testiReducer,
    client: clientReducer,
    gallery: galleryReducer,
    gallery2: gallery2Reducer,
    blog: blogReducer,
    users: usersReducer,
    login: loginReducer,
    logo: logoReducer,
    setup: setupReducer,
    socialmedia:socialmediaReducer, //new
    claim:claimReducer,
    states:statesReducer,
    cities:citiesReducer,
    pricingRange:pricingrangeReducer,
    features:featureReducer,
    favourites:favouriteReducer,
    reports:reportReducer,
    newsletters:newsletterReducer,
    aboutPage:aboutReducer,
    country:countryReducer,
    search:searchReducer,
    review:reviewReducer,
    countView:countReducer,
    authorProfiles:authorProfileReducer,
    profiles:ProfileReducer,
    comment:commentReducer
});
export default rootReducer;