
import React, {  Fragment, useRef, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { NavLink,useSearchParams,useLocation} from 'react-router-dom';
import ListingCardGrid12 from '../content/element/card/card-listion-grid-12';
import ListingCardGrid6 from '../content/element/card/card-listing-grid-6';
import AsyncSelect from 'react-select/async';
import { GET_MY_FAVOURITE } from "../../store/action/favouriteAction";
import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/slider.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/slider';
import {searchAction,fetch_data} from '../../store/action/searchAction'
import {components} from 'react-select'
import {getCountry} from '../../store/action/countryAction'
import { useTranslation } from 'react-i18next';

function ListingList(props) {
    const {t}=useTranslation()
const [listTowns,setListowns]=useState(props.country.towns.map(t=>t.name.toLowerCase()))

   const[state] = useState({
       list:Object.values(props.list).filter(l=> listTowns.includes(l.location?.toLowerCase())),
        cat: props.cat,
        count: 0,
        loginIn:props.login,
      
    })
   
    // const[state,setState]=useState ({
    //     list: props.list.Listing,
    //     cate: props.list.Listing,
       
    //     count: 0
    // })

//     let email=state.loginIn !==null ? state.loginIn[0]?.email : null
// //************************************************************************************************************************************ */

// useMemo(() => {
//     if (state.loginIn !==null) {        
//         const data={email}
//         props.getMyFavourite(data)
//     }
//    // eslint-disable-next-line react-hooks/exhaustive-deps
//    },[email])




    const location=useLocation();
    const [searchParams] = useSearchParams();
    const [searchList,setSearchList]=useState(Object.values(props.search).filter(l=>{return listTowns.includes(l.location?.toLowerCase())}))
    const [features,setFeatures]=useState([])
    const [filterRating,setFilterRating]=useState([])
    const [storeOpenChecked,setStoreOpenChecked]=useState(false)
    const [onActiveGrid,setOnActiveGrid]=useState(false)
    const {getCountry,fetch_data,searchAction}=props
   
   //cat=category
    // const [cat,setCat]=useState('')
    const [catID,setCatID]=useState(null)
    const prevList=usePrevious(searchList)

   document.title=t('listing.allitems')+"- HOGO PLACES"

         const currentParams = Object.fromEntries([...searchParams])
   useEffect(() => {
                       
             if(Object.values(currentParams).length!==0 && (currentParams.pl || currentParams.q || currentParams.cat))
              { 
                       
             searchAction(currentParams)
              }else{
               fetch_data()
              }      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentParams.pl,currentParams.q,currentParams.cat]);
     
      useMemo(()=>{
        if(currentParams.country)
        {
         getCountry(currentParams.country)

        }
       },[currentParams.country,getCountry])
      useEffect(()=>{
        setListowns(props.country.towns.map(t=>t.name?.toLowerCase()))
   },[props.country.towns])

      useEffect((prevProps)=>{
        if(location.pathname.slice(1)==='all-listings-grid')
        {
       
           setOnActiveGrid(true)
           
        }else{
            setOnActiveGrid(false)
        }
        if(props.search!==prevProps?.search )
        {

            setSearchList(Object.values(props.search).filter(l=>{return listTowns.includes(l.location?.toLowerCase())}))
        }
      
      },[props,listTowns,location])
  
      
      
  

//********************************************************************************************************************************************** */
   useEffect (() => {
        $(document).ready(() => {

            //Range slider light
            var slider_range = $(".slider-range");
            slider_range.each(function () {
                $(this).slider({
                    range: true,
                    min: 0,
                    max: 500,
                    values: [0, 500],
                    slide: function (event, ui) {
                        $(".amount").text("$" + ui.values[0] + " - $" + ui.values[1]);
                    }
                });
            });
            $(".amount").text("$" + slider_range.slider("values", 0) + " - $" + slider_range.slider("values", 1));
        });

    })


    
    
        // sorting here
        const sort = [];

        Object.values(searchList).map(item => 
             sort.push(item)
            )



            const logdIn = () => {
                return props.login
            }

        const sorting = (e) => {
            e.preventDefault();
            
            switch (e.target.getAttribute('href')) {
                case 'new':
                    setSearchList(
                       sort.sort(function(a, b) {
                        var textA = a.createAt;
                        var textB = b.createAt;

                        if (textA < textB) return 1;
                        if (textA > textB) return -1;
                        return 0;
                    })
                )

                break;
                case 'old':
                    setSearchList(
                       sort.sort(function(a, b) {
                        var textA = a.createAt;
                        var textB = b.createAt;

                        if (textA > textB) return 1;
                        if (textA < textB) return -1;
                        return 0;
                    })
                )

                break;
                case 'heigh':

                    setSearchList(
                       sort.sort(function(a, b) {

                        var textA = a.price;
                        var textB = b.price;

                        if (textA < textB) return 1;
                        if (textA > textB) return -1;
                        return 0;
                    })
                )

                break;
                case "low" :

                   setSearchList(
                     sort.sort(function(a, b) {

                            var textA = a.price;
                            var textB = b.price;

                            if (textA < textB) return -1;
                            if (textA > textB) return 1;
                            return 0;
                        })
                    )
                    break;
                    case 'a-z':

                        setSearchList(
                           sort.sort(function(a, b) {

                            var textA = a.title;
                            var textB = b.title;

                            if (textA < textB) return -1;
                            if (textA > textB) return 1;
                            return 0;
                        })
                    );
                    break;

                    case 'z-a':

                        setSearchList(
                            sort.sort(function(a, b) {

                            var textA = a.title;
                            var textB = b.title;

                            if (textA < textB) return 1;
                            if (textA > textB) return -1;
                            return 0;
                        })
                    );
                    break;
                default :

                setSearchList(
                    state.list
                );

            }
        }
        // sorting end

        // filter by search ...
        const search = e => {
            e.preventDefault();
            const value = e.target.value.toLowerCase();
            if(value.length )
           { 
           
                 setSearchList(
              Object.values(state.list).filter(function (item) {
                    return (item.title.toLowerCase().startsWith(value) || item.location.toLowerCase().startsWith(value)) && (catID?item.category===catID:true) ?

                        item : ''

                })
            )
            
           
            }
            else{
   
                  
                    setSearchList(Object.values(state.list).filter(item=>(catID?item.category===catID:true)))
                                        
                    
                    
               
            }
           
        }
     
        const searchLocation = e => {
            e.preventDefault();
            const value = e.target.value.toLowerCase();
            setSearchList(
              Object.values(state.list).filter(function (item) {
                    return item.location?.toLowerCase().startsWith(value) && (catID?item.category===catID:true) ?
                        item : ''

                })
            )
        }
        // filter by search

        // filter by category
     
        const category = value => {
            //console.log(e.target)
            setCatID(value.id)
            // setCat(value.label)
           
            if(value.label !== "All") {
               searchAction({cat:value.label})
                
               
            } else {
                setSearchList(state.list)
            }
        }
       
        // const [min,setMin]=useState()
        // const [max,setMax]=useState()
        // filter by price range
        const range =(e) => {
              e.preventDefault()
            const value = document.getElementById('amount1').innerHTML;
            const max   = value.split('-').pop().split('$').pop().trim();
            const min   = value.split('-').shift().split('$').pop().trim();
             console.log('val',value,"max",max,'min',min)
           
            setSearchList(Object.values(state.list).filter(item1 =>Number(item1.pricing) >= Number(min) && Number(item1.pricing) <= Number(max)  )
            )
           if(catID!==null)
           {
            setSearchList(Object.values(searchList).filter(C=>C.category===catID))
           }
        
             
        }
        
        const handleStoreOpenChecked=()=>{
             
           
            setStoreOpenChecked(!storeOpenChecked)
            
                 
             if(!storeOpenChecked)
            {
                
                setSearchList(
                    Object.values(searchList).filter(item => {
                        return item.opCl==="open" && (catID?item.category===catID:true)
                    })
                )
                
               
            }  else{
                setSearchList(prevList)
              
                }
            }
            
          
        // filter by price range end
          
          const filterCategories = (value) => {
            return Object.values(props.cat).filter((i) =>
              i.label.toLowerCase().includes(value.toLowerCase())
            );
          };
          const loadOptionsCategories = (
             value,
            callback
          ) => {
            setTimeout(() => {
              callback(filterCategories(value));
            }, 1000);
          };

          const customStyles = {
            option: (provided, state) => ({
                ...provided,
                borderBottom: '1px dotted gray',
                backgroundColor: state.isSelected &&'deeppink'
              }),
            control: base => ({
              ...base,
              height: 48,
              minHeight: 48,

            })
          };
          const { Option } = components;
            const IconOption = props => (
            <Option {...props}>
                <span
                className={props.data.icon}
                style={{ width: 36 }}
                alt={props.data.label}
                ></span>
                {props.data.label}
            </Option>
            );
         
          const onChangeFeatures=(e)=>{
         const value=e.target.value
          
                    if(e.target.checked)
                    { 
                       setFeatures([...features,value])
                       }
                    else{
                       setFeatures(Object.values(features).filter(item=>item!==value))                
                       }
          }
                    useMemo(()=>{
                                                
                    if(features.length!==0)
                    {
                     let newList=state.list
                     
                  
                  
                    features.filter(item=>
                        newList=Object.values(newList).filter(
                         i=>i.features.find(f=>f.feature===item) && (catID?i.category===catID:true) ) 
                     )
                  
                   
                    setSearchList(newList)
                    }
                    else{
                        
                        setSearchList(Object.values(state.list).filter(c=>(catID?c.category===catID:true)))
                    }
                    if(filterRating.length!==0)
                    {
                const sortFilterRating=[]
                Object.values(searchList).forEach(item=>{
                     
                   
                        filterRating.forEach(rating=>{      
                            if(Number(item.rating)>=Number(rating) && Number(item.rating)<(Number(rating)+1) && (catID?item.category===catID:true))
                               sortFilterRating.push(item)
                        })
                        
                     
                })
                    setSearchList(sortFilterRating)
                }
           
                        
            // eslint-disable-next-line react-hooks/exhaustive-deps
            },[features, filterRating, state.list, catID])
               
                    
                   
                   
            

          const onChangeRating=e=>{
            const value=e.target.value
            
                    if(e.target.checked)
                    {   
                       setFilterRating([...filterRating,value]) 
                    }
                    else{
                        setFilterRating(Object.values(filterRating).filter(item=>item!==value))
                    }
          }
          
     

     const handleActiveGrid=(validation)=>{
       
        setOnActiveGrid(validation==="active"?true:false)
       
       
     }

        return (
            <Fragment>
                <section className="all-listing-wrapper section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="atbd_generic_header">
                                    <div className="atbd_generic_header_title">

                                        <h4>{t('listing.allitems')}</h4>
                                        <p>{t('listing.total.found')}: {Object.values(searchList).length}</p>
                                       
                                        

                                    </div>{/*<!-- ends: .atbd_generic_header_title -->*/}
                                    <div className="atbd_listing_action_btn btn-toolbar" role="toolbar">
                                        {/* <!-- Views dropdown --> */}
                                        <div className="view-mode">
                                            <NavLink className="action-btn" onClick={()=>handleActiveGrid('active')} to="/all-listings-grid"><span className="la la-th-large"></span></NavLink><NavLink className="action-btn "  onClick={()=>handleActiveGrid('desactive')} to="/all-listings-list"><span className="la la-list"></span></NavLink>
                                        </div>
                                        {/* <!-- Orderby dropdown --> */}
                                        <div className="dropdown">
                                            <a className="action-btn dropdown-toggle" href=" " role="button" id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('listing.sort.by')} <span className="caret"></span></a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                                                <a className="dropdown-item" onClick={sorting} href="a-z">{t('listing.sort.by1')}</a>
                                                <a className="dropdown-item" onClick={sorting} href="z-a">{t('listing.sort.by2')}</a>
                                                <a className="dropdown-item" onClick={sorting} href="new">{t('listing.sort.latest')}</a>
                                                <a className="dropdown-item" onClick={sorting} href="old">{t('listing.sort.oldest')}</a>                                            
                                                <a className="dropdown-item" onClick={sorting} href="low">{t('listing.sort.price1')}</a>
                                                <a className="dropdown-item" onClick={sorting} href="heigh">{t('listing.sort.price2')}</a>                                            
                                            </div>
                                        </div>
                                    </div>{/*<!-- ends: .atbd_listing_action_btn -->*/}
                                </div>{/*<!-- ends: .atbd_generic_header -->*/}
                            </div> {/*<!-- ends: .col-lg-12 -->*/}

                            <div className="col-lg-12 listing-items">
                                <div className="row">
                                    
                                    <div className="col-lg-4 order-lg-0 order-1 mt-5 mt-lg-0">
                                        <div className="listings-sidebar">
                                            <div className="search-area default-ad-search">
                                                <form action="#">
                                                    <div className="form-group">
                                                        <div className="select-basic">
                                                        
                                                                <AsyncSelect 
                                                                cacheOptions
                                                                defaultOptions
                                                                getOptionLabel={e=>e.label}
                                                                getOptionValue={e=>e.label}
                                                                 onChange={category}
                                                                loadOptions={loadOptionsCategories}
                                                                placeholder={t('home.select.category')}
                                                                styles={customStyles}
                                                                components={{ Option: IconOption }}
                                                                name="valueCategory"
                                                                
                                                                    />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" 
                                                            placeholder={t('home.input.search.title')}
                                                            className="form-control" 
                                                            onChange={search}
                                                        />
                                                    </div>{/*<!-- ends: .form-group -->*/}

                                                    <div className="form-group">
                                                        <div className="position-relative">
                                                            <input onChange={searchLocation} type="text" placeholder={t('listing.location1')} className="form-control location-name" />
                                                            <button type="submit" className="locator"><span className="la la-crosshairs"></span></button>
                                                        </div>
                                                    </div>

                                                    <div className="form-group p-bottom-10">
                                                        <div className="price-range rs-primary">
                                                            <p className="d-flex justify-content-between">
                                                                <span>{t('lisintg.pricerange')} </span>
                                                                <span className="amount" style={{zIndex:0}} id="amount1"></span>
                                                            </p>
                                                            <div className="slider-range"  style={{zIndex:0}}></div>                                                                                                                    
                                                        </div>{/*<!-- ends: .price-range -->*/}
                                                    </div>{/*<!-- ends: .form-group -->*/}

                                                    <div className="check-btn">
                                                       
                                                        <div className="btn-checkbox active-color-secondary">
                                                            <label>
                                                                <input type="checkbox" value="1" onClick={range}  /><span className="color-primary"><i className="la la-search"></i>{t('listing.search')}</span>
                                                            </label>
                                                        </div>
                                                        <div className="btn-checkbox active-color-secondary">
                                                            <label>
                                                                <input type="checkbox" value="1" onChange={handleStoreOpenChecked} checked={storeOpenChecked}/> <span className="color-success"><i className="la la-clock-o"></i> {t('listing.opennow')}</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="filter-checklist">
                                                        <h5>{t('listing.features')}</h5>
                                                        <div className="checklist-items feature-checklist hideContent">
                                                        {
                                                                Object.values(props.features).map(
                                                                    (item)=>{
                                                                        return <GenerateList key={item.id} data={item} onChangeFeatures={onChangeFeatures}/>
                                                                    }
                                                                )
                                                                
                                                            }
                                                        </div>
                                                        <a href=" " className="show-link">{t('listing.showmore')}</a>
                                                    </div>{/*<!-- ends: .filter-checklist -->*/}
                                                   
                                                    {/*<!-- ends: .filter-checklist -->*/}
                                                    <div className="filter-checklist">
                                                        <h5>{t('listing.rating')}</h5>
                                                        <div className="sort-rating">
                                                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                                <input type="checkbox" onChange={onChangeRating} value="5"className="custom-control-input" id="customCheck7"  />
                                                                <label className="custom-control-label" htmlFor="customCheck7">
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                                <input type="checkbox" onChange={onChangeRating} value="4" className="custom-control-input" id="customCheck8" />
                                                                <label className="custom-control-label" htmlFor="customCheck8">
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                                <input type="checkbox" onChange={onChangeRating} value="3" className="custom-control-input" id="customCheck9" />
                                                                <label className="custom-control-label" htmlFor="customCheck9">
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                                <input type="checkbox" onChange={onChangeRating} value="2" className="custom-control-input" id="customCheck10" />
                                                                <label className="custom-control-label" htmlFor="customCheck10">
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                                <input type="checkbox" onChange={onChangeRating} value="1" className="custom-control-input" id="customCheck11" />
                                                                <label className="custom-control-label" htmlFor="customCheck11">
                                                                    <span className="active"><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                                <input type="checkbox" onChange={onChangeRating} value="0" className="custom-control-input" id="customCheck12" />
                                                                <label className="custom-control-label" htmlFor="customCheck12">
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                    <span><i className="la la-star"></i></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>{/*!-- ends: .filter-checklist -->*/}
                                                    {/* <a href=" " onClick={noAction} className="btn btn-gradient btn-gradient-two btn-block btn-icon icon-right m-top-40">Search Filter <span className="la la-long-arrow-right"></span></a> */}
                                                </form>{/*<!-- ends: form -->*/}
                                            </div>
                                        </div>
                                    </div> {/* wiget */}

                                    <div className="col-lg-8 order-lg-1 order-0">
                                        <div className="row">
                                        {

                                            Object.values(searchList).length ? (
                                                onActiveGrid? <ListingCardGrid6 list={searchList}  logdIn={logdIn()} /> :<ListingCardGrid12 list={searchList} />

                                            ) : (<div className="col-lg-12"><div className="alert alert-warning" role="alert">Data Not found!</div></div>)
                                        }
                                            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>               
            </Fragment>
        )

    }

let tag=1;
function GenerateList(props){
       let i=tag++
  
    return(
            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                 <input type="checkbox" className="custom-control-input" id={"tag".concat(i)} value={props.data?.id}  onChange={props.onChangeFeatures} />
                  <label className="custom-control-label" htmlFor={"tag".concat(i)} >{props.data?.label}</label>
            </div>
    )

}

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }
                                                             



const mapStateToProps =  (state) => {
    return {
        
      search :state.search,
      list:state.list.Listing,
      cat:state.category,
      login:state.login,
      country:state.country,
      towns:state.country.towns.map(t=>t.name.toLowerCase()),
      favourites:state.favourites,
      features:state.features

        
    }
}
const mapDispatchToProps = dispatch => {
    return {
      searchAction: search=> dispatch(searchAction(search)),
      fetch_data:()=>dispatch(fetch_data()),
      getCountry:name=>dispatch(getCountry(name)),
      getMyFavourite : data => dispatch({type:GET_MY_FAVOURITE,data}),

       
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ListingList);