import initState1 from '../../softdata.json';
const initState={
   reviewList:[]
}

export const FETCH_REVIEW='FETCH_REVIEW'
export const SET_REVIEW='SET_REVIEW'
export const GET_ALL_REVIEW='GET_ALL_REVIEW'
 const reviewReducer=(state=initState,action)=>{

    switch(action.type){
        case FETCH_REVIEW:
        const list=initState1[0].review
        state.reviewList=Object.values(list).filter(item=>item.listing_id===action.payload)
            return state;
        case SET_REVIEW:
         state.reviewList=[...state.reviewList,action.payload]
        return state;
        case GET_ALL_REVIEW:
            const reviews=initState1[0].review
        state.reviewList=Object.values(reviews)
        return state;
         
        default:
           
        return state
    }
  
}
export default reviewReducer