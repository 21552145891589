import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from './page/index';

import AllListingList from './page/all-listing-list';
import ListingDetails from './page/listing-details';
import Category from './page/all-categoris';
import Location from './page/all-location';
import Pricing from './page/pricing-plan';
import Faq from './page/faq';
import About from './page/about';
import Contact from './page/contact';
import Cards from './page/cards';
import HeaderStyle from './page/header-style';
import BlogRightSide from './page/blog-right-side';
import BlogGrid from './page/blog-grid';
import BlogDetails from './page/blog-details';
import AuthProfile from './page/auth-profile';
import AuthDeshbord from './page/auth-deshbord';
import CheckoutBasic from './page/checkout-basic';
import CheckoutAdvanced from './page/advanced';
import Enterprise from './page/enterprise';
import Invoice from './page/invoice';
import AddListing from './page/add-listing/add-listing';
import EditListing from './page/add-listing-edit';
import ResetPassword from './content/element/modal/reset-password';
import PrivacyPolicy from './page/privacy-policy';
import Advantages from './page/advantages';


 
function App(props) {  
  console.log("process.env.PUBLIC_URL => " + process.env.PUBLIC_URL);
  return (
    //<Router basename={process.env.PUBLIC_URL} >    
    <Router basename='' >          
        <Routes>
          <Route exact path = '/' element = {<Index /> } />          
          <Route path = '/all-listings-grid' element = {<AllListingList/> } />          
          <Route path = '/all-listings-list' element = {<AllListingList /> } />          
          <Route path = '/listing-details:id' element = {<ListingDetails /> } />          
          <Route path = '/all-categories' element = {<Category /> } />          
          <Route path = '/all-locations' element = {<Location /> } />          
          <Route path = '/pricing-plans' element = {<Pricing /> } />          
          <Route path = '/faqs' element = {<Faq /> } />          
          <Route path = '/about' element = {<About /> } />          
          <Route path = '/contact' element = {<Contact /> } />          
          <Route path = '/cards' element = {<Cards /> } />          
          <Route path = '/headers' element = {<HeaderStyle /> } />
          <Route path = '/blog-right-sidebar' element = {<BlogRightSide /> } />
          <Route path = '/blog-grid' element = {<BlogGrid /> } />
          <Route path = '/blog-details:id' element = {<BlogDetails /> } />
          <Route path = '/author-profile' element = {<AuthProfile /> } />
          <Route path = '/dashboard-listings' element = {<AuthDeshbord /> } />
          <Route path = '/checkout' element = {<CheckoutBasic /> } />
          <Route path = '/advanced' element = {<CheckoutAdvanced /> } />
          <Route path = '/enterprise' element = {<Enterprise /> } />
          <Route path = '/invoice' element = {<Invoice /> } />
          <Route path = '/add-listing' element = {<AddListing /> } />
          <Route path = '/add-listing-edit:id' element = {<EditListing /> } />
          <Route path = '/reset-password' element = {<ResetPassword /> } />
          <Route path = '/privacy-policy' element = {<PrivacyPolicy /> } />
          <Route path = '/advantages' element = {<Advantages /> } />
        </Routes>
    </Router>    
  );
}
export default App;
