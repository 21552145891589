import { t } from 'i18next';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment/moment';
import { getI18n } from 'react-i18next';
import Services from "../../../store/service/services";


const noAction = e => e.preventDefault();

export class SellerInfo extends Component {
    render() {
        return (
            <Fragment>
                <div className="widget-body atbd_author_info_widget">
                    <div className="atbd_avatar_wrapper">
                        <div className="atbd_review_avatar">
                            <img src="./assets/img/avatar-60x60.jpg" alt="AvatarImage" />
                        </div>
                        <div className="atbd_name_time">
                            <h4>Zephy Real Estate <span className="verified" data-toggle="tooltip" data-placement="top" title="Verified"></span></h4>
                            <span className="review_time">Posted 6 days ago</span>
                        </div>
                    </div>
                    {/* <!-- ends: .atbd_avatar_wrapper --> */}
                    <div className="atbd_widget_contact_info">
                        <ul>
                            <li>
                                <span className="la la-map-marker"></span>
                                <span className="atbd_info">25 East Valley Road, Michigan</span>
                            </li>
                            <li>
                                <span className="la la-phone"></span>
                                <span className="atbd_info">(213) 995-7799</span>
                            </li>
                            <li>
                                <span className="la la-envelope"></span>
                                <span className="atbd_info">support@aazztech.com</span>
                            </li>
                            <li>
                                <span className="la la-globe"></span>
                                <NavLink to="/at_demo" className="atbd_info" onClick={noAction}>www.aazztech.com</NavLink>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- ends: .atbd_widget_contact_info --> */}
                    <div className="atbd_social_wrap">
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-facebook-f"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-twitter"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-google-plus-g"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-linkedin-in"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-dribbble"></span></NavLink></p>
                    </div>
                    {/* <!-- ends: .atbd_social_wrap --> */}
                    <NavLink onClick={noAction} to="/profile" className="btn btn-outline-primary btn-block">View Profile</NavLink>
                </div>
            </Fragment>
        )
    }
}

export const BusinessInfo = ({data}) => {
    console.log(data)
    const {v4v47j7j,openHours}=data[0]
    console.log(openHours,v4v47j7j)

    const getCurrentDay=()=>{
        console.log(moment().format('dddd').toLowerCase())
        return moment().format('dddd').toLowerCase()
    }

    const getValueTranslation = (data) =>{
        switch (data) {
            case "saturday":
                return t('Edit.listing.saturday');
            case "sunday":
                return t('Edit.listing.sunday');
            case "monday":
                return t('Edit.listing.monday');
            case "tuesday":
                return t('Edit.listing.tuesday');
            case "wednesday":
                return t('Edit.listing.wednesday');
            case "thursday":
                return t('Edit.listing.thursday');
            case "friday":
                return t('Edit.listing.friday');
        
            default:
                break;
        }
    
    }


    const trnChar = (data) => {
        const trn=data.length>1 ? data : '0'+data
        return trn
    }


    const convertHourtoEn = (data) => {
        let value= data.split(':')
        value[0]=parseInt(value[0])
        let hour=12-value[0]
        
        if (hour>0) {
             return data + ' am'
        }
        if(hour<0) {
             data=trnChar(String(-(hour)))+':'+value[1]
             console.log(data)
             return data + ' pm'
        }
        if (value[0]===12) {
             return String(12)+':'+value[1]+' pm'
        }
        if (value[0]===0) {
         return String(12)+':'+value[1]+' am'
    }
     }
 
     const convertHourtoFr = (data) =>{
         let value=data.replace(':','h')
         return value
     }
 

    const convertToHours=()=>{
        const currentHour=moment().format('LT')
        let hours=null
        let currentTime=null
        const tab=currentHour.split(' ')
        const tabHour=tab[0].split(':')
        tabHour.push(tab[1])
        const AM_PM=tabHour[2]
        const value=parseInt(tabHour[0])
        switch (AM_PM) {
            case 'PM':
               hours=value<12 ? value + 12 : value===12 ? 12 : -1
                break;
            case 'AM':
               hours=value<12 ? value : value===12 ? 0 : -1
                break;
            default:
                break;
        }
        const minHour=parseInt(tabHour[1])
        currentTime={hours,minHour}
        return currentTime
    }

    const openedOrClosed=()=>{
        const openOrclose={opening:"OPEN NOW",closing:"CLOSE NOW"}
        const currentDay=getCurrentDay()
        const dataHours=Services.filterByName(openHours,null,currentDay)
        console.log(dataHours.isclose)
        if(v4v47j7j){
            return openOrclose.opening
        }
        if (!dataHours.isclose) {
            const closinghour=dataHours.closinghour.split(':')
            const openinghour=dataHours.openinghour.split(':')
            console.log(closinghour,openinghour)
            
            const currentTime=convertToHours()
            if (currentTime.hours>parseInt(openinghour[0]) && currentTime.hours<parseInt(closinghour[0])) {
                return openOrclose.opening
            }
            if (currentTime.hours===parseInt(openinghour[0])) {

                if (currentTime.minHour<parseInt(openinghour[1])) {
                    return openOrclose.closing   
                }else{
                    return openOrclose.opening
                }
            }
            if (currentTime.hours===parseInt(closinghour[0])) {

                if (currentTime.minHour<parseInt(closinghour[1])) {
                    return openOrclose.opening    
                }else{
                    return openOrclose.closing
                }
            }
        } else {
            return openOrclose.closing
        }
      return openOrclose.closing
    }



    const getCurrentLanguage = () =>{
        return getI18n().language
    }

    const convertOpenOrClose = (data) =>{
        
        if (data==='OPEN NOW') {
            return t('Listing.details.openednow')
        }
        if (data==='CLOSE NOW') {
            return t('Listing.details.closednow')
            
        }
    }
   
        return (
            <Fragment>

                <div className="widget atbd_widget widget-card">
                    <div className="atbd_widget_title">
                        <h4><span className="la la-clock-o"></span>{t('Listing.details.bhours')}</h4>
                        <span className={openedOrClosed()==="OPEN NOW" ? "badge badge-success" : "badge badge-danger"}>{convertOpenOrClose(openedOrClosed())}</span>
                    </div>
                    {/* <!-- ends: .atbd_widget_title --> */}
                    <div className="directory_open_hours">
                        <ul>
                            {
                             v4v47j7j ? 
                                    (<li className="atbd_open atbd_today">
                                             <span>{t('Listing.details.hourv4')}</span>
                                    </li>) :   
                            Object.values(openHours).length>0 ?
                            (Object.values(openHours).map((item,index)=>{
                                if(! item.isclose){
                                    return (<li className={item.day.toLowerCase()===getCurrentDay() ? "atbd_today atbd_open" : "atbd_open"} key={index}>
                                                <span className="day">{getValueTranslation(item.day)}</span>
                                                <div className="atbd_open_close_time">
                                                    <span className="time">{getCurrentLanguage()==='en' ? convertHourtoEn(item.openinghour) : getCurrentLanguage()==='fr' ? convertHourtoFr(item.openinghour):null}</span> - <span className="time">{getCurrentLanguage()==='en' ? convertHourtoEn(item.closinghour) : getCurrentLanguage()==='fr' ? convertHourtoFr(item.closinghour):null}</span>
                                                </div>
                                            </li>)
                                }else{
                                     return (<li className={item.day.toLowerCase()===getCurrentDay() ? " atbd_today" : "atbd_closed"} key={index}>
                                                    <span className="day">{getValueTranslation(item.day)}</span>
                                                    <span>{t('Listing.details.closed')}</span>
                                            </li>)

                                } 

                                        })) : (<li className="atbd_closed">
                                                    <span>{t('Listing.details.not.available')}</span>
                                            </li>)                           
                            }
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    
}



export function CategoryWithIcon (props){

    const generateLink=name=>{
        const mylink=`/all-listings-list?cat=${name.replaceAll('&','_')}`;
       //console.log(mylink)
        return mylink
    }
        return (
            <Fragment>

                <div className="widget atbd_widget widget-card">
                    <div className="atbd_widget_title">
                        <h4><span className="la la-bookmark"></span> Category with Icon</h4>
                    </div>{/*<!-- ends: /.atbd_widget_title -->*/}
                    <div className="widget-body atbdp-widget-categories">
                        <ul className="atbdp_parent_category">
                        
                        {
                            Object.values(props.category).map(cat=>{
                                const {id,label,icon}=cat
                               return(
                                 <li>
                                <NavLink  key={id} to={generateLink(label)}><span className={icon}></span>{label}</NavLink>
                                </li>
                               )
                            })
                        }
                            {/* <li>
                                <NavLink onClick={noAction} to="/at_demo"><span className="la la-money"></span>Business</NavLink>
                            </li> */}
                            {/* <li>
                                <NavLink onClick={noAction} to="/at_demo"><span className="la la-heartbeat"></span>Health Care</NavLink>
                            </li> */}
                            {/* <li>
                                <NavLink onClick={noAction} to="/at_demo"><span className="la la-laptop"></span>Technology</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={noAction} to="/at_demo"><span className="la la-eject"></span>Conference</NavLink>
                                <span className="cat-trigger"></span>
                                <ul className="atbdp_child_category">
                                    <li><NavLink to="/at_demo">Event</NavLink></li>
                                    <li><NavLink to="/at_demo">Meeting</NavLink></li>
                                    <li><NavLink to="/at_demo">Sports</NavLink></li>
                                    <li><NavLink to="/at_demo">Business</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to="/at_demo"><span className="la la-leaf" onClick={noAction}></span>Agriculture</NavLink>
                            </li>
                            <li>
                                <NavLink to="/at_demo"><span className="la la-calculator" onClick={noAction}></span>Food and Fitness</NavLink>
                            </li> */}
                        </ul>
                    </div>{/*<!-- ends: .atbdp -->*/}
                </div>{/*<!-- ends: .widget -->                */}
            </Fragment>
        )
    }


export const TagStyle = ({data}) => {
    const {tagline}=data[0]
    console.log(tagline)
        return (
            <Fragment>
                 <div className="widget atbd_widget widget-card">
                    <div className="atbd_widget_title">
                        <h4><span className="la la-tags"></span> {t('Widget.tagstyle')}</h4>
                    </div>
                    <div className="widget-body atbdp-widget-tags">
                        <ul className="list-unstyled">
                            {
                             Object.values(tagline).length>0 ?
                                Object.values(tagline).map((item,i) => {

                                    return <li key={i}><NavLink onClick={noAction} to="/at_demo">{item.value}</NavLink></li>
                                }) : <li><NavLink onClick={noAction}>{t('widget.tags.avaibility')}</NavLink></li>
                            }
                        </ul>
                    </div>{/*<!-- ends: .widget-body -->*/}
                </div>{/*<!-- ends: .widget -->                */}
            </Fragment>
        )
    
}

export class SimilarListing extends Component {
    render() {
        return (
            <Fragment>
                <ul className="listings">
                {
                    Object.values(this.props.list).slice(0, 4).map((value, key) => {
                         return (
                            <li key={key}>
                                <div className="atbd_left_img">
                                    <NavLink to={"listing-details"+value.id}><img src={value.img} style={{width: "90px"}} alt="listingimage" /></NavLink>
                                </div>
                                <div className="atbd_right_content">
                                    <div className="cate_title">
                                        <h4><NavLink to={"listing-details"+value.id}>{value.title}</NavLink></h4>
                                    </div>
                                    <p className="listing_value">
                                        <span>$25,800</span>
                                    </p>
                                    <p className="directory_tag">
                                        <span className="la la-cutlery" aria-hidden="true"></span>
                                        <span>
                                            <NavLink to="/at_demo" onClick={noAction}>Food & Drink</NavLink>
                                            <span className="atbd_cat_popup">+3
                                                <span className="atbd_cat_popup_wrapper">
                                                    <span>
                                                        <NavLink to="/at_demo" onClick={noAction}>Food<span>,</span></NavLink>
                                                        <NavLink to="/at_demo" onClick={noAction}>Others<span>,</span></NavLink>
                                                        <NavLink to="/at_demo" onClick={noAction}>Service<span>,</span></NavLink>
                                                    </span>
                                                </span>
                                            </span>{/*<!-- ends: .atbd_cat_popup -->*/}
                                        </span>
                                    </p>
                                </div>
                            </li>
                         )
                     })
                }
                </ul>
            </Fragment>
        )
    }
}

export class PopularListing extends Component {
    render() {
        return (
            <Fragment>
                <ul className="listings">
                {
                    Object.values(this.props.list).slice(0, 4).map((value, key) => {
                         return (
                            <li key={key}>
                                <div className="atbd_left_img">
                                    <NavLink to={"listing-details"+value.id}><img src={value.img} style={{width: "90px"}} alt="listingimage" /></NavLink>
                                </div>
                                <div className="atbd_right_content">
                                    <div className="cate_title">
                                        <h4><NavLink to={"listing-details"+value.id}>{value.title}</NavLink></h4>
                                    </div>
                                    <p className="listing_value">
                                        <span>$25,800</span>
                                    </p>
                                    <p className="directory_tag">
                                        <span className="la la-cutlery" aria-hidden="true"></span>
                                        <span>
                                            <NavLink to="/at_demo" onClick={noAction}>Food & Drink</NavLink>
                                            <span className="atbd_cat_popup">+3
                                                <span className="atbd_cat_popup_wrapper">
                                                    <span>
                                                        <NavLink to="/at_demo" onClick={noAction}>Food<span>,</span></NavLink>
                                                        <NavLink to="/at_demo" onClick={noAction}>Others<span>,</span></NavLink>
                                                        <NavLink to="/at_demo" onClick={noAction}>Service<span>,</span></NavLink>
                                                    </span>
                                                </span>
                                            </span>{/*<!-- ends: .atbd_cat_popup -->*/}
                                        </span>
                                    </p>
                                </div>
                            </li>
                         )
                     })
                }
                </ul>
            </Fragment>
        )
    }
}

export class WidgetContactInfo extends Component {
    render() {
        return (
            <Fragment>
                <div className="widget-body atbd_author_info_widget">
                    <div className="atbd_widget_contact_info">
                        <ul>
                            <li>
                                <span className="la la-map-marker"></span>
                                <span className="atbd_info">25 East Valley Road, Michigan</span>
                            </li>
                            <li>
                                <span className="la la-phone"></span>
                                <span className="atbd_info">(213) 995-7799</span>
                            </li>
                            <li>
                                <span className="la la-envelope"></span>
                                <span className="atbd_info">support@aazztech.com</span>
                            </li>
                            <li>
                                <span className="la la-globe"></span>
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_info">www.aazztech.com</NavLink>
                            </li>
                        </ul>
                    </div>{/*<!-- ends: .atbd_widget_contact_info -->*/}
                    <div className="atbd_social_wrap">
                        <p><NavLink to="/at_demo" onClick={noAction} ><span className="fab fa-facebook-f"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction} ><span className="fab fa-twitter"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction} ><span className="fab fa-google-plus-g"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction} ><span className="fab fa-linkedin-in"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction} ><span className="fab fa-dribbble"></span></NavLink></p>
                    </div>{/*<!-- ends: .atbd_social_wrap -->*/}
                </div>{/*<!-- ends: .widget-body --> */}
            </Fragment>
        )
    }
}

export class Category extends Component {
    render() {
        return (
            <Fragment>
                 <div className="widget-wrapper">
                    <div className="widget-default">
                        <div className="widget-header">
                            <h6 className="widget-title">Categories</h6>
                        </div>
                        <div className="widget-content">
                            <div className="category-widget">
                                <ul>
                                    <li className="arrow-list4"><NavLink onClick={noAction} to="/at_demo">Business</NavLink></li>
                                    <li className="arrow-list4"><NavLink onClick={noAction} to="/at_demo">Finance</NavLink></li>
                                    <li className="arrow-list4"><NavLink onClick={noAction} to="/at_demo">Industry Reports</NavLink></li>
                                    <li className="arrow-list4"><NavLink onClick={noAction} to="/at_demo">Strategy</NavLink></li>
                                    <li className="arrow-list4"><NavLink onClick={noAction} to="/at_demo">Technology</NavLink></li>
                                    <li className="arrow-list4"><NavLink onClick={noAction} to="/at_demo">Marketing</NavLink></li>
                                    <li className="arrow-list4"><NavLink onClick={noAction} to="/at_demo">Strategy</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export class PopularPost extends Component {
    render() {
        return (
            <Fragment>
               <div className="widget-wrapper">
                    <div className="widget-default">
                        <div className="widget-header">
                            <h6 className="widget-title">Popular Post</h6>
                        </div>
                        <div className="widget-content">
                            <div className="sidebar-post">
                                {
                                    Object.values(this.props.blog).slice(0, 4).map((value, key) => {
                                        return (
                                            <div className="post-single" key={key}>
                                                <div className="d-flex align-items-center">
                                                    <NavLink to={'blog-details'+value.id}><img src={value.imgSrc} alt="" style={{width: "90px"}} /></NavLink>
                                                    <p><span>{value.date}</span> <span>by <a href="http://aazztech.com">Aazztech</a></span></p>
                                                </div>
                                                <NavLink to={'blog-details'+value.id} className="post-title">{value.title.split("").slice(0, 30)}</NavLink>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export class RecentPost extends Component {
    render() {
        return (
            <Fragment>
               <div className="widget-wrapper">
                    <div className="widget-default">
                        <div className="widget-header">
                            <h6 className="widget-title">Recent Post</h6>
                        </div>
                        <div className="widget-content">
                            <div className="sidebar-post">
                                {
                                    Object.values(this.props.blog).slice(0, 4).map((value, key) => {
                                        return (
                                            <div className="post-single" key={key}>
                                                <div className="d-flex align-items-center">
                                                    <NavLink to={'blog-details'+value.id}><img src={value.imgSrc} alt="" style={{width: "90px"}} /></NavLink>
                                                    <p><span>{value.date}</span> <span>by <a href="http://aazztech.com">Aazztech</a></span></p>
                                                </div>
                                                <NavLink to={'blog-details'+value.id} className="post-title">{value.title.split("").slice(0, 30)}</NavLink>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export class PopularTags extends Component {
    render() {
        return (
            <Fragment>
               <div className="widget-wrapper">
                    <div className="widget-default">
                        <div className="widget-header">
                            <h6 className="widget-title">Popular Tags</h6>
                        </div>
                        <div className="widget-content">
                            <div className="tags-widget">
                                <ul>
                                    <li><NavLink to="/at_demo" onClick={noAction}>Business</NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction}>Finance</NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction}>Strategy</NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction}>Global</NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction}>Marketing</NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction}>Technology</NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction}>Wordpress</NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction}>Solution</NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction}>Bizillion</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export class StayUpdate extends Component {
    render() {
        return (
            <Fragment>
              <div className="widget-wrapper">
                    <div className="widget-default">
                        <div className="widget-header">
                            <h6 className="widget-title">Stay Updated</h6>
                        </div>
                        <div className="widget-content">
                            <div className="subscribe-widget">
                                <form action="#">
                                    <input type="email" className="form-control m-bottom-20" placeholder="Enter email" required />
                                    <button className="btn btn-sm btn-primary shadow-none" type="submit">Subscribe</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export class ConnentFollow extends Component {
    render() {
        return (
            <Fragment>
              <div className="widget-wrapper">
                    <div className="widget-default">
                        <div className="widget-header">
                            <h6 className="widget-title">Connect &amp; Follow</h6>
                        </div>
                        <div className="widget-content">
                            <div className="social social--small">
                                <ul className="d-flex flex-wrap">
                                    <li><NavLink to="/at_demo" onClick={noAction} className="facebook"><span className="fab fa-facebook-f"></span></NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction} className="twitter"><span className="fab fa-twitter"></span></NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction} className="linkedin"><span className="fab fa-linkedin-in"></span></NavLink></li>
                                    <li><NavLink to="/at_demo" onClick={noAction} className="gplus"><span className="fab fa-google-plus-g"></span></NavLink></li>
                                </ul>
                            </div>{/*<!-- ends: .social -->*/}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
