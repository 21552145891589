import React, { useState } from "react"
import { connect } from "react-redux"
import {addComment} from '../../../../store/action/commentAction'
//import { MentionsInput, Mention } from 'react-mentions'

function AddComment({  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  handleCancel,
  initialText = "",
})  {
  const [text, setText] = useState(initialText);
  const isTextareaDisabled=text.length===0
  const onSubmit=event=>{
    event.preventDefault()
    handleSubmit(text)
    setText("")
  }
  

  return (
    
    <div className="form-group">
    <form onSubmit={onSubmit}>
        <textarea 
        placeholder="Message"
        className="form-control "
         value={text}
        onChange={(e)=>setText(e.target.value)}
         ></textarea>

        <button className="btn btn-sm btn-secondary" disabled={isTextareaDisabled}>{submitLabel}</button>
        {hasCancelButton && (
        <button
          type="button"
          className="btn btn-sm btn-danger comment-form-cancel-button"
          onClick={handleCancel}
        >
          cancel
        </button>
      )}
    </form>
    </div>
   
  )
}
const mapStateToProps=(state)=>{

  return{
      login:state.login,
      users:state.users
  }
   
}
const mapDispatchToProps=(dispatch)=>{
  return{
    addComment:data=>dispatch(addComment(data))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(AddComment)