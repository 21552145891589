import React, { Fragment, useState,useRef,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';
import frJson from '../../page/add-listing/validation-fr.json';
import  moment  from "moment";
import Services from '../../../store/service/services';
//import $ from "jquery";


export function Subscribe({datas}) {

  const { t } = useTranslation();
  const [refresh,setRefresh]=useState(false)  
  const [mailer,setMailer]=useState({
        email:{
            name:'email',
            value:'',
            classes:'form-group',
            rules:'required|email'
        },
        date:{
            value:moment().format("L")
        }
  })

  const [valid,setValid]=useState({
    valid:false,
    notvalid:false
  })

  SimpleReactValidator.addLocale('fr',{frJson})
  const validator = useRef(new SimpleReactValidator({locale: 'fr'}));



  useEffect(() => {
      document.title = 'Report complain - HOGO Places';
    }, [refresh]);

  const onBlur = (event) => {
      const target = event.target;
      const inputIdentifier = target.name;
  
      refreshFormFeedBack(inputIdentifier);
    }

    const onChange = (event) => {
      const target = event.target;
      const inputIdentifier = target.name;
      setValid({
        valid:false,
        notvalid:false
      })
      const updatedForm = {...mailer};
      const value = target.type==='checkbox' ? target.checked:target.value;
      console.log("value =>", value);
      updatedForm[inputIdentifier].value = value;
      setMailer({...updatedForm});
      refreshFormFeedBack(inputIdentifier);
    }

    const refreshFormFeedBack = (inputIdentifier) => {
      const updatedForm = {...mailer};
      console.log(inputIdentifier)
      const value = updatedForm[inputIdentifier].value;
      const rules = updatedForm[inputIdentifier].rules;
      const valid = validator.current.check(value, rules);
      if(valid) {
        updatedForm[inputIdentifier].classes = 'form-group';
        validator.current.hideMessageFor(mailer[inputIdentifier].name);
      } else {
        updatedForm[inputIdentifier].classes = 'form-group invalid';
        validator.current.showMessageFor(mailer[inputIdentifier].name);
      }
      setMailer({...updatedForm});
    }

    const onSubmit = (event) =>{
      event.preventDefault()
      if (validator.current.allValid()) {
         // setActiv(true)
         const updatedForm={...mailer}
         const mail=Services.transformData(mailer)
         console.log(datas)
         const data={email:mail.email}
         datas.checkthisEmail(data)
         console.log(data)
         if (datas.newsletters.FoundMail.length===0) {
            setValid({
              valid:true,
              notvalid:false
            })
           datas.addNewsLetter(mail)
           updatedForm['email'].value=''
           setMailer({...updatedForm})
          //  $('#modal-item-info').hide()
          //   $('#modal-item-info').show()
         }else{
          setValid({
            valid:false,
            notvalid:true
          })      
         }
      }else{
          validator.current.showMessages()
          setRefresh(true)
      }

    }
  return (
      <Fragment>
          <section className="subscribe-wrapper">
              <div className="container">
                  <div className="row">
                      <div className="col-md-8 offset-md-2">
                          <h1>{t('subscribe')}</h1>
                          <p>{t('subscribe.update')}</p>
                          <form action="/"onSubmit={onSubmit.bind(this)} className="subscribe-form m-top-40">
                              <div className={mailer['email'].classes}>
                                  <span className="la la-envelope-o"></span>
                                  <input type="text" 
                                  name={mailer['email'].name}
                                  value={mailer['email'].value}
                                  onChange={onChange.bind(this)}
                                  onBlur={onBlur.bind(this)}
                                  placeholder={t('subscribe.enterEmail')}/>
                                  {validator.current.message(mailer['email'].name,mailer['email'].value,mailer['email'].rules)}
                              </div>
                              <button type="submit" className="btn btn-gradient btn-gradient-one">{t('subscribe.submit')}</button>
                          </form><br/>
                          {valid.notvalid && (<div className="alert alert-danger" role="alert" ><i class="la la-times-circle-o"></i>
                              Email déja enregistré!!!
                          </div>)}
                          {valid.valid && <div className="alert alert-success" role="alert" ><i className="la la-check-circle"></i>
                                              Email enregistré!!!
                                          </div>}
                      </div>
                  </div>
                  
              </div>
          </section>
      </Fragment>
  );
}

