import React, { Fragment } from 'react';

export function SectionTitle(props) {

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h2>{props.title}</h2>
            <p>{props.content}</p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}