import initState1 from '../../softdata.json';
import { ADD_NEWSLETTER, SEARCH_MAIL } from "../action/newsletterAction";


const initState = {
    NewsLetter:initState1[0].newsletter,
    FoundMail:[]
}
const newsletterReducer = (state = initState, action) => {
    switch (action.type) {

        case ADD_NEWSLETTER:
            const Id=state.NewsLetter !== null ? String(parseInt(state.NewsLetter[state.NewsLetter.length-1].id) + 1) : "1"
            state.NewsLetter=[...state.NewsLetter,{id:Id,...action.data}]
            return state;

        case SEARCH_MAIL:
            let foundMail=[]
            foundMail=Object.values(state.NewsLetter).filter(elem => elem.email===action.data.email)
            console.log(foundMail)
            state.FoundMail=foundMail
            return state

        default:
            return state
    }  
}
export default newsletterReducer;