import { t } from 'i18next';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

const Gallery=({data})=> {
const {sliderImg}=data[0]
    const gallery=sliderImg
    console.log(gallery)

        //const { gallery, gallery2 } = data;
        return (
            <Fragment>
               

                {
                gallery && Object.values(gallery).length>0 ?
                (<div className="atbdb_content_module_contents">
                    <div className="gallery-wrapper">
                        <div className="gallery-images">
                        {   
                            Object.values(gallery).map((value, key) => {
                                const  img  = value;
                                return (                       
                                    <div className="single-image" key={key}>
                                        <img src={img} alt="" style={{width:670,height:440}} />                                        
                                    </div>                  
                                )
                            })
                        }
                        </div>
                        <div className="gallery-thumbs">
                        {   
                            Object.values(gallery).map((value, key) => {
                                const  img  = value;
                                return (                       
                                    <div className="single-thumb" key={key}>
                                        <img src={img} alt="" />                                        
                                    </div>                  
                                )
                            })
                        }
                        </div>
                    </div>
                </div>):(
                       
                            <div className="container">
                                <div className="alert alert-danger" role="alert">
                                {t('Listing.details.alertmessagegallery')}
                                </div>                                
                            </div>                        
                       
                    )
                }
            </Fragment>
        )
    
}
const mapStateToProps =  (state) => {
    return {
        gallery : state.gallery,
        gallery2 : state.gallery2,
        listing:state.list
    }
}
export default connect(mapStateToProps)(Gallery)
