
import {createStore, applyMiddleware, compose, StoreEnhancer} from 'redux';
import rootReducers from './reducers/rootReducer';
import thunkMiddleware from 'redux-thunk';
//import { composeWithDevTools } from "redux-devtools-extension";

declare global {
  interface Window {
    reactStore: any;
  }
}

if( !window.reactStore) {
  const middlewares = [thunkMiddleware];
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers: StoreEnhancer[] = [middlewareEnhancer]
  
  window.reactStore = createStore(
      rootReducers,
      undefined,
      composeEnhancers(...enhancers)
      //composeWithDevTools(...enhancers)
  );
}

export default window.reactStore;

