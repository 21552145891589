import React, { Fragment } from 'react';

export const Counter = ({data}) => {
    console.log(data)
    const {about}=data
    const {AboutCounter}=about
    const {content,title3,counter}=AboutCounter
        return (
            <Fragment>               
                <section className="counters-wrapper bg-gradient-pw section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h1>{title3}</h1>
                                <p>{content}</p>
                                <ul className="list-unstyled counter-items">
                                    {
                                     Object.values(counter).map((item,i) => {

                                         return (
                                             <li key={i}>
                                                    <p><span className="count_up">{item.number}</span>{item.unit}</p>
                                                    <span>{item.label}</span>
                                                </li>
                                        )
                                    })   

                                    }
                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    
}
