import React, { useState,Fragment,  useMemo} from 'react';
import { NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
//import {useSearchParams} from 'react-router-dom'

  
function CategoryCardGrid2(props) {

    
        const [place]=useState(props.place)
    
        const [listTowns,setListowns]=useState(props.towns.map(t=>t.name.toLowerCase()))
        useMemo(()=>{
            setListowns(props.towns.map(t=>t.name.toLowerCase()))
       },[props.towns])
    
        const generateLink=name=>{
            const mylink=`/all-listings-list?pl=${name?.replaceAll('&','_')}`;
            return mylink
        }
        //pl=place
        const count=(pl)=>{
            
            return Object.values(props.list).filter(item=>item.location===pl).length
 
         }
      
 
        return (
            <Fragment>
            {
                Object.values(place).filter(l=>{return listTowns.includes(l.location?.toLowerCase())}).slice(0, 4).map((value, key) => {
                    const { img,location } = value;
                    return (                       
                        <div className="category-place-single" key={key}>
                            <figure>
                                <NavLink  to={generateLink(location)}><img src={img} alt="" /></NavLink>
                                <figcaption>
                                    <h3>{location}</h3>
                                    <p>{count(location)} Listings</p>
                                </figcaption>
                            </figure>
                        </div>                      
                    )
                })
            }
            </Fragment>
        )
    }

const mapStateToProps =  (state) => {
    return {
        place : state.place,
        list:state.list.Listing,
        country:state.country
        
    }
}
export default connect(mapStateToProps)(CategoryCardGrid2)