import {FETCH_DATA, SEARCH_ACTION} from '../reducers/searchReducer'


export const searchAction=(search)=>(
    {
        type:SEARCH_ACTION,
        payload:search
    }
  
)


export const fetch_data=()=>({
    type:FETCH_DATA,
})


  