import React, { Fragment,useState, useMemo, useEffect,useCallback} from 'react';
import { NavLink,useNavigate,useLocation} from 'react-router-dom';
import { NavItem } from '../navbar/navItem';
import { connect } from 'react-redux';
import { LogOut } from '../../../store/action/logoutAction';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { setNameCountry,getCountry ,setLabelCountry} from '../../../store/action/countryAction';
import ReactCountryFlag from "react-country-flag"
import $ from 'jquery';
//const noAction = e => e.preventDefault();

function Header(props) {    
    const {t,i18n} = useTranslation();
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    const navigate=useNavigate()
    const location=useLocation()
  
    const logdIn = () => {
      return props.login
    }
    const logOut = (e) => {
      e.preventDefault();
      props.logOutdata(null);
    }
    const generateLink=name=>{
      const mylink=`/all-listings-list?cat=${name?.replaceAll('&','_')}`;
      return mylink
  }


 const logIn=useCallback(()=>{
     return props.login
 },[props])

  const getProfile=()=>{
      if(logIn())
      {  
        const userConnected =JSON.parse(localStorage.getItem('login')).email
        const user=Object.values(props.users).filter(user=>user.email===userConnected)
        
      return user[0].profile
    }
  }

  const [valueCountry, setValueCountry] = useState(props.NameCountry)
  const [label,setLabel]=useState(props.labelCountry)
  const changeHandler = value => {
    setLabel(value.label)
    setValueCountry(value.name)
  }
  
  
  useEffect(()=>{

    if(location.search.length===0)
    { 
      navigate(location.pathname.concat(`?country=${label?.toLowerCase()}`))
  
    }
    
   
  },[label, location.pathname, location.search.length, navigate])
  useMemo(()=>{
    props.setNameCountry(valueCountry)
    props.setLabelCountry(label)
  },[valueCountry,label,props])


  const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted gray',
        backgroundColor: state.isSelected &&'deeppink'
      }),
    control: base => ({
      ...base,
      height: 48,
      minHeight: 48,
      
    })
  };
  const filterCountry = (value) => {
    return Object.values(props.country).filter((i) =>
      i.name?.toLowerCase().includes(value?.toLowerCase())
    );
  };
  const loadOptionsCountry = (
    value,
    callback
  ) => {
    setTimeout(() => {
      callback(filterCountry(value));
    }, 1000);
  };
  const valide=(e)=>{
    e.preventDefault() 
    navigate(location.pathname.concat(`?country=${label?.toLowerCase()}`))
    $("#country_modal").click()


  }
 


  return (
    <Fragment>
      <div className="modal fade" id="country_modal" tabIndex={-1} role="dialog" aria-labelledby="country_modal_label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="country_modal_label"><i className="la la-lock" />{t('header.choose.country')}</h5>
                             <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="/" id="country-form">
                                             <AsyncSelect 
                                                                getOptionLabel={e=>e.name}
                                                                cacheOptions
                                                                defaultOptions
                                                                onChange={changeHandler}
                                                                loadOptions={loadOptionsCountry}
                                                                placeholder={t("author.profile.Country.placeholder")}
                                                                styles={customStyles}
                                                                name="country"
                                                                    /> <br/>
                                    <button type="submit" className="btn btn-sm btn-secondary" onClick={valide}>{t('author.change.profile.button.save')}</button>
                                    </form>
                                <div className="form-excerpts">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      <div className={"menu-area menu1 " + props.class}>
        <div className="top-menu-area">
          <div className="container-fluid">
            <div className="row">
              
              <div className='col bg-dark bg-gradient text-light ' >
             
                 <i  className="la la-map-marker icon-xs"></i>{t('header.choose.country')}: <span style={{fontSize:20}}><a href=" "   data-toggle="modal" data-target="#country_modal">{valueCountry}</a></span>
                </div>
                <span className='col bg-dark bg-gradient text-light ' style={{textAlign:'right',PaddingRight:10,paddingTop:5}}> 
                    {t('language')} : <NavLink onClick={() => changeLanguage('en')} to="#">
                <ReactCountryFlag
                countryCode="US"
                svg
                style={{
                    width: '2em',
                    height: '2em',
                    marginRight:8
                }}
                title="US"
                />
              </NavLink>
                      <NavLink onClick={() => changeLanguage('fr')} to="#">
                        <ReactCountryFlag
                    countryCode="FR"
                    svg
                    style={{
                        width: '2em',
                        height: '2em',
                        marginRight:8
                    }}
                    title="FR"
                    />
                </NavLink></span>     
              
              <div className="col-lg-12">
                <div className="menu-fullwidth">
                  <div className="logo-wrapper order-lg-0 order-sm-1">
                    <div className="logo logo-top">
                      <NavLink to="/"><img src={props.logo} alt="logoImage" className="img-fluid" /></NavLink> 
                    </div>
                  </div>{/*<!-- ends: .logo-wrapper -->*/}
                  <div className="menu-container order-lg-1 order-sm-0">
                    <div className="d_menu">
                      <nav className="navbar navbar-expand-lg mainmenu__menu">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#direo-navbar-collapse" aria-controls="direo-navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon icon-menu"><i className="la la-reorder"></i></span>
                        </button>
                        {/*<!-- Collect the nav links, forms, and other content for toggling -->*/}
                        <div className="collapse navbar-collapse" id="direo-navbar-collapse">
                          <NavItem />
                        </div>
                        {/*<!-- /.navbar-collapse -->*/}
                      </nav>
                    </div>
                  </div>
                  <div className="menu-right order-lg-2 order-sm-2">
                    <div className="search-wrapper">
                      <div className="nav_right_module search_module">
                        <span className="icon-left" id="basic-addon9"><i className="la la-search"></i></span>
                        <div className="search_area">
                          <form action="/">
                            <div className="input-group input-group-light">
                              <input type="text" className="form-control search_field top-search-field" placeholder={t('home.input.search.title')} autoComplete="off" />
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="search-categories">
                        <ul className="list-unstyled">
                        <li><NavLink to={generateLink('Food & Drink')} ><span className="la la-glass bg-danger"></span>{t('category.food&drink')}</NavLink></li>
                          <li><NavLink to={generateLink('Restaurants')}><span className="la la-cutlery bg-primary"></span> {t('category.restaurants')}</NavLink></li>
                          <li><NavLink to={generateLink("Places & Destination")}><span className="la la-map-marker bg-success"></span> {t('category.places')}</NavLink></li>
                          <li><NavLink to={generateLink('Shopping & Store')}><span className="la la-shopping-cart bg-secondary"></span> {t('category.shopping&store')}</NavLink></li>
                          <li><NavLink to={generateLink("Hotel & Travel")}><span className="la la-bed bg-info"></span>{t('category.hotel&travel')}</NavLink></li>
                          <li><NavLink to={generateLink('Art & History')} ><span className="la la-bank bg-warning"></span> {t('category.art&history')}</NavLink></li>
                         </ul>
                      </div>
                    </div>{/*<!-- ends: .search-wrapper -->*/}
                    {/*<!-- start .author-area -->*/}
                    <div className="author-area">
                      <div className="author__access_area">
                   
                      {
                        logdIn() == null ? (
                          <ul className="d-flex list-unstyled align-items-center">
                          
                            <li>
                              <NavLink to="/add-listing" className="btn btn-xs btn-gradient btn-gradient-two">
                                <span className="la la-plus"></span> {t('add.listing')} 
                              </NavLink>
                            </li>
                            <li>
                              <a href=" " className="access-link" data-toggle="modal" data-target="#login_modal">Login</a>
                              <span>or</span>
                              <a href=" " className="access-link" data-toggle="modal" data-target="#signup_modal">Register</a>
                            </li>
                          </ul>
                        ) : (
                          <ul className="d-flex list-unstyled align-items-center">
                            <li>
                              <NavLink to="/add-listing" className="btn btn-xs btn-gradient btn-gradient-two">
                                <span className="la la-plus"></span> {t('add.listing')} 
                              </NavLink>
                            </li>
                            <li>					
                              <div className="author-info">
                                <a href=" " className="author-avatar"><img src={getProfile()}  alt="" className="rounded-circle"  style={{height:50,width:50}} /></a>
                                <ul className="list-unstyled">
                                  <li><NavLink to="/author-profile">My Profile</NavLink></li>
                                  <li><NavLink to="/dashboard-listings">Deshboard</NavLink></li>
                                  <li><NavLink to="/dashboard-listings">change password</NavLink></li>
                                  <li><NavLink to="/at_demo">Favorite Listing</NavLink></li>
                                  <li><NavLink to="/at_demo" onClick={logOut}>Logout</NavLink></li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        )
                      }
                      </div>
                    </div>
                    
                    {/*<!-- end .author-area -->*/}
                    <div className={"offcanvas-menu d-none"}>
                      <a href=" " className="offcanvas-menu__user"><i className="la la-user"></i></a>
                      <div className="offcanvas-menu__contents">
                        <a href=" " className="offcanvas-menu__close"><i className="la la-times-circle"></i></a>
                        <div className="author-avatar">
                          <img src="./assets/img/author-avatar.png" alt="" className="rounded-circle" />
                        </div>
                        <ul className="list-unstyled">
                          <li><a href="dashboard-listings.html">My Profile</a></li>
                          <li><a href="dashboard-listings.html">My Listing</a></li>
                          <li><a href="dashboard-listings.html">Favorite Listing</a></li>
                          <li><a href="add-listing.html">Add Listing</a></li>                                                        
                          <li><a href=" ">Logout</a></li>
                        </ul>
                        <div className="search_area">
                          <form action="/">
                            <div className="input-group input-group-light">
                              <input type="text" className="form-control search_field" placeholder="Search here..." autoComplete="off" />
                            </div>
                            <button type="submit" className="btn btn-sm btn-secondary">Search</button>
                          </form>
                        </div>{/*<!-- ends: .search_area -->*/}
                      </div>{/*<!-- ends: .author-info -->*/}
                    </div>{/*<!-- ends: .offcanvas-menu -->*/}
                  </div>{/*<!-- ends: .menu-right -->*/}
                </div>
              </div>
            </div>
            {/* <!-- end /.row --> */}
          </div>
            {/* <!-- end /.container --> */}
        </div>
        {/* <!-- end  --> */}
      </div>
    </Fragment>
  )
}
const mapStateToProps = state => {
  return {
    login : state.login,
    NameCountry:state.country.name,
    labelCountry:state.country.label,
    country:state.country.list,
    countryState:state.country,
    users: state.users
   
  }
}
const mapDispatchToProp = dispatch => {
  return {
    setNameCountry:name=>dispatch(setNameCountry(name)),
    setLabelCountry:name=>dispatch(setLabelCountry(name)),
    getCountry:name=>dispatch(getCountry(name)),
    logOutdata : (login) => dispatch(LogOut(login))
  }
}
export default connect(mapStateToProps, mapDispatchToProp)(Header)