import initState1 from '../../softdata.json';
import Services from "../service/services";
const initState = {
    dataCountries:initState1[0].countries, dataCities:[]
}

export const GET_CITIES_BY_ID='GET_CITIES_BY_ID'


const citiesReducer = (state = initState, action) => { 
    switch (action.type) {
        case 'GET_CITIES_BY_ID':
            console.log(action)
            const currentStates=Services.filterData(state['dataCountries'],'states',action.data.id)
            state['dataCities']=Services.filterData(currentStates,'towns',action.data.idLocation)
            console.log(state.dataCities)
            return state;
    
        default:
            return state;
    }   
          
}
export default citiesReducer;