
import React, { Fragment, useRef, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router';
import SimpleReactValidator from "simple-react-validator";
import frJson from "../../../page/add-listing/validation-fr.json";
import ModalConfirmation from "./modalConfirmation";
import $ from "jquery";


//const noAction = e => e.preventDefault();

const Clime=({data})=> {


    const {t}=useTranslation()
    const [refresh,setRefresh]=useState(false)
    const [climeForm,setClimeForm]=useState({
        name:{
            name:'name',
            value:'',
            classes:'col-md-6',
            rules:'required'
        },
        email:{
            name:'email', 
            value:'',
            classes:'col-md-6',
            rules:'required|email'
        },
        url:{
            name:'url',
            value:'',
            classes:'col-md-6',
            rules:'required|url'
        },
        phone:{
            name:'phone',
            value:'',
            classes:'col-md-6',
            rules:'required|phone'
        },
        infolist:{
            name:'infolist',
            value:'',
            classes:'col-lg-12',
            rules:'required|min:10|max:100'
        },
        subjectId:{
            value:''
        }
    })

    SimpleReactValidator.addLocale('fr',{frJson})
    const validator = useRef(new SimpleReactValidator({locale: 'fr'}));

    useEffect(() => {
        document.title = 'Claim this business - HOGO Places';
      }, [refresh]);

    const onBlur = (event) => {
        const target = event.target;
        const inputIdentifier = target.name;
    
        refreshFormFeedBack(inputIdentifier);
      }
    
    const refreshFormFeedBack = (inputIdentifier) => {
        const updatedClimeForm = {...climeForm};
        const value=updatedClimeForm[inputIdentifier].value
        const rules=updatedClimeForm[inputIdentifier].rules
        console.log(inputIdentifier)
        const valid = validator.current.check(value, rules);
        if(valid) {
            if (inputIdentifier==="infolist") {
                updatedClimeForm[inputIdentifier].classes = 'col-lg-12';   
            }else{
                updatedClimeForm[inputIdentifier].classes = 'col-md-6';
            }
          validator.current.hideMessageFor(climeForm[inputIdentifier].name);
        } else {
            if (inputIdentifier==="infolist") {
                updatedClimeForm[inputIdentifier].classes = 'col-lg-12 invalid';   
            }else{
                updatedClimeForm[inputIdentifier].classes = 'col-md-6 invalid';  
            }
            validator.current.showMessageFor(climeForm[inputIdentifier].name);
        }
        setClimeForm({...updatedClimeForm});
    }


    const onChange = (event) => {
        const target = event.target;
        const inputIdentifier = target.name;
        const updatedClimeForm = {...climeForm};
        const value = target.value;
        updatedClimeForm[inputIdentifier].value = value;
        setClimeForm({...updatedClimeForm});
        refreshFormFeedBack(inputIdentifier);
      }


    // submit button function of the form*************************************************

    const onSubmit = (event) => {
        event.preventDefault();
    
    if(validator.current.allValid()) {
        const {props,id}=data
        console.log(data)
        assignIdtoClaimForm(id)
        const currentData=transformData(climeForm)
        props.claimAction(currentData)
        refreshFields()
        $('#moda_claim_listing').click()
        //navigate(´/listing-details+$id´,{replace:true})
    } else {
      validator.current.showMessages();
      setRefresh(true);
    }
  } 

  function refreshFields() {

    setClimeForm({
        name:{
            name:'name',
            value:'',
            classes:'col-md-6',
            rules:'required'
        },
        email:{
            name:'email',
            value:'',
            classes:'col-md-6',
            rules:'required|email'
        },
        url:{
            name:'url',
            value:'',
            classes:'col-md-6',
            rules:'required|url'
        },
        phone:{
            name:'phone',
            value:'',
            classes:'col-md-6',
            rules:'required|phone'
        },
        infolist:{
            name:'infolist',
            value:'',
            classes:'col-lg-12',
            rules:'required|min:10|max:100'
        },
        subjectId:{
            value:''
        }
    })
    
    setRefresh(false)
  }
  

     //function prepare formdata for request**********************************************
  
 const transformData=(datas)=>{
    let keyArray=Object.keys(datas)
    let newArray=Object.values(datas)
    let dataValue={}
    for (let index = 0; index < keyArray.length; index++) {
      dataValue[keyArray[index]] = newArray[index].value; 
    }
    return dataValue
  }

//assign id's of te place claimed to the state's form

  const assignIdtoClaimForm=(ID)=>{
        const updatedClimeForm={...climeForm}
        updatedClimeForm['subjectId'].value=ID
        setClimeForm({...updatedClimeForm})
  }



  // redirect me function*******************************************
// const redirectMe=(event)=>{
//     event.preventDefault()
//     navigate("/dashboard-listings",{replace:true})
// }
   
                
        return (
            <Fragment>
                <div className="modal fade" id="moda_claim_listing" tabIndex="-1" role="dialog" aria-labelledby="claim_listing_label" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="claim_listing_label"><i className="la la-check-square"></i>{t('Listing.details.claim')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={onSubmit}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className={climeForm['name'].classes}>
                                                <input type="text" 
                                                name={climeForm['name'].name}
                                                value={climeForm['name'].value}
                                                onBlur={onBlur.bind(this)}
                                                onChange={onChange.bind(this)}
                                                placeholder={t("Listing.details.name")} className="form-control"/>
                                                {validator.current.message(climeForm['name'].name, climeForm['name'].value, climeForm['name'].rules)}
                                            </div>
                                            <div className={climeForm['email'].classes}>
                                                <input type="text"
                                                name={climeForm['email'].name}
                                                value={climeForm['email'].value}
                                                onBlur={onBlur.bind(this)}
                                                onChange={onChange.bind(this)}
                                                className="form-control" placeholder={t("Listing.details.emailaddress")}/>
                                                {validator.current.message(climeForm['email'].name, climeForm['email'].value, climeForm['email'].rules)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className={climeForm['phone'].classes}>
                                                <input type="text"
                                                name={climeForm['phone'].name}
                                                value={climeForm['phone'].value}
                                                onBlur={onBlur.bind(this)}
                                                onChange={onChange.bind(this)}
                                                className="form-control" placeholder={t("Listing.details.phonenumber")} />
                                                {validator.current.message(climeForm['phone'].name, climeForm['phone'].value, climeForm['phone'].rules)}
                                            </div>
                                            <div className={climeForm['url'].classes}>
                                                <input type="text"
                                                name={climeForm['url'].name}
                                                value={climeForm['url'].value}
                                                onBlur={onBlur.bind(this)}
                                                onChange={onChange.bind(this)}
                                                className="form-control" placeholder={t("Listing.details.url")}/>
                                                {validator.current.message(climeForm['url'].name, climeForm['url'].value, climeForm['url'].rules)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <textarea className="form-control"
                                                name={climeForm['infolist'].name}
                                                onBlur={onBlur.bind(this)}
                                                value={climeForm['infolist'].value}
                                                onChange={onChange.bind(this)}
                                                rows="6" placeholder={t("Listing.details.provide.info")}></textarea>
                                                {validator.current.message(climeForm['infolist'].name, climeForm['infolist'].value, climeForm['infolist'].rules)}
                                            </div>
                                            <div className="col-lg-12">
                                                <button type="submit" className="btn btn-secondary">{t('Listing.details.submit')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
               <ModalConfirmation t={t}/>
            </Fragment>
        )
    
}

export default Clime;