import React, {Fragment,useCallback,useEffect,useMemo,useState} from 'react';
import Header from '../layout/header';
import { Footer } from '../layout/footer';
import { BreadcrumbWraper } from '../content/element/breadcrumb';

import { connect } from 'react-redux';
import  AuthorListingCardGrid4 from '../content/element/card/card-author-grid-4';
import moment from 'moment';
import { getAllReview } from '../../store/action/reviewAction';
import { useTranslation } from 'react-i18next';

function AuthProfile (props) {

    const {t}=useTranslation()
    const [userConnectedID,setUserConnectedID] = useState(null)
    const [previewInfo,setPreviewInfo]=useState([])
    const [listing,setListing]=useState([])
    const [category,setCategory]=useState('')
    const logIn=useCallback(()=>{
        return props.login
    },[props])

    useEffect(()=>{
        if(logIn())
        {
            const user=JSON.parse(localStorage.getItem('login'))
            setUserConnectedID(user.email)
          
        }   
        props.getAllReview()

        },[logIn, props])
       
   const [userInfo,setUserInfo]=useState([])
        useMemo(()=>{
        setUserInfo(Object.values(props.authorProfiles.detailsProfile).filter(d=>d.currentUserId===userConnectedID))
        if(userConnectedID!==null)
        {     const user=Object.values(props.users).filter(user=>user.email===userConnectedID)
            
        setPreviewInfo(user[0])
        const listing=Object.values(props.list).filter(list=>list.authorId===userConnectedID)
  
        setListing(listing)
       }
        },[userConnectedID,props])
       
    
   console.log(userConnectedID)
  
        

  
    const getCountReview=useCallback(()=>{
       const reviews=props.review.reviewList
      
         const review=Object.values(reviews).filter(r=>r.userId===userConnectedID)
 
     return review.length
 
   },[props.review.reviewList, userConnectedID])

  
   
 
        const light = props.logo[0].light;
        const logdIn = () => {
            return props.login
        }
       
        return (
            <Fragment>
                {/* Header section start */}
                <section className="header-breadcrumb bgimage overlay overlay--dark">
                    <div className="bg_image_holder"><img src="./assets/img/breadcrumb1.jpg" alt="" /></div>
                    <div className="mainmenu-wrapper">
                        <Header logo={light} class="menu--dark" />                    
                    </div>
                    {/* <!-- ends: .mainmenu-wrapper --> */}
                    <BreadcrumbWraper title={t("author.profile")}/>
                </section>
                {/* Header section end */}
               { logdIn()!==null && userConnectedID!==null ?
                <section className="author-info-area section-padding-strict section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="atbd_auhor_profile_area">
                                    <div className="atbd_author_avatar">
                                       <img src={previewInfo.profile} style={{height:120,width:120}}  alt="AuthorImage" />
                                        <div className="atbd_auth_nd">
                                            <h2>{previewInfo.fullName}</h2>
                                            <p>{t('author.profile.joined')} {moment(previewInfo.createAt).format("MMMM YYYY")}</p>
                                        </div> 
                                    </div>{/*<!-- ends: .atbd_author_avatar -->*/}
                                    <div className="atbd_author_meta">
                                        <div className="atbd_listing_meta">
                                            <span className="atbd_meta atbd_listing_rating">4.5 <i className="la la-star"></i></span>
                                            <p className="meta-info"><span>{getCountReview()}</span>{t('reviews')}</p>
                                        </div>
                                        <p className="meta-info"><span>{listing.length}</span>{t('listing')}</p>
                                    </div>{/*<!-- ends: .atbd_author_meta -->*/}
                                </div>{/*<!-- ends: .atbd_auhor_profile_area -->*/}
                            </div>
                           {  userInfo.length ? 
                           <>
                            {/* <!-- ends: .col-lg-12 --> */}
                            <div className="col-lg-8 col-md-7 m-bottom-30">
                                <div className="atbd_author_module">
                                    <div className="atbd_content_module">
                                        <div className="atbd_content_module__tittle_area">
                                            <div className="atbd_area_title">
                                                <h4><span className="la la-user"></span>{t('author.about.seller')}</h4>
                                            </div>
                                        </div>
                                        <div className="atbdb_content_module_contents">
                                            {userInfo[0].aboutAuthor}
                                        </div>
                                    </div>
                                </div>{/*<!-- ends: .atbd_author_module -->*/}                                
                            </div>{/*<!-- ends: .col-md-8 -->*/}
                            <div className="col-lg-4 col-md-5 m-bottom-30">
                                <div className="widget atbd_widget widget-card">
                                    <div className="atbd_widget_title">
                                        <h4><span className="la la-phone"></span>{t('author.contact.info')}</h4>
                                    </div>{/*<!-- ends: .atbd_widget_title -->*/}
                                    <div className="widget-body atbd_author_info_widget">
                                        <div className="atbd_widget_contact_info">
                                            <ul>
                                                <li>
                                                    <span className="la la-map-marker"></span>
                                                    <span className="atbd_info">{userInfo[0].address.length?userInfo[0].address:t('avialable')}</span>
                                                </li>
                                                <li>
                                                    <span className="la la-phone"></span>
                                                    <span className="atbd_info">{userInfo[0].phoneNumber}</span>
                                                </li>
                                                <li>
                                                    <span className="la la-envelope"></span>
                                                    <span className="atbd_info">{userInfo[0].email}</span>
                                                </li>
                                                <li>
                                                    <span className="la la-globe"></span>
                                                    <a href={userInfo[0].linkWebsite} rel="noreferrer" target="_blank" className="atbd_info">{userInfo[0].linkWebsite.length?userInfo[0].linkWebsite:t('avialable')}</a>
                                                </li>
                                            </ul>
                                        </div>{/*<!-- ends: .atbd_widget_contact_info -->*/}
                                        <div className="atbd_social_wrap">
                                           {userInfo[0].linkFacebook.length!==0 && <p><a href={userInfo[0].linkFacebook} rel="noreferrer" target="_blank"><span className="fab fa-facebook-f"></span></a></p>}
                                           {userInfo[0].linkTwitter.length!==0 && <p><a href={userInfo[0].linkTwitter} rel="noreferrer" target="_blank"><span className="fab fa-twitter"></span></a></p>}
                                            {userInfo[0].linkLinkedln.length!==0 && <p><a href={userInfo[0].linkLinkedln} rel="noreferrer" target="_blank"><span className="fab fa-linkedin-in"></span></a></p>}
                                           {userInfo[0].linkYoutube.length!==0? <p><a href={userInfo[0].linkYoutube} rel="noreferrer" target="_blank"><span className="fab fa-dribbble"></span></a></p>:''}
                                           
                                        </div>{/*<!-- ends: .atbd_social_wrap -->*/}
                                    </div>{/*<!-- ends: .widget-body -->*/}
                                </div>{/*<!-- ends: .widget -->*/}
                            </div>{/*<!-- ends: .col-lg-4 -->*/}

                            <div className="col-lg-12">
                                <div className="atbd_author_listings_area m-bottom-30">
                                    <h1>{t('author.listings')}</h1>
                                    <div className="atbd_author_filter_area">
                                        <div className="dropdown">
                                        {t('auhtor.filter.by.category')} : 
                                            <select value={category.id} onChange={(e)=>setCategory(e.target.value)} >
                                              
                                            <option value="All">{t('listing.category.select')}</option>
                                            
                                             {
                                                Object.values(props.cat).map((category,index)=>
                                                    <option value={category.id} key={index}  href=" ">{category.label}</option>
                                                    )
                                                    
                                             }
                            
                                           </select>
                                       
                                        </div>
                                    </div>
                                </div>{/*<!-- ends: .atbd_author_listings_area -->*/}
                                
                                <div className="row">
                                    {listing.length?   < AuthorListingCardGrid4  logdIn={logdIn()} category={category} currentUserId={userConnectedID} />
                                    :<div className='alert alert-warning'>{t('listing.avialable')}</div>}
                                 
                                </div>
                            </div>
                          </>:<div className='alert alert-danger text-center'>{t('author.alert.complete.information')}</div>}  
                        </div>
                    </div>
                </section>:<div className='alert alert-danger text-center'>{t('message.log.in')}</div>}       
               <Footer />
            </Fragment>
        )
    }

const mapStateToProps = state => {
    return {
        list: state.list.Listing,
        login : state.login,
        logo: state.logo,
        users:state.users,
        authorProfiles:state.authorProfiles,
        review:state.review,
        cat:state.category,
        
    }
}
const mapDispatchToProp=dispatch=>{
    return{
        getAllReview:()=>dispatch(getAllReview())
    }
}
export default connect(mapStateToProps,mapDispatchToProp)(AuthProfile);