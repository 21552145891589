import React, {  Fragment, useState} from 'react';
import { NavLink,useParams} from 'react-router-dom';
import { connect } from 'react-redux';
import ReactStars from "react-rating-stars-component";


 function AddReview (props) {
    const {id}=useParams()
    const logdIn = () => {
        return props.login
      }
   
    //console.log(props.login.data.email)
    //console.log(props.login)
    
    //console.log(JSON.parse(userConnected).email)
    const [rating,setRating]=useState(0)
    const [content,setContent]=useState('')
    const [errorRating,setErroRating]=useState(false)
   
   const onStarClick=(nextValue)=> {
       setRating(nextValue);
       setErroRating(false)
      }
   
     const onSubmit=(e)=>{
        e.preventDefault()
        if(logdIn())
        {
            if(rating===0)
            {
                setErroRating(true)
            }
            else{
                
                 const info={id:Math.random().toString(36).substr(2, 9),createAt:new Date().toISOString(),editAt:null,hasRating:rating>0?true:false,rating:rating,content:content,userId:props.currentUserId,parentId:null,listing_id:id} 
                props.handleSubmit(info)
                setContent('')
                
            }
       
        }
    }
  
    return (
        <Fragment>
           
            <div className="atbd_content_module atbd_review_form">
                <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                        <h4><span className="la la-star"></span>Add a Review</h4>
                    </div>
                </div>
                <div className="atbdb_content_module_contents atbd_give_review_area">
                    
                        {
                            logdIn()===null? 
                         <div className="atbd_notice alert alert-info" role="alert">
                        <span className="la la-info" aria-hidden="true"></span>
                        You need to <NavLink to="login" data-toggle="modal" data-target="#login_modal">Login</NavLink> or <NavLink to="/register" data-toggle="modal" data-target="#signup_modal">Register</NavLink> to submit a review
                     </div>:''
                        }
                       
                    {/* <!-- ends: .atbd_notice --> */}
                    <form action="/" id="atbdp_review_form" method="post">
                        <div className="atbd_review_rating_area">
                            {/* <!--It should be displayed on the left side --> */}
                            <div className="atbd_review_update_rating">
                                <span>Rating: </span>
                                <div className="atbd_rating_stars">
                                    <div className="br-wrapper br-theme-fontawesome-stars m-left-15">
                                    
                                                <ReactStars
                                                count={5}
                                                onChange={onStarClick}
                                                size={24}
                                                value={rating}
                                                edit={true}
                                                activeColor="#ffd700"
                                            /> 
                                    </div>
                                     {errorRating && <div className='text-danger'>Choose a rating</div>}
                                </div>
                               
                            </div>{/*<!-- ends: .atbd_review_update_rating -->*/}
                        </div>{/*<!-- ends: .atbd_review_rating_area -->*/}
                        
                        <div className="form-group">
                            <textarea name="content" id="review_content" value={content} onChange={(e)=>setContent(e.target.value)} className="form-control" placeholder="Message" ></textarea>
                        </div>
                        <div className="form-group">
                            <div id="atbd_up_preview"></div>
                            <div className="atbd_upload_btn_wrap">
                               
                                {/* <span id="file_name"></span> */}
                            </div>
                        </div>
                        {/* <!--If current user has a review then show him update and delete button--> */}
                        <button className="btn btn-gradient btn-gradient-one" type="submit" id="atbdp_review_form_submit" onClick={(e)=>onSubmit(e)}>Submit Review</button>
                    </form>
                
                </div>
            </div>
        </Fragment>
    )
}
const mapStateToProps=(state)=>{

    return{
        login:state.login
    }
     
 }
 
 export default connect(mapStateToProps)(AddReview)