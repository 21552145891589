import initState1 from '../../softdata.json';

const initState = [...initState1[0].claimers]

export const  ADD_NEW_CLAIM='ADD_NEW_CLAIM'
const claimReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_NEW_CLAIM':
            const ID=String(parseInt(state[state.length-1].id)+1)
            console.log(ID)
            return [...state,{"id":ID,"date":Date.now(),...action.data}];
    
        default:
            return state; 
    }
   
}
export default claimReducer;