/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment} from 'react';
import Header from '../../layout/header';
import { Footer } from '../../layout/footer';
import { BreadcrumbWraper } from '../../content/element/breadcrumb';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import './add-listing.css';
import CreatableSelect from "react-select/creatable";
import frJson from './validation-fr.json';
import { useNavigate } from 'react-router';
import { ADD_NEW_LIST } from "../../../store/action/listingAction";

import  {GET_STATES_BY_ID} from "../../../store/reducers/statesReducer";
import { GET_CITIES_BY_ID} from "../../../store/reducers/citiesReducer";
import Services from '../../../store/service/services';

function AddListing(props) {
  const { t } = useTranslation();
  const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
  const [refresh, setRefresh] = useState(false);
  const [idLocation, setIdLocation] = useState('');
  const [Locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [id, setId] = useState(null);
  const [tagValue,setTagValue]=useState({inputValue:'',value:[]})
  const navigate=useNavigate()
  const [valid,setValid]=useState({
    valid:false,
    notvalid:false
  })
  const loginIn=props.login
  const [addForm, setAddForm] = useState({
    title:{
      name:'title',
      value:'',
      classes:'form-group',
      rules:'required|min:4'
  },
  description:{
      name:'description',
      value:'',
      classes:'form-group',
      rules:'required|min:20|max:400'
  },
  category:{
      name:'category',
      value:'',
      classes:'form-group',
      rules:'required'
  },
  tagline:{
      name:'tagline',
      inputValue:'',
      value:[],
      classes:'form-group',
      rules:'string|min:2|max:10'
  },
  pricing:{
      name:'pricing',
      value:'',
      classes:'form-group',
      rules:'required'
  },
  checkPriceUno:{
      name:'checkPriceUno',
      value:true,
      classes:'form-group',
      rules:'boolean'
  },
  checkPriceDue:{
      name:'checkPriceDue',
      value:false,
      classes:'form-group',
      rules:'boolean'
  },
  shortdescription:{
      name:'shortdescription',
      value:'',
      classes:'form-group',
      rules:'required|min:15|max:150'
  },
  cdate:{
      name:'cdate',
      value:'',
      classes:'form-group',
      rules:'required'
  },
  country:{
      name:'country',
      value:'',
      classes:'form-group',
      rules:'required'
  },
  state:{
      name:'state',
      value:'',
      classes:'form-group',
      rules:'required'
  },
  city:{
      name:'city',
      value:'',
      classes:'form-group',
      rules:'required'
  },
  tags:{
      name:'tags',
      value:[],
      classes:'form-group',
      rules:'string|min:2|max:10'
  },
  hideInfo:{
      name:'hideInfo',
      value:false,
      classes:'form-group',
      rules:'boolean'
  },
  adress:{
      name:'adress',
      value:'',
      classes:'form-group',
      rules:'required'
  },
  dialcode:{
      name:'dialcode',
      value:'',
      classes:'form-group',
      rules:'required'
  },
  phoneNumber:{
      name:'phoneNumber',
      value:'',
      classes:'form-group',
      rules:'required|phone|max:12'
  },
  email:{
      name:'email',
      value:'',
      classes:'form-group',
      rules:'required|email'
  },
  website:{
      name:'website',
      value:'',
      classes:'form-group',
      rules:'url'
  },
  social_media:{
      name:'social_media',
      value:[{label:'',url:''}],
      classes:'form-group',
      rules:{rules1:'string',rules2:'url'}
  },
  v4v47j7j:{
      name:'v4v47j7j',
      value:false,
      rules:'boolean'

  },
  openHours:{
      name:'openHours',
      value:[
          {
              day:'saturday',
              isclose:false,
              openinghour:'',
              closinghour:''
          },
          {
              day:'sunday',
              isclose:false,
              openinghour:'',
              closinghour:''
          },
          {
              day:'monday',
              isclose:false,
              openinghour:'',
              closinghour:''
          },
          {
              day:'tuesday',
              isclose:false,
              openinghour:'',
              closinghour:''
          },
          {
              day:'wednesday',
              isclose:false,
              openinghour:'',
              closinghour:''
          },
          {
              day:'thursday',
              isclose:false,
              openinghour:'',
              closinghour:''
          },
          {
              day:'friday',
              isclose:false,
              openinghour:'',
              closinghour:''
          }
      ],
      classes:'col-sm-6',
      rules:{rules1:'string',rules2:'boolean'}
  },
  faqs:{
      name:'faqs',
      value:[{question:'',answer:''}],
      classes:'form-group',
      rules:{rules1:'string',rules2:'string'}
  },
  iscoordo:{
      name:'iscoordo',
      value:false,
      classes:'form-group',
      rules:'boolean'
  },
  longitude:{
      name:'longitude',
      value:'',
      classes:'form-group',
      rules:'numeric'
  },
  latitude:{
      name:'latitude',
      value:'',
      classes:'form-group',
      rules:'numeric'
  },
  hideMap:{
      name:'hideMap',
      value:false,
      classes:'form-group',
      rules:'boolean'
  },
  img:{
      name:'img',
      value:[],
      imgFile:[],
      classes:'form-group',
      rules:'required'
  },
  features:{
      name:'features',
      value:[{feature:'',definition:''}],
      classes:'form-group',
      rules:{rules1:'string',rules2:'string'}
  },
  sliderImg:{
      name:'sliderImg',
      value:[],
      imgPreview:[],
      classes:'required',
      rules:'string'

  },
  videoUrl:{
      name:'videoUrl',
      value:'',
      classes:'form-group',
      rules:'url'
  },
  agree_tc:{
      name:'agree_tc',
      value:true,
      classes:'form-group',
      rules:'accepted|boolean'
  },
  authorId:{
    value:loginIn!== null ? loginIn.email : null
  }
  });


      //filling props to const

      const light = props.logo[0].light;
      const categories = props.categories;
      const countries = props.countries;
      const socialMedias=props.socialmedia



  SimpleReactValidator.addLocale('fr', {frJson});

  const validator = useRef(new SimpleReactValidator({locale: 'fr'}));
  
  useEffect(() => {
    document.title = 'Add Place - HOGO Places';
  }, [refresh]);

  const onBlur = (event,index) => {
    const target = event.target;
    const inputIdentifier = target.name;

    refreshFormFeedBack(inputIdentifier,index);
  }


   // getting and setting values from form to the addform's state*****************************************

  const onChange = (event) => {
    const target = event.target;
    const inputIdentifier = target.name;

    const updatedAddForm = {...addForm};
    const value = target.type==='checkbox' ? target.checked:target.value;
    console.log("value =>", value);
    updatedAddForm[inputIdentifier].value = value;
    setAddForm({...updatedAddForm});
    if(inputIdentifier==='country'){
      setId(value)   
    }
    if(inputIdentifier==='state'){
      setIdLocation(value)
    }
    refreshFormFeedBack(inputIdentifier);
    setValid({
      valid:false,
      notvalid:false
    })
  }


  // for managing render of select input of locations and city on te form depending of id's country and id location

  useEffect(()=>{
    if(id){
      const data={id,idLocation}
      props.statesAction(id)
      const states=props.states.dataStates
      setLocations(states)
      if (idLocation){
        props.getCitiesAction(data)
        const currentCities=props.cities.dataCities
        setCities(currentCities)
      }
      const codes=Services.filterData(countries,'dial_code',id)
      const updatedAddForm=Services.assignDialcode(addForm,codes,'dialcode',null)
      setAddForm({...updatedAddForm})
    }
  },[id,idLocation,Locations])
  
  

  // setting values of socialmedia fields to the addform state**************************************
  const onOtherChange=(event,index,keydata)=>{
    const target=event.target
    const targets=target.name
    const updatedAddForm={...addForm}
    if (index>=0) {
      updatedAddForm[keydata].value[index][targets]=target.value    
    }
    else{
      updatedAddForm[keydata].value[targets]=target.value    
    }
    setAddForm({...updatedAddForm})
    setValid({
      valid:false,
      notvalid:false
    })
  
  }


  //manage form validation*****************************************************

  const refreshFormFeedBack = (inputIdentifier,index) => {
    const updatedAddForm = {...addForm};
    let rules=null,value=null
    console.log(inputIdentifier)
    switch (inputIdentifier) {
      case 'label':
        inputIdentifier='social_media'
        value=updatedAddForm[inputIdentifier].value[index].label
        rules=updatedAddForm[inputIdentifier].rules.rules1
        break;
      case 'url':
        inputIdentifier='social_media'
        value=updatedAddForm[inputIdentifier].value[index].url
        rules=updatedAddForm[inputIdentifier].rules.rules2
        break;      
      default:
        value = updatedAddForm[inputIdentifier].value;
        rules = updatedAddForm[inputIdentifier].rules;
        break;
    }
      const valid = validator.current.check(value, rules);
    if(valid) {
      updatedAddForm[inputIdentifier].classes = 'form-group';
      validator.current.hideMessageFor(addForm[inputIdentifier].name);
    } else {
      updatedAddForm[inputIdentifier].classes = 'form-group invalid';
      validator.current.showMessageFor(addForm[inputIdentifier].name);
    }
    setAddForm({...updatedAddForm});
  }

   //function prepare formdata for request**********************************************
  



// submit button function of the form*************************************************

const onSubmit = (event) => {
  event.preventDefault();
  
  if(validator.current.allValid() && addForm.agree_tc.value) {
  const data=Services.transformData(addForm)
  props.addListingAction(data)
  refreshFields()
  setValid({
    valid:true,
    notvalid:false
  })
  navigate("/dashboard-listings",{replace:true})

  } else {
    validator.current.showMessages();
    setRefresh(true);
    setValid({
      valid:false,
      notvalid:true
    })
  }
}

// refresh fields*************************************************************************

function refreshFields() {
  setAddForm({
    title: {
      name: 'titre',
      value: '',
      classes: 'form-group',
      rules: 'required|min:2'
  }, description: {
    name: 'description',
    value: '',
    classes: 'form-group',
    rules: 'required|min:10|max:200'
  }, tagline: {
    name: 'tagline',
    inputValue:'',
    value: [],
    classes: 'form-group',
    rules: 'required'
  }, category: {
    name: 'category',
    value: '',
    classes: 'form-group',
    rules: 'required'
  },
  country: {
    name: 'country',
    value: '',
    classes: 'form-group',
    rules: 'required'
  }, 
  state: {
    name: 'state',
    value: '',
    classes: 'form-group',
    rules: 'required'
  }, city: {
    name: 'city',
    value: '',
    classes: 'form-group',
    rules: 'required'
  },
  hideInfo: {
    name: 'hideInfo',
    value: true,
    classes: 'form-group',
    rules: 'boolean'
  },
  adress: {
    name: 'adress',
    value: '',
    classes: 'form-group',
    rules: 'required|min:8'
  },
  dialcode:{
    name:'dialcode',
    value:'',
    classes:'form-group',
    rules:'required'
  },
  phoneNumber:{
    name:'phoneNumber',
    value:'',
    classes:'form-group',
    rules:'required|phone|max:12'
  },
  email: {
    name: 'email',
    value: '',
    classes: 'form-group',
    rules: 'required|email'
  },
  website: {
    name: 'website',
    value: '',
    classes: 'form-group',
    rules: 'url'
  },
  img:{
    name:'img',
    value:[],
    imgFile:[],
    classes:'form-group',
    rules:''
  },
  social_media: 
    {
    name:'social_media',
    value: [{label:'',url:''}],
    classes: 'form-group',
    rules:{rules1:'string',rules2:'url'}
    },
  agree_tc: {
      name: 'agree_tc',
      value: true,
      classes: 'form-group',
      rules: 'boolean'
    } ,

    authorId:{
      value:loginIn!== null ? loginIn.email : null
    }  
})

setTagValue({
  inputValue:'',
  value:[]
})
  
}//end of function

//uploading images*************************************************************************

const onFileChange=(event)=>{
  event.preventDefault()
  const {files}=event.target
  console.log(files)
  const validImagesFiles=[]
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    if (file.type.match(imageTypeRegex)){
      validImagesFiles.push(file)
    }   
  }
  if(validImagesFiles.length){
    const updatedAddForm={...addForm}
    updatedAddForm['img'].imgFile=[...validImagesFiles]
    setAddForm({...updatedAddForm})
  }
  console.log(addForm.img.value)
}


//social media manager of fields***********************************************************
const addNewFields=(event)=>{
  event.preventDefault()
  const updatedAddForm={...addForm}
  updatedAddForm['social_media'].value=[...updatedAddForm['social_media'].value,{label:'',url:''}]
  setAddForm({...updatedAddForm})
}


//deleting ligns's fields of social media****************************************************************

const removeFields=(keytitle,keyvalue,index)=>{
  const updatedAddForm={...addForm}
  updatedAddForm[keytitle][keyvalue].splice(index,1)
  if (keytitle==='img') {
    updatedAddForm[keytitle].value.splice(index,1)
  }
  setAddForm({...updatedAddForm}) 
}


//use effect for previewing image***********************************************************
useEffect(()=>{

const imgs=[],fileReaders=[],updatedAddForm={...addForm}
let isCancel=false
if (updatedAddForm['img'].imgFile.length) {
  updatedAddForm['img'].imgFile.forEach((file)=>{

    const fileReader=new FileReader()
    fileReaders.push(fileReader)
    fileReader.onload=(event)=>{
      const {result}=event.target
      if (result) {
        console.log(result)
        imgs.push(result)
    }
    if(imgs.length===updatedAddForm['img'].imgFile.length && !isCancel){
      updatedAddForm['img'].value=[...imgs]
      setAddForm({...updatedAddForm})
      console.log(addForm)
    }
  }
    fileReader.readAsDataURL(file)
  })    
}

return () =>{
  isCancel=true
  fileReaders.forEach(fileReader=>{
    if (fileReader.readyState===1) {
      fileReader.abort()    
    }
  })
}
},[addForm.img.imgFile])


// to upload and transfert data to the backend**********************************************************

// const onFileUpload=(event)=>{
//   //create an object of formdata
//   const formData=new FormData()

//   console.log(formData)

//   //update the formdata object
//   console.log(event.target.files)

//   formData.append(
//     "myFile",
//     addForm['img'].value,
//     addForm['img'].value.name
//   )
//  // axios.post("api/formload",formData)
//   console.log(addForm['img'].value,formData)

// }


const handleChange=(value)=>{
  console.group('value changed')
  console.log(value)
  console.groupEnd()
  const updatedTagValue={...tagValue}
  updatedTagValue.value=value
  setTagValue({...updatedTagValue})
}
  

const handleKeyDown=(event)=>{
  console.log(tagValue)
  if (!tagValue.inputValue) {
    return;
  }
  switch (event.key) {
    case 'Enter':
      case ' ':
        case 'Tab':
            console.log(tagValue)
            setTagValue({
              inputValue:'',
              value:[...tagValue.value,createOption(tagValue.inputValue)],
            })
            event.preventDefault()
        break
    default:
        break       
  }

}

useEffect(()=>{
  setAddForm({
    ...addForm,
    tagline:{
      ...addForm.tagline,
      inputValue:'',
      value:[...tagValue.value],
    }
  })
},[tagValue.value])


const handleInputChange=(inputValue)=>{
  const updatedTagValue={...tagValue}
  updatedTagValue.inputValue=inputValue
  setTagValue({...updatedTagValue})
}


const createOption=label=>({
  label,
  value:label

})

//************************************useEffect to manage rules of socialmedia************************************************* */

useEffect(()=>{
  if (addForm['social_media'].value.length>1) {
      const updatedAddForm={...addForm}
      updatedAddForm['social_media'].rules.rules1='required'
      updatedAddForm['social_media'].rules.rules2='required|url'
      setAddForm({...updatedAddForm})  
        
  }else{
      const updatedEditForm={...addForm}
      updatedEditForm['social_media'].rules.rules1='string'
      updatedEditForm['social_media'].rules.rules2='url'
      setAddForm({...updatedEditForm}) 
  }
},[addForm['social_media'].value.length])

//*************************************************************************************************************** */

const triggerInputFile=(event)=>{
  event.preventDefault()
  var fileSelect=document.getElementById("fileSelect")
  var fileElem=document.getElementById("fileElem")
  fileSelect.addEventListener("click",(event)=>{
      event.preventDefault()
      if (fileElem) {
        fileElem.click()
        event.preventDefault()
         
      }
  },false)
}
  
    

  return (
    <Fragment>
      {/* Header section start */}
      <section className="header-breadcrumb bgimage overlay overlay--dark">
        <div className="bg_image_holder"><img src="./assets/img/breadcrumb1.jpg" alt="" /></div>
        <div className="mainmenu-wrapper">
            <Header logo={light} class="menu--dark" />                    
        </div>
        {/* <!-- ends: .mainmenu-wrapper --> */}
        <BreadcrumbWraper title={t('addlisting.generalinformation')} />
      </section>
      {/* Header section end */}

      <form action="/" onSubmit={event=>onSubmit(event)}>
        <section className="add-listing-wrapper border-bottom section-bg section-padding-strict">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4><span className="la la-user"></span>{t('addlisting.generalinformation')}</h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents">
                    <div className={addForm['title'].classes}>
                      <label htmlFor="title" className="form-label">{t('addlisting.title')}</label>
                      <input name="title" type="text" 
                              className="form-control" id="title" placeholder={t('addlisting.titleplaceholder')}
                              value={addForm['title'].value} 
                              onBlur={event=>onBlur(event,null)} 
                              onChange={onChange.bind(this)}/>
                      {validator.current.message(addForm['title'].name, addForm['title'].value, addForm['title'].rules)}
                    </div>
                    <div className={addForm['description'].classes}>
                      <label htmlFor="desc" className="form-label">{t('addlisting.description')}</label>
                      <textarea id="desc" rows="8" name="description" 
                          className="form-control" placeholder={t('addlisting.descriptionplaceholder')} 
                          value={addForm['description'].value} 
                          onBlur={event=>onBlur(event,null)} 
                          onChange={onChange.bind(this)}></textarea>
                      {validator.current.message(addForm['description'].name, addForm['description'].value, addForm['description'].rules)}
                      <div className={addForm['tagline'].classes}>
                          <label htmlFor="tagline" className="form-label">{t('addlisting.tagLine')}</label>
                          <CreatableSelect
                          isClearable
                          isMulti
                          components={
                            {DropdownIndicator:null}
                          }
                          inputValue={tagValue.inputValue}
                          menuIsOpen={false}
                          onChange={handleChange}
                          placeholder={t('addlisting.taglineplaceholder')}
                          onKeyDown={handleKeyDown}
                          onInputChange={handleInputChange}
                          value={tagValue.value} />

                      </div>                    
                    </div>

                    <div className={addForm['category'].classes}>
                      <label htmlFor="ad_categroy" className="form-label">{t('addlisting.category')}</label>
                      <div className="select-basic">
                        <select name="category" 
                          className="form-control" id="categry00" 
                          value={addForm['category'].value}
                          onBlur={event=>onBlur(event,null)} 
                          onChange={onChange.bind(this)}>
                          <option>{t('addlisting.categoryselect')}</option>
                          {
                            Object.values(categories).map((cat,index) => {
                              return (<option key={index} value={cat.id}>{cat.label}</option>);
                            })
                          }
                          
                        </select>
                      </div>
                      {validator.current.message(addForm['category'].name, addForm['category'].value, addForm['category'].rules)}
                    </div>
                    
                    
                    <div className={addForm['country'].classes}>
                      <label htmlFor="country" className="form-label">{t('addlisting.country')}</label>
                      <div className="select-basic">

                        <select className="form-control"
                         name={addForm['country'].name}
                         value={addForm['country'].value}
                         onBlur={event=>onBlur(event,null)}
                         onChange={onChange.bind(this)}
                         id="country_00">
                           <option>{t('addlisting.countryselect')}</option>
                          {
                            countries.map((country, index) => {
                              return (<option key={index} value={country.id}>{country.name}</option>);
                            })
                          }
                        </select>
                      </div>
                      {validator.current.message(addForm['country'].name, addForm['country'].value, addForm['country'].rules)}
                    </div>

                    <div className={addForm['state'].classes}>
                      <label htmlFor="state" className="form-label">{t('addlisting.state')}</label>
                      <div className="select-basic">
                        <select name={addForm['state'].name} 
                        className="form-control" id="state_00"
                        value={addForm['state'].value}
                        onBlur={event=>onBlur(event,null)}
                        onChange={onChange.bind(this)}>
                        <option>{t('addlisting.stateselect')}</option>

                          { 
                            Locations && Locations.length>0 ?
                            Locations.map((currentLocation,index)=>{
                              return (<option key={index} value={currentLocation.id}>{currentLocation.name}</option>)
                            }):null
                          }
                          
                        </select>
                        {validator.current.message(addForm['state'].name, addForm['state'].value, addForm['state'].rules)}
                      </div>
                    </div>{/*<!-- ends: .form-group -->*/}

                  {/* //here come cities */}
                    <div className={addForm['city'].classes}>
                      <label htmlFor="city" className="form-label">{t('addlisting.city')}</label>
                      <div className="select-basic">
                        <select name={addForm['city'].name} className="form-control" id="city"
                        value={addForm['city'].value}
                        onBlur={event=>onBlur(event,null)}
                        onChange={onChange.bind(this)}>
                        <option>{t('addlisting.cityselect')}</option>
                          {
                            cities && cities.length>0 ?
                            cities.map((city,index) => {
                              return (<option key={index} value={city.id}>{city.name}</option>);
                            }):null
                          }
                        </select>
                        {validator.current.message(addForm['city'].name, addForm['city'].value, addForm['city'].rules)}
                      </div>
                    </div>
                  </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                </div>{/*<!-- ends: .atbd_content_module -->*/}
              </div>{/*<!-- ends: .col-lg-10 -->*/}
              <div className="col-lg-10 offset-lg-1">
                  <div className="atbd_content_module">
                      <div className="atbd_content_module__tittle_area">
                          <div className="atbd_area_title">
                              <h4><span className="la la-user"></span>{t('addlisting.contactInformation')}</h4>
                          </div>
                      </div>
                      <div className="atbdb_content_module_contents">
                              <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary m-bottom-20">
                                  <input type="checkbox" className="custom-control-input" id="hide_contace_info"
                                  defaultChecked={addForm['hideInfo'].value}
                                  onChange={onChange.bind(this)} name={addForm['hideInfo'].name} />
                                  <label className="custom-control-label" htmlFor="hide_contace_info">{t('addlisting.contactInformationcheck')}</label>
                                  {validator.current.message(addForm['hideInfo'].name,addForm['hideInfo'].value,addForm['hideInfo'].rules)}
                              </div>
                              <div className={addForm['adress'].classes}>
                                  <label htmlFor="address" className="form-label">{t('addlisting.address')}</label>
                                  <input type="text" 
                                  name={addForm['adress'].name} value={addForm['adress'].value} onChange={onChange.bind(this)}
                                  onBlur={event=>onBlur(event,null)} 
                                  placeholder={t("addlisting.addressplaceholder")} id="address" className="form-control" required />
                                  {validator.current.message(addForm['adress'].name, addForm['adress'].value, addForm['adress'].rules)}
                              </div>


                              <div className='form-group'>
                                <label className="form-label">{t('addlisting.phoneNumber')}</label>
                                  <div >
                                  {/*<Formfield fields={fields} handleChange={handleChange} socialMedias={socialMedias} onBlur={onBlur} t={t}/> */}
                                   
                                      <div className="directorist ">
                                          <div className="row m-bottom-20">
                                              <div className="col-sm-3">

                                                  <div className={addForm['dialcode'].classes}>
                                                      <div className="select-basic">
                                                          <input type='text' className="form-control"
                                                          name={addForm['dialcode'].name}
                                                          disabled
                                                          onBlur={event=>onBlur(event,null)}
                                                          onChange={onChange.bind(this)} 
                                                          value={addForm['dialcode'].value}/>
                                                        {validator.current.message(addForm['dialcode'].name,addForm['dialcode'].value,addForm['dialcode'].rules)}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-sm-9">
                                                  <div className={addForm['phoneNumber'].classes}>
                                                      <input type="text" 
                                                      name={addForm['phoneNumber'].name}
                                                      value={addForm['phoneNumber'].value.number}
                                                      onChange={onChange.bind(this)}
                                                      onBlur={event=>onBlur(event,null)}
                                                      placeholder={t("addlisting.phoneNumberplaceholder")} className="form-control"/>
                                                      {validator.current.message(addForm['phoneNumber'].name, addForm['phoneNumber'].value, addForm['phoneNumber'].rules)}
                                                      
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>



                              {/* <div className={addForm['phone_number'].classes}>
                                  <label htmlFor="phone_number" className="form-label">{t('listing.phoneNumber')}</label>
                                  <input type="text" 
                                  name={addForm['phone_number'].name} onChange={onChange.bind(this)} value={addForm['phone_number'].value}
                                  onBlur={onBlur.bind(this)} 
                                  placeholder="Phone Number" id="phone_number" className="form-control"/>
                                  {validator.current.message(addForm['phone_number'].name, addForm['phone_number'].value, addForm['phone_number'].rules)}
                              </div> */}




                              <div className={addForm['email'].classes}>
                                  <label htmlFor="contact_email" className="form-label">{t('addlisting.email')}</label>
                                  <input type="email" id="contact_email"
                                  name={addForm['email'].name} value={addForm['email'].value} onChange={onChange.bind(this)}
                                  onBlur={event=>onBlur(event,null)}  
                                  className="form-control" placeholder={t("addlisting.emailplaceholder")} />
                                  {validator.current.message(addForm['email'].name, addForm['email'].value, addForm['email'].rules)}
                              </div>
                              <div className={addForm['website'].classes}>
                                  <label htmlFor="website_address" className="form-label">{t('addlisting.website')}</label>
                                  <input type="text"
                                  name={addForm['website'].name} value={addForm['website'].value}
                                  onChange={onChange.bind(this)}
                                  onBlur={event=>onBlur(event,null)}  
                                  id="website_address" className="form-control" placeholder={t("addlisting.websiteplaceholder")}/>
                                  {validator.current.message(addForm['website'].name, addForm['website'].value, addForm['website'].rules)}
                              </div>
                            
                              
                                <div className='form-group'>
                                <label className="form-label">{t('addlisting.socialInformation')}</label>
                                  <div id="social_info_sortable_container" >
                                  {/*<Formfield fields={fields} handleChange={handleChange} socialMedias={socialMedias} onBlur={onBlur} t={t}/> */}
                                   {
                                    addForm['social_media'].value.map((socialmedia,index)=>{
                                      return(
                                      <div className="directorist atbdp_social_field_wrapper" key={index}>
                                          <div className="row m-bottom-20">
                                              <div className="col-sm-4">

                                                  <div className={addForm['social_media'].classes}>
                                                      <div className="select-basic">
                                                          <select className="form-control"
                                                          name='label' 
                                                          value={addForm['social_media'].value[index].label}
                                                          onBlur={event=>onBlur(event,index)}
                                                          onChange={event=>onOtherChange(event,index,'social_media')}>
                                                              <option>{t('addlisting.socialmediaselect')}</option>
                                                            {
                                                              socialMedias && socialMedias.length>0 ?
                                                            Object.values(socialMedias).map((item,i)=>{
                                                              return(<option key={i} value={item.id}>{item.label}</option>)
                                                            }):null
                                                            }
                                                          </select>
                                                        {validator.current.message('label',addForm['social_media'].value[index].label,addForm['social_media'].rules.rules1)}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-sm-6">
                                                  <div className={addForm['social_media'].classes}>
                                                      <input type="text" 
                                                      name='url'
                                                      value={addForm['social_media'].value[index].url}
                                                      onChange={event=>onOtherChange(event,index,'social_media')}
                                                      onBlur={event=>onBlur(event,index)}
                                                      className="form-control directory_field atbdp_social_input" placeholder={t("addlisting.socialmediaadress" )}/>
                                                      {validator.current.message('url',addForm['social_media'].value[index].url,addForm['social_media'].rules.rules2)} 
                                                  </div>
                                              </div>
                                              <div className="col-sm-2 d-flex align-items-center">
                                                  <span onClick={addForm['social_media'].value.length>1 ? ()=>removeFields('social_media','value',index) : null} className="removeSocialField btn-danger" id="removeSocial" title="Remove this item"><i className="la la-times"></i></span>
                                                  <span className="adl-move-icon btn-secondary"><i className="la la-arrows"></i></span>
                                              </div>
                                          </div>
                                      </div>

                                      )})}
                                  </div>
                              </div>
            
                                <button onClick={ validator.current.fieldValid('url') && validator.current.fieldValid('label') && addForm['social_media'].value.length < 5 ? addNewFields.bind(this) : null} className="copy-btn btn btn-sm btn-secondary"><i className="la la-plus"></i> {t('addlisting.addnew')}</button>
                           
                      </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                  </div>{/*<!-- ends: .atbd_content_module -->*/}
              </div>{/*<!-- ends: .col-lg-10 -->*/}
              
              <div className="col-lg-10 offset-lg-1">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4><span className="la la-calendar-check-o"></span> {t('addlisting.image')}</h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents">
                    <div id="_listing_gallery">
                      <div className="add_listing_form_wrapper" id="gallery_upload">
                        <div className="form-group text-center">
                          {/*<!--  add & remove image links -->*/}
                          <p className="hide-if-no-js">
                          <input type="file" id='fileElem' className='input-file form-group btn btn-outline' onChange={onFileChange.bind(this)} accept="image/png, image/jpg, image/jpeg" style={{display:"none"}}/>
                          <button id="fileSelect" onClick={event=>triggerInputFile(event)} className="upload-header btn btn-outline-secondary">{t('addlisting.uploadpreviewimage')}</button>
                            
                          </p>
                          
                        </div>
                        {addForm['img'].value.length>0 ?
                        <div className="form-group text-center">
                          {/*<!-- image container, which can be manipulated with js -->*/}
                          <div className="listing-img-container">
                            {
                            addForm['img'].value.map((imgs,index)=>{
                              return (<p key={index}><img src={imgs} alt="No_Image_Found" style={{width:100,height:80}} /><span onClick={()=>removeFields('img','value',index)} className="removeSocialField btn-danger"  title={t("addlisting.removethisitem")}><i className="la la-times"></i></span>
                              {addForm['img'].imgFile.length>0 && addForm['img'].imgFile[0].name}</p>)
                            })               
                            }
                          </div>
                        </div>
                          :null
                        }
                      </div>
                      {/*<!--ends add_listing_form_wrapper-->*/}
                    </div>
                  </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                </div>{/*<!-- ends: .atbd_content_module -->*/}
              </div>{/*<!-- ends: .col-lg-10 -->*/}
              <div className="col-lg-10 offset-lg-1 text-center">
                <div className="atbd_term_and_condition_area custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                  <input type="checkbox" onChange={onChange.bind(this)} onBlur={event=>onBlur(event,null)} className="custom-control-input" name={addForm['agree_tc'].name} defaultChecked={addForm['agree_tc'].value} id="listing_t" />
                  <label htmlFor="listing_t" className="not_empty custom-control-label">{t('addlisting.iagreewithall')} <a href=" " id="listing_t_c">{t('addlisting.termsandconditions')}</a></label>
                  {validator.current.message(addForm['agree_tc'].name,addForm['agree_tc'].value,addForm['agree_tc'].rules)} 
                </div>
                <div className="btn_wrap list_submit m-top-25">
                  <button type="submit" className="btn btn-primary btn-lg listing_submit_btn">{t('addlisting.submit')}</button>
                </div> <br/>
                {valid.notvalid && (<div className="alert alert-danger" role="alert" ><i class="la la-times-circle-o"></i>
                {t('addlisting.messagenonOk')}
                          </div>)}
                          {valid.valid && <div className="alert alert-success" role="alert" ><i className="la la-check-circle"></i>
                          {t('addlisting.messageOk')}
                                          </div>}
              </div>{/*<!-- ends: .col-lg-10 -->*/}
              
            </div>
          </div>
        </section>{/*<!-- ends: .add-listing-wrapper -->*/}
      </form>
      <Footer />
    </Fragment>
  )
}


const mapStateToProps = state => {
  console.log(state)
    return {
        list: state.list,
        login : state.login,
        logo: state.logo,
        categories: state.category,
        countries: state.setup.countries,
        socialmedia:state.socialmedia.socialmedialist,
        states:state.states,
        cities:state.cities
    }    
}


const mapDispatchToProps=dispatch=>{
  return{
    addListingAction:(data)=>{dispatch({type:ADD_NEW_LIST,data})},
    statesAction:(id)=>{dispatch({type:GET_STATES_BY_ID,id})},
    getCitiesAction:(data)=>{dispatch({type:GET_CITIES_BY_ID,data})}
  };
  
}




export default connect(mapStateToProps,mapDispatchToProps)(AddListing);