

var item=localStorage.getItem("user")
const initState = JSON.parse(item)
export const SET_PROFIL='SET_PROFIL'

const ProfileReducer = (state = initState, action) => {
    switch(action.type){
        case SET_PROFIL :   
            localStorage.setItem("user", action.data);
        return JSON.parse(localStorage.getItem('user'));  
        default:
            return state;
    }
}
export default ProfileReducer