import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { SignUp } from '../../../../store/action/userActions';
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';
import FacebookLogin from 'react-facebook-login'
import { LogInAc } from '../../../../store/action/loginAction';
import { setProfil } from '../../../../store/action/profileAction';

class Register extends Component {
    constructor (props) {
        super (props)
        this.state = {
            firstName:'',
            lastName:'',
            fullName: '',
            email: '',
            password: '',
            profile:'./assets/img/author-profile.jpg',
            createAt:new Date().toISOString()
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    render() {
        var data = this.props.users

        const  responseFacebook=(response)=>{
        
         
            this.setState({
                 isLoggedIn:true,
                 fullName:response.name,
                 email:response.email?response.email:response.userID,
                 profile:response.picture.data.url
     
             },()=>{
                 const info={...this.state}
                 
                 const filter = data.filter(item => {
                     return item.email === this.state.email
                 });
                 if(filter.length){
                     this.props.logindata(this.state);
                     $("#signup_modal").click();
                  } else {
                 data.push(info)
                 this.props.setProfil([...data])
                 this.props.logindata(this.state);
                    $("#signup_modal").click();
                  }
                 
             })
             
         }
        const addUser = (e) => {
            e.preventDefault();
            if (this.validator.allValid()) {
                const filter = data.filter(item => {
                    return item.email === this.state.email
                });

                if(filter.length){
                   alert("Email already exists");
                } else {
                        this.setState({
                            fullName:this.state.firstName.toUpperCase() +' '+this.state.lastName.toUpperCase()
                        },()=>{
                            data.push(this.state) 
                        this.props.userAdd([...data]).then(() => {
                        alert('You submitted the form and stuff!');
                        $("#signup_modal").click();
                    })
                        })
                    
                  
                    var success = true;
                    return success;
                }

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                var success2 = false;
                return success2;
            }
        }
        const quitSignUp=()=>{
            $("#signup_modal").click();
        }

        return (
            <Fragment>
                <div className="modal fade" id="signup_modal" tabIndex={-1} role="dialog" aria-labelledby="signup_modal_label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="signup_modal_label"><i className="la la-lock" /> Sign Up</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="/" id="signup-form">
                                     <input type="text" name="firstName" value={this.state.firstName} onChange={this.setStateFromInput} className="form-control" placeholder="first name" required />
                                    <div className="text-danger">{this.validator.message('First name', this.state.email, 'required|25')}</div>

                                     <input type="text" name="lastName" value={this.state.lastName} onChange={this.setStateFromInput} className="form-control" placeholder="last name" required />
                                    <div className="text-danger">{this.validator.message('Last name', this.state.email, 'required|25')}</div>

                                    <input type="email" name="email" value={this.state.email} onChange={this.setStateFromInput} className="form-control" placeholder="Email" required />
                                    <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|email')}</div>

                                    <input type="password" name="password" value={this.state.password} onChange={this.setStateFromInput} className="form-control" placeholder="Password" required />
                                    <div className="text-danger">{this.validator.message('Password', this.state.password, 'required|string')}</div>

                                    <button type="submit" onClick={addUser} className="btn btn-block btn-lg btn-gradient btn-gradient-two">Sign Up</button>
                                </form>
                                <div className="form-excerpts">
                                    <ul className="list-unstyled">
                                        <li>Already a member? <NavLink to="/at_demo" onClick={quitSignUp}data-toggle="modal" data-target="#login_modal">Sign In</NavLink></li>
                                        <li><NavLink onClick={quitSignUp} to="/at_fb_demo"data-toggle="modal" data-target="#recover_modal">Recover Password</NavLink></li>
                                    </ul>
                                    <div className="social-login">
                                        <span>Or Signup with</span>
                                        <p>
                                        <FacebookLogin
                                            appId="1288419661906557"
                                            autoLoad={false}
                                            fields="name,email,picture"
                                            size='small'
                                            icon="fa-facebook"
                                            textButton="Facebook"
                                            typeButton="btn btn-outline-secondary"
                                            callback={responseFacebook} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        users: state.users
    }
}
const mapDispatchToProp = dispatch => {
    return {
        userAdd : (user) => dispatch(SignUp(user)),
        setProfil : (profilUser) => dispatch(setProfil(profilUser)),
        logindata : (login) => dispatch(LogInAc(login))
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(Register);