import React, { useState, useMemo,useEffect} from 'react'
import { Modal, Button } from 'react-bootstrap'
import Avatar from 'react-avatar-edit'
import {setDetailProfil} from '../../../store/action/author-profilAction'
import { setProfil } from '../../../store/action/profileAction'
import { connect } from "react-redux"
import './helpers/authorProfile.css'
import Select from "react-select";
import{ City, Country } from 'country-state-city'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'


function AuthorProfil({currentUserId,setProfil,users,setDetailProfil,authorProfiles,login}) {
  const {t}=useTranslation()
    const [image,setImage]=useState("")

    const [alertSave,setAlertSave]=useState(false)
 
    const [values,setValues]=useState({country:'',city:''})

  
    const [defaultData] = useState({
        fullName:login!==null ? JSON.parse(localStorage.getItem('login')).fullName:'', 
         firstName:login!==null ? JSON.parse(localStorage.getItem('login')).firstName:'',
         lastName:login!==null ? JSON.parse(localStorage.getItem('login')).lastName:'',
          email: login!==null ? JSON.parse(localStorage.getItem('login')).email:'',
        phoneNumber:'', linkWebsite:'', address:'', linkFacebook:'', linkLinkedln: ''
      , linkTwitter:'', linkYoutube:'', aboutAuthor:''
    });
    

     const {register,handleSubmit,reset,formState:{errors}}=useForm({mode:"all",defaultValues:defaultData})

document.title = 'Author Profile - HOGO Places';

const [validationCity,setValidationCity]=useState(false)
const [validationCountry,setValidationCountry]=useState(false)
  const onSubmit = (data) => {

        if(validationCity && validationCountry)
        {
          const info={currentUserId,...data,values}
        
        const list= Object.values(users).filter(u=>{
            
            if(u.email===currentUserId)
            {
                u.fullName=info.fullName

            }
           
            return u
        })
        setProfil(list)  
        setDetailProfil(info)
        setAlertSave(!false)
      }else{
        setValidationCity(false)
        setValidationCountry(false)
      }
     
       
  }

   
   
   
    useMemo(()=>{
            const user= Object.values(users).filter(user=>user.email===currentUserId)
       
            const profile=  user[0].profile
            setImage(profile)
         
    },[currentUserId, users])
    
    const recharge=()=>{
    if(authorProfiles.detailsProfile.length!==0)
       { 
        
        const info=Object.values(authorProfiles.detailsProfile).filter(item=>item.currentUserId===currentUserId)
        if(info.length===1)
    {         
    const updatedAddForm = {...defaultData};
    updatedAddForm.fullName=info[0].fullName
    updatedAddForm.firstName=info[0].firstName
    updatedAddForm.lastName=info[0].lastName
    updatedAddForm.email=info[0].email
    updatedAddForm.linkWebsite=info[0].linkWebsite
    updatedAddForm.phoneNumber=info[0].phoneNumber
    updatedAddForm.address=info[0].address
    updatedAddForm.linkFacebook=info[0].linkFacebook
    updatedAddForm.linkTwitter=info[0].linkTwitter
    updatedAddForm.linkYoutube=info[0].linkWebsite
    updatedAddForm.linkLinkedln=info[0].linkWebsite
    updatedAddForm.aboutAuthor=info[0].aboutAuthor
    setValues({
      country:info[0].values.country,city:info[0].values.city})
      // console.log(info)
      reset(updatedAddForm);
    
    }
      }
     
       }
       useEffect(()=>{
        recharge() 
         // eslint-disable-next-line react-hooks/exhaustive-deps
       },[])
      // console.log(values)

    
  const onDelete=(userId)=>{
   
    const list= Object.values(users).filter(u=>{
        if(u.email===userId)
        {
            u.profile="./assets/img/author-profile.jpg"
        }
        return u
    })
   
    setProfil(list) 
    setImage("./assets/img/author-profile.jpg")
 
  }
  const onSave=(data)=>{
   const list= Object.values(users).filter(u=>{
        if(u.email===data.userId)
        {
            u.profile=data.profile
        }
        return u
    })
   
    setProfil(list) 
    setImage(data.profile)
 
  }
  const countries = Country.getAllCountries();


  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country
  }));

   const updatedCities = (countryId) =>
     { 
   
     return  City
      .getCitiesOfCountry(countryId)
         .map((city) => ({ label: city.name, value: city.id, ...city }));

    }
   

    
  return (
    <div className="tab-pane fade p-bottom-30" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 mb-5 mb-lg-0">
                                                <div className="user_pro_img_area">
                                                    <img src={image} alt="profile" style={{height:120,width:120}} />
                                                    <div className="image-info">
                                                        <h6>{t('author.profile.image')}</h6>
                                                        <span>JPG or PNG 120x120 px</span>
                                                    </div>
                                                       <ModalDialog users={users} onDelete={onDelete} onSave={onSave}  currentUserId={currentUserId} />

                                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-9 col-md-8">
                                           { alertSave &&<div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <strong>{t('author.profile.message.success.title')} </strong>{t('author.profile.message.success.content')}
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            </div>}
                                            
                                           
                                                <div className="atbd_author_module">
                                                    <div className="atbd_content_module">
                                                        <div className="atbd_content_module__tittle_area">
                                                            <div className="atbd_area_title">
                                                                <h4><span className="la la-user"></span>{t('author.profile')}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="atbdb_content_module_contents">
                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className="user_info_wrap">
                                                                {/*<!--Full name-->*/}
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" >
                                                                            <label htmlFor="full_name" className="not_empty">{t("author.profile.fullName")}</label>
                                                                            <input className={`form-control ${errors.fullName? 'is-invalid' : ''}`} name="fullName"  type="text" placeholder={t("author.profile.fullName")} id="full_name" 
                                                                              {...register("fullName",{required:t("author.profile.fullName.message"),minLength:{value:5,message:t("author.profile.fullName.message")} })} />
                                                                               <div className="form-text text-danger">{errors.fullName?.message}</div> 
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="user_name" className="not_empty">{t("author.profile.userName")}</label>
                                                                            <input className="form-control"  id="user_name" type="text" disabled="disabled" value={currentUserId} />
                                                                            <p>({t("author.profile.warning.username")})</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" >
                                                                            <label htmlFor="first_name"  className="not_empty">{t("author.profile.firstName")}</label>
                                                                            <input className={`form-control ${errors.firstName? 'is-invalid' : ''}`} name={`form-control ${errors.firstName? 'is-invalid' : ''}`} id="first_name" type="text"  placeholder={t("author.profile.firstName")}
                                                                              {...register("firstName",{required:t("author.profile.firstName.message"),minLength:{value:4,message:t("author.profile.firstName.message")}})} />
                                                                              <div className="form-text text-danger">{errors.firstName?.message}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" >
                                                                            <label htmlFor="last_name"  className="not_empty">{t("author.profile.lastName")}</label>
                                                                            <input className={`form-control ${errors.lastName? 'is-invalid' : ''}`} id="last_name"   type="text"  placeholder={t("author.profile.lastName")}
                                                                             {...register("lastName",{required:t("author.profile.lastName.message"),minLength:{value:4,message:t("author.profile.lastName.message")}})} />
                                                                             <div className="form-text text-danger">{errors.lastName?.message}</div>
                                                                                
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" >
                                                                            <label htmlFor="req_email"  className="not_empty">{t("author.profile.email")} ({t('required')})</label>
                                                                            <input className={`form-control ${errors.email? 'is-invalid' : ''}`}  name="email" id="req_email" type="text"   placeholder="mail@example.com"  
                                                                            {...register("email",{required:t("author.profile.email.message")})} />
                                                                            <div className="form-text text-danger">{errors.email?.message}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="phone"  className="not_empty">{t("author.profile.CellNumber")} </label>
                                                                            <input className={`form-control ${errors.phoneNumber? 'is-invalid' : ''}`} name='phoneNumber'  type="tel" placeholder={t("author.profile.CellNumber")} id="phone" 
                                                                               {...register("phoneNumber",{required:t("author.profile.CellNumber.message"), pattern: /^[0-9]+$/i})} />
                                                                               <div className="form-text text-danger">{errors.phoneNumber?.message}</div>
                                                                               <div className="form-text text-danger">{errors.phoneNumber?.type==="pattern" && t("author.profile.CellNumber.message.pattern")}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="website"  className="not_empty">{t("author.profile.website")}</label>
                                                                            <input className={`form-control ${errors.linkTwitter? 'is-invalid' : ''}`} name='linkWebsite'  type="text" placeholder="https://www.example.ca" 
                                                                               {...register("linkWebsite")} />
                                                                             
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="address"  className="not_empty">{t("author.profile.Address")}</label>
                                                                            <input className={`form-control ${errors.address? 'is-invalid' : ''}`}  name="address" id="address" type="text" placeholder={t("author.profile.Address")}
                                                                              {...register("address",{required:t("author.profile.Address.message")})} />
                                                                              <div className="form-text text-danger">{errors.address?.message}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                      <div className="form-group">
                                                                            <label htmlFor="address" className="not_empty"><b>{t("author.profile.Country")}</b></label>
                                                                        <Select
                                                                          
                                                                          name="country"
                                                                          label="country"
                                                                          required
                                                                          options={updatedCountries}
                                                                          value={values.country}
                                                                          placeholder={t("author.profile.Country.placeholder")}
                                                                          onChange={(value) => {
                                                                            console.log(value)
                                                                            setValues({ country: value, city: null });
                                                                            setValidationCountry(true)
                                                                          
                                                                          }}
                                                                         /> 
                                                                       
                                                                       <div className="form-text text-danger">  {(!validationCountry && values.country==="") && t("author.profile.Country.placeholder")}</div>
                                                                         
                                                                         
                                                                          {/* <div className="form-text text-danger">{!validationCountry && "Select a country"}</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                    <div className="form-group">
                                                                            <label htmlFor="address" className="not_empty"><b> {t("author.profile.City")}</b></label>
                                                                      <Select
                                                                        required
                                                                          id="city"
                                                                          name="city"
                                                                          label="city"
                                                                          placeholder={t("author.profile.City.placeholder")}
                                                                          options={updatedCities(values.country ? values.country.isoCode: null)}
                                                                          value={values.city}
                                                                          onChange={(value) => {
                                                                            setValues({country:values.country,city: value });
                                                                            setValidationCity(true)
                                                                          }}
                                                                          
                                                                        /> 
                                                                         <div className="form-text text-danger">{(!validationCity && values.city==="" )&& t("author.profile.City.placeholder")}</div>
                                                                         {/* <div className="form-text text-danger">{(!validationCountry  && "Select a city"}</div> */}
                                                                        </div>
                                                                      
                                                                    </div>
                                                                   

                                                                       
                                                                                                                                    
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" >
                                                                            <label htmlFor="facebook" className="not_empty">{t("author.profile.Facebook")}</label>
                                                                            <input id="facebook" name="linkFacebook" className={`form-control ${errors.linkFacebook? 'is-invalid' : ''}`} type="url" placeholder={t("author.profile.Facebook.url")} 
                                                                              {...register("linkFacebook")} />
                                                                              <div className="form-text text-danger">{errors.linkFacebook?.message}</div>
                                                                            <p>{t("author.profile.fieldEmpty")}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" >
                                                                            <label htmlFor="twitter" className="not_empty">{t("author.profile.Twitter")} </label>
                                                                            <input id="twitter" name="linkTwitter"className={`form-control ${errors.linkTwitter? 'is-invalid' : ''}`} type="url" placeholder={t("author.profile.Twitter.url")} 
                                                                             {...register("linkTwitter")} />
                                                                             <div className="form-text text-danger">{errors.linkTwitter?.message}</div>
                                                                            <p>{t("author.profile.fieldEmpty")}</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="linkedIn" className="not_empty">{t("author.profile.LinkedIn")} </label>
                                                                            <input id="linkedIn" name="linkedIn" className={`form-control ${errors.linkLinkedln? 'is-invalid' : ''}`} type="url" placeholder={t("author.profile.LinkedIn.url")}
                                                                             {...register("linkedIn")} />
                                                                             <div className="form-text text-danger">{errors.linkedIn?.message}</div>
                                                                            <p>{t("author.profile.fieldEmpty")}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="youtube" className="not_empty">{t("author.profile.Youtube")}</label>
                                                                            <input id="youtube" name="linkYoutube" className={`form-control ${errors.linkYoutube? 'is-invalid' : ''}`} type="url" placeholder={t("author.profile.Youtube.url")}
                                                                              {...register("linkYoutube")} />
                                                                              <div className="form-text text-danger">{errors.linkYoutube?.message}</div>
                                                                            <p className>{t("author.profile.fieldEmpty")}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group" >
                                                                            <label htmlFor="bio" className="not_empty ">{t("author.profile.AboutAuthor")}</label>
                                                                            <textarea  className={`form-control ${errors.aboutAuthor? 'is-invalid' : ''}`} rows="6"  autoComplete="off" id="bio" placeholder={t("author.profile.AboutAuthor.placeholder")}
                                                                             {...register("aboutAuthor",{required:t("author.profile.AboutAuthor.message"),minLength:{value:10,message:"The about author must be at least 10 characters."},maxLength:{value:500,message:"The about author ne doit pas depasser 500 characters."}})}></textarea>
                                                                                <div className="form-text text-danger">{errors.aboutAuthor?.message}</div>
                                                                        </div>
                                                                    </div>
                                                                </div> <br/>
                                                                {/*<!--ends social info .row-->*/}
                                                                <button type="submit" className="btn btn-primary" id="update_user_profile" >{t("author.profile.button.save")}</button>
                                                            </div>
                                                        </form>
                                                        </div>
                                                    </div>
                                                </div>{/*<!-- ends: .atbd_author_module -->*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
  )
}
const mapStateToProps = state => {
    return {
        users: state.users,
        login : state.login,
        authorProfiles:state.authorProfiles
    }
}
const mapDispatchToProp = dispatch => {
    return {
        setProfil : (profilUser) => dispatch(setProfil(profilUser)),
        setDetailProfil:data=>dispatch(setDetailProfil(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(AuthorProfil);


function ModalDialog({onDelete,onSave,currentUserId}) {
   const [isShow, invokeModal] = React.useState(false)
   const [preview,setPreview]=useState(null)

    const onCrop=view=>{
       setPreview(view)
    }
   const onBeforeFileLoad=(e)=>{
   // const file = e.target.files[0];

        //setSrc(file)
    
    }
    const save=()=>{
        onSave({userId:currentUserId,profile:preview})
        invokeModal(false)
    }
    const deleteProfil=()=>{
       onDelete(currentUserId)
    }
   
   
  return (
    <>
      <label htmlFor="customFile"  onClick={()=>invokeModal(!false)} className="btn btn-sm btn-secondary">{t('author.profile.update.image')}</label>
      <label className="btn btn-sm btn-danger" onClick={deleteProfil}>{t('author.profile.delete.image')}</label>
      <Modal show={isShow}>
        <Modal.Header closeButton onClick={()=>invokeModal(false)}>
          <Modal.Title>{t('author.change.profile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <center>
         <Avatar
         width={300}
         height={300}
         onBeforeFileLoad={onBeforeFileLoad}
         onCrop={onCrop}
         onClose={()=>invokeModal(false)}
       
         
         />
         </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>invokeModal(false)} >
            {t("author.change.profile.button.close")}
          </Button>
          <Button variant="dark" onClick={save}>
           {t('author.change.profile.button.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
