import React, {Fragment,useMemo} from 'react';
import Header from '../layout/header';
import { Footer } from '../layout/footer';
import  AdvSearch  from '../content/element/advance-search';
import CardCategoryGrid4 from '../content/element/card/card-category-grid-4';
import { SectionTitle } from '../content/element/section-title';
import CardListingGrid4 from '../content/element/card/card-listing-grid-4';
import { NavLink } from 'react-router-dom';
import { ContentBlockHome } from '../content/element/content-block';
import CardCategoryGrid2 from '../content/element/card/card-category-grid-2';
import  PlaceList  from '../content/element/place-list';
import Testimonial from '../content/element/carousel/testimonial';
import ClientLogo from '../content/element/carousel/client-logo';
import { Subscribe } from '../content/element/subscribe';
import { connect } from 'react-redux';
import {useSearchParams} from 'react-router-dom'
import {getCountry} from '../../store/action/countryAction'
import { useTranslation } from 'react-i18next';
function Index(props) {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const currentParams = Object.fromEntries([...searchParams])

  const logdIn = () => {
    return props.login
  }
  const light = props.logo[0].light; 
 
  useMemo(()=>{
  if(currentParams.country)
  {
    
    props.getCountry(currentParams.country)
 
  }

 },[currentParams.country,props])
 const towns=()=>{
  return props.country.towns
 }

 
  return (
    <Fragment>
      {/* Header section start */}
      <section className="intro-wrapper bgimage overlay overlay--dark">
        <div className="bg_image_holder"><img src="./assets/img/intro.jpg" alt="" /></div>
        <div className="mainmenu-wrapper">
       
          <Header logo={light} class="menu--dark" />                    
        </div>
        {/* <!-- ends: .mainmenu-wrapper --> */}
        <AdvSearch  towns={towns()} />
      </section>
      {/* Header section end */}


      {/* Category section start */}            
      <section className="categories-cards section-padding-two">
        <div className="container">
          <SectionTitle 
            title={t('home.section.title')}
            content={t('home.section.content')}
          />
          <div className="row">
            <CardCategoryGrid4 towns={towns()}/>
          </div>
        </div>
      </section>
      {/* Category section end */}

      {/* Listing section start */}
      <section className="listing-cards section-bg section-padding">
        <div className="container">
          <SectionTitle 
             title={t('home.section.title1')}
             content={t('home.section.content1')}
          />
          <div className="row">                        
            <div className="listing-cards-wrapper col-lg-12">
              <div className="row">
                <CardListingGrid4  logdIn={logdIn()} />
                <div className="col-lg-12 text-center m-top-20">
                  <NavLink to="/all-listings-list" className="btn btn-gradient btn-gradient-two">{t('home.explore.all')}</NavLink>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </section>
      {/* Listing section end */}
      
      <ContentBlockHome />

      {/* Place section start */}
      <section className="places section-padding">
        <div className="container">
          <SectionTitle 
             title={t('home.section.title2')}
             content={t('home.section.content2')}
          />
          <div className="row">
            <div className="col-lg-12">
              <div className="cat-places-wrapper">
                <CardCategoryGrid2 towns={towns()}/>
              </div>
            </div>
            <PlaceList towns={towns()} />
          </div>
        </div>
      </section>
      {/* Place section end */}

      {/* Testimonial section start */}
      <section className="testimonial-wrapper section-padding--bottom">
        <div className="container">
          <SectionTitle 
              title={t('home.section.title3')}
              content={t('home.section.content3')}
          />
          <div className="row">
            <Testimonial />
          </div>
        </div>
      </section>
      {/* Testimonial section end */}

      {/* Client section start */}
      <section className="clients-logo-wrapper border-top p-top-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ClientLogo />
            </div>
          </div>
        </div>
      </section>
      {/* client section end */}
      
      <Subscribe />
      <Footer />
    </Fragment>
  );
}
const mapStateToProps = state => {
  return {
    list: state.list,
    login : state.login,
    logo: state.logo,
    country:state.country,
    NameCountry:state.country.name
  }
}
const mapDispatchToProp = dispatch => {
  return {
   
    getCountry:name=>dispatch(getCountry(name)),
    
  }
}
export default connect(mapStateToProps, mapDispatchToProp)(Index);
