import React,{Fragment}from 'react'
import { Footer } from '../layout/footer';
import { BreadcrumbWraper } from '../content/element/breadcrumb';
import Header from '../layout/header';
import { connect } from 'react-redux';

 function PrivacyPolicy(props) {
        const light = props.logo[0].light;
        return (
            <Fragment>
                {/* Header section start */}
                <section className="header-breadcrumb bgimage overlay overlay--dark">
                    <div className="bg_image_holder"><img src="./assets/img/breadcrumb1.jpg" alt="" /></div>
                    <div className="mainmenu-wrapper">
                        <Header logo={light} class="menu--dark" />                    
                    </div>
                    {/* <!-- ends: .mainmenu-wrapper --> */}
                    <BreadcrumbWraper title="Privacy Policy" />
                </section>
                {/* Header section end */}
    
                <section className="all-listing-wrapper section-bg">
                    <div className="container">
                        <div className='row '>
                            <div className="col-lg-12 order-lg-1 order-0">
                               texte a saisir
                            </div>
                        </div>
                    </div>
                </section>  
               <Footer />
            </Fragment>
        )
    }
    
    const mapStateToProps = state => {
        return {        
            logo: state.logo
        }
    }
    
    export default connect(mapStateToProps)(PrivacyPolicy);