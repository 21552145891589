
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Services from '../../../store/service/services';

const ListingFetures=({data})=> {
    const {t}=useTranslation()
    const featured= data.filter[0]
    const {features}=data.props
    console.log(features,featured)

    //******************************************************************************************************************** */

    const translateFeatures = (data) => {
        console.log(data)
        switch (data.toLowerCase()) {
            case 'accepts bank cards':
                return t('Listing.details.features.bankCard')
                
            case 'bicycle parking':
                return t('Listing.details.features.bicycleparking')

            case 'wheel chair':
                return t('Listing.details.features.wheelchair')
            
            case 'internet':
                return t('Listing.details.features.internet')

            case 'car parking':
                return t('Listing.details.features.carparking')

            case 'camera security':
                return t('Listing.details.features.camerasecurity')
            
            case 'covered by bus':
                return t('Listing.details.features.coveredbybus')
            
            case 'shopping':
                return t('Listing.details.features.shopping')
            case 'travel booking':
                return t('Listing.details.features.travelbooking')
            default:
                break;
        }
    }
  
        return (
            <Fragment>
                <div className="atbd_content_module atbd_listing_features">
                    <div className="atbd_content_module__tittle_area">
                        <div className="atbd_area_title">
                            <h4><span className="la la-list-alt"></span>{t('Listing.details.features')}</h4>
                        </div>
                    </div>
                    <div className="atbdb_content_module_contents">
                        <ul className="atbd_custom_fields features-table">
                            {/* <!--  get data from custom field--> */}
                            
                           { 
                           featured.features[0].feature !=='' && featured.features[0].definition !==''  ?
                           Object.values(featured.features).map((item,i) => {

                              return (
                                    <li key={i}>
                                        <div className="atbd_custom_field_title">
                                            <p><i className={item.feature !=='' ? Services.filterData(features,'icon',item.feature): null}></i> {item.feature !=='' ? translateFeatures(Services.filterData(features,'label',item.feature)) : null} </p>
                                        </div>
                                        <div className="atbd_custom_field_content">
                                            <p>{item.definition}</p>
                                        </div>
                                    </li>
                                    )
                        })
                          :(
                            
                                <div className="container">
                                    <div className="alert alert-danger" role="alert">
                                    {t('Listing.details.messagefeature')}
                                    </div>                                
                                </div>                        
                            
                        )
                        }
                            
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    
}

export default ListingFetures