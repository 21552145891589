

export default  function filterRating(rating) {
   
    rating=Number(rating);
    const ratingClassName=[]
    const a=5-rating
   for(let i=0;i<rating;i++){
        
        ratingClassName.push('rate_active')
   }
   
   if(a>0)
   {
    for(let i=0;i<a;i++){
        
      ratingClassName.push('rate_disable') 
    }
     
   }
  

   return ratingClassName
  
}
