/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useState,useRef,useEffect} from 'react';
import Header from '../layout/header';
import { Footer } from '../layout/footer';
import ModalConfirmation from "../content/element/modal/modalConfirmation";
import { BreadcrumbWraper } from '../content/element/breadcrumb';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
//import Map1 from '../content/element/map';
import { useTranslation } from 'react-i18next';
import frJson from "../page/add-listing/validation-fr.json";
import CreatableSelect from "react-select/creatable";
import Services from '../../store/service/services';
import  {GET_STATES_BY_ID} from "../../store/reducers/statesReducer";
import { GET_CITIES_BY_ID} from "../../store/reducers/citiesReducer";
import { EDIT_LIST } from "../../store/action/listingAction"
import { useParams } from 'react-router';
import $ from "jquery";

const EditListing=(props)=> {
        const light = props.logo[0].light;
        const {t}=useTranslation()
        const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
        const [refresh,setRefresh]=useState(false)
        const [idLocation, setIdLocation] = useState('');
        const [Locations, setLocations] = useState([]);
        const [cities, setCities] = useState([]);
        //const [dataCoordonates,setDataCoordonates]=useState(null)
        const [id, setId] = useState(null);
        const params=useParams()
        console.log(params)
        const loginIn=props.login
        const [tagValue,setTagValue]=useState({inputValue:'',value:[]})
        const [editForm,setEditForm]=useState({
            title:{
                name:'title',
                value:'',
                classes:'form-group',
                rules:'required|min:4'
            },
            description:{
                name:'description',
                value:'',
                classes:'form-group',
                rules:'required|min:20|max:400'
            },
            category:{
                name:'category',
                value:'',
                classes:'form-group',
                rules:'required'
            },
            tagline:{
                name:'tagline',
                inputValue:'',
                value:[],
                classes:'form-group',
                rules:'string|min:2|max:10'
            },
            pricing:{
                name:'pricing',
                value:'',
                classes:'form-group',
                rules:'required'
            },
            checkPriceUno:{
                name:'checkPriceUno',
                value:true,
                classes:'form-group',
                rules:'boolean'
            },
            checkPriceDue:{
                name:'checkPriceDue',
                value:false,
                classes:'form-group',
                rules:'boolean'
            },
            shortdescription:{
                name:'shortdescription',
                value:'',
                classes:'form-group',
                rules:'required|min:15|max:150'
            },
            cdate:{
                name:'cdate',
                value:'',
                classes:'form-group',
                rules:'required'
            },
            country:{
                name:'country',
                value:'',
                classes:'form-group',
                rules:'required'
            },
            state:{
                name:'state',
                value:'',
                classes:'form-group',
                rules:'required'
            },
            city:{
                name:'city',
                value:'',
                classes:'form-group',
                rules:'required'
            },
            tags:{
                name:'tags',
                value:[],
                classes:'form-group',
                rules:'string|min:2|max:10'
            },
            hideInfo:{
                name:'hideInfo',
                value:false,
                classes:'form-group',
                rules:'boolean'
            },
            adress:{
                name:'adress',
                value:'',
                classes:'form-group',
                rules:'required'
            },
            dialcode:{
                name:'dialcode',
                value:'',
                classes:'form-group',
                rules:'required'
            },
            phoneNumber:{
                name:'phoneNumber',
                value:'',
                classes:'form-group',
                rules:'required|phone|max:12'
            },
            email:{
                name:'email',
                value:'',
                classes:'form-group',
                rules:'required|email'
            },
            website:{
                name:'website',
                value:'',
                classes:'form-group',
                rules:'url'
            },
            social_media:{
                name:'social_media',
                value:[{label:'',url:''}],
                classes:'form-group',
                rules:{rules1:'string',rules2:'url'}
            },
            v4v47j7j:{
                name:'v4v47j7j',
                value:false,
                rules:'boolean'

            },
            openHours:{
                name:'openHours',
                value:[
                    {
                        day:'saturday',
                        isclose:false,
                        openinghour:'',
                        closinghour:''
                    },
                    {
                        day:'sunday',
                        isclose:false,
                        openinghour:'',
                        closinghour:''
                    },
                    {
                        day:'monday',
                        isclose:false,
                        openinghour:'',
                        closinghour:''
                    },
                    {
                        day:'tuesday',
                        isclose:false,
                        openinghour:'',
                        closinghour:''
                    },
                    {
                        day:'wednesday',
                        isclose:false,
                        openinghour:'',
                        closinghour:''
                    },
                    {
                        day:'thursday',
                        isclose:false,
                        openinghour:'',
                        closinghour:''
                    },
                    {
                        day:'friday',
                        isclose:false,
                        openinghour:'',
                        closinghour:''
                    }
                ],
                classes:'col-sm-6',
                rules:{rules1:'string',rules2:'boolean'}
            },
            faqs:{
                name:'faqs',
                value:[{question:'',answer:''}],
                classes:'form-group',
                rules:{rules1:'string',rules2:'string'}
            },
            iscoordo:{
                name:'iscoordo',
                value:false,
                classes:'form-group',
                rules:'boolean'
            },
            longitude:{
                name:'longitude',
                value:'',
                classes:'form-group',
                rules:'numeric'
            },
            latitude:{
                name:'latitude',
                value:'',
                classes:'form-group',
                rules:'numeric'
            },
            hideMap:{
                name:'hideMap',
                value:false,
                classes:'form-group',
                rules:'boolean'
            },
            img:{
                name:'img',
                value:[],
                imgFile:[],
                classes:'form-group',
                rules:'required'
            },
            features:{
                name:'features',
                value:[{feature:'',definition:''}],
                classes:'form-group',
                rules:{rules1:'string',rules2:'string'}
            },
            sliderImg:{
                name:'sliderImg',
                value:[],
                imgPreview:[],
                classes:'form-group',
                rules:'string'

            },
            videoUrl:{
                name:'videoUrl',
                value:'',
                classes:'form-group',
                rules:'url'
            },
            agree_tc:{
                name:'agree_tc',
                value:true,
                classes:'form-group',
                rules:'accepted|boolean'
            },
            authorId:{
                value:loginIn!== null ? loginIn.email : null
              } 
            
        })


   // defining props*********************************************************************

  const categories=props.category
  const countries=props.country  
  const socialmedias=props.socialmedia
  const features=props.features
  const priceRange=props.priceRange
  const list=props.list.Listing
  


    SimpleReactValidator.addLocale('fr', {frJson});
    const validator = useRef(new SimpleReactValidator({locale: 'fr'}));

    useEffect(() => {
        document.title = 'Edit Place - HOGO Places';
      }, [refresh]);

//******************filling fields with data from database******************************************************************************************** */
    useEffect(()=>{
      
        if (params.id) {
            const currentList=Services.filterData(list,null,params.id)
            console.log(currentList)
            const updatedEditForm={...editForm}
            updatedEditForm['title'].value=currentList.title
            updatedEditForm['description'].value=currentList.description
            console.log(countries,'categories=>',categories)
            updatedEditForm['category'].value=currentList.category
            updatedEditForm['tagline'].value=currentList.tagline
            updatedEditForm['pricing'].value=currentList.pricing
            setTagValue(
                {inputValue:'',
                value:currentList.tagline}
            )
            setId(currentList.country)
            updatedEditForm['country'].value=currentList.country
            setIdLocation(currentList.state)
            updatedEditForm['state'].value=currentList.state
            console.log('currentStates.id=>',currentList.state)
            updatedEditForm['city'].value=currentList.city
            updatedEditForm['cdate'].value=currentList.cdate
            updatedEditForm['hideInfo'].value=currentList.hideInfo
            updatedEditForm['adress'].value=currentList.adress
            updatedEditForm['phoneNumber'].value=currentList.phoneNumber
            updatedEditForm['email'].value=currentList.email
            updatedEditForm['website'].value=currentList.website
            updatedEditForm['shortdescription'].value=currentList.shortdescription
            updatedEditForm['social_media'].value=currentList.social_media
            updatedEditForm['openHours'].value=currentList.openHours
            updatedEditForm['img'].value=currentList.img
            updatedEditForm['faqs'].value=currentList.faqs
            updatedEditForm['features'].value=currentList.features
            updatedEditForm['sliderImg'].value=currentList.sliderImg
            updatedEditForm['videoUrl'].value=currentList.videoUrl
            updatedEditForm['agree_tc'].value=currentList.agree_tc
            
            

            setEditForm({...updatedEditForm})
            
        }

    },[params.id])


//*******************lat and lng to send to component Map1********************************************** */
// const generateLocation=(event,lat,long)=>{
//     event.preventDefault()
//     const latitude=lat
//     const longitude=long
//     const data={latitude,longitude}
//     setDataCoordonates(data)
// }
//************************************************************************** */

const nameofField = (thename,indice) => {
    return thename+indice
}


    //*************************************************************************************************** */


    
      const onBlur = (event,index) => {
        const target = event.target;
        const inputIdentifier = target.name;
    
        refreshFormFeedBack(inputIdentifier,index);
      }

      
// getting and setting values from form to the addform's state*****************************************

const onChange = (event) => {
  
    const target = event.target;
    const inputIdentifier = target.name;

    const updatedEditForm = {...editForm};
    const value = target.type==='checkbox' ? target.checked:target.value;
    console.log("value =>", value);
    updatedEditForm[inputIdentifier].value = value;
   
    if(inputIdentifier==='country'){
      setId(value)   
    }
    if(inputIdentifier==='state'){
      setIdLocation(value)
    }
    if (inputIdentifier==='checkPriceDue') {
        updatedEditForm['checkPriceUno'].value=false
        updatedEditForm['pricing'].value='' 
        
    }
    if (inputIdentifier==='checkPriceUno') {
       updatedEditForm['checkPriceDue'].value=false
       updatedEditForm['pricing'].value='' 
       
    }

    if (inputIdentifier==='v4v47j7j' && value) {
       for (let index = 0; index < editForm['openHours'].value.length; index++) {
        editForm['openHours'].value[index].openinghour=''
        editForm['openHours'].value[index].closinghour=''
        editForm['openHours'].value[index].isclose=false
       }
        
     }
   
      setEditForm({...updatedEditForm});

    refreshFormFeedBack(inputIdentifier);
  }

   // for managing render of select input of locations and city on te form depending of id's country and id location

useEffect(()=>{
    if(id){
        const data={id,idLocation}
        props.statesAction(id)
        const states=props.states.dataStates
        setLocations(states)
        if (idLocation){
          props.getCitiesAction(data)
          const currentCities=props.cities.dataCities
          setCities(currentCities)
        }
        const codes=Services.filterData(countries,'dial_code',id)
        const updatedForm=Services.assignDialcode(editForm,codes,'dialcode',null)
        setEditForm({...updatedForm})
      }
  },[id,idLocation,Locations])

const formFeedBack=(event,index)=>{
    const target = event.target;
    const inputIdentifier = target.name;
    const updatedForm={...editForm}
    const openinghour='openinghour'
    const closinghour='closinghour'
    const isclose='isclose'
    let key=null
    if (inputIdentifier.includes(openinghour)) {
        key=openinghour
    }
    if (inputIdentifier.includes(closinghour)) {
        key=closinghour
    }
    if (inputIdentifier.includes(isclose)) {
        key=isclose
    }
    const keyIdentifier='openHours'
    const value=updatedForm[keyIdentifier].value[index][key]
    const rules=inputIdentifier.includes(isclose) ? updatedForm[keyIdentifier].rules.rules2 : updatedForm[keyIdentifier].rules.rules1
    const valid = validator.current.check(value, rules);
    if(valid) {
      updatedForm[keyIdentifier].classes = 'col-sm-6';
      validator.current.hideMessageFor(inputIdentifier);
    } else {
      updatedForm[keyIdentifier].classes = 'col-sm-6 invalid';
      validator.current.showMessageFor(inputIdentifier);
    }
    setEditForm({...updatedForm});
   
}

const refreshFormFeedBack = (inputIdentifier,index) => {
        const updatedEditForm = {...editForm};
        let rules=null,value=null,key=null,keyRules=null
        console.log(inputIdentifier)
        if (inputIdentifier.includes('label')) {
            key='label'
            inputIdentifier='social_media'
            keyRules='rules1'
        }
        if (inputIdentifier.includes('url')) {
            key='url'
            inputIdentifier='social_media'
            keyRules='rules2'
        }
        if (inputIdentifier.includes('question')) {
            key='question'
            inputIdentifier='faqs'
            keyRules='rules1'
        }
        if (inputIdentifier.includes('answer')) {
            key='answer'
            inputIdentifier='faqs'
            keyRules='rules2'
        }
        if (inputIdentifier.includes('feature')) {
            key='feature'
            inputIdentifier='features'
            keyRules='rules1'
        }
        if (inputIdentifier.includes('definition')) {
            key='definition'
            inputIdentifier='features'
            keyRules='rules2'
        }
        if (inputIdentifier==='faqs' || inputIdentifier==='social_media' || inputIdentifier==='features') {
            value=updatedEditForm[inputIdentifier].value[index][key]
            rules=updatedEditForm[inputIdentifier].rules[keyRules]     
        } else {
            value = updatedEditForm[inputIdentifier].value;
            rules = updatedEditForm[inputIdentifier].rules;
        }
        console.log(value,'==>',rules)
          const valid = validator.current.check(value, rules);
        if(valid) {
          updatedEditForm[inputIdentifier].classes = 'form-group';
          validator.current.hideMessageFor(editForm[inputIdentifier].name);
        } else {
          updatedEditForm[inputIdentifier].classes = 'form-group invalid';
          validator.current.showMessageFor(editForm[inputIdentifier].name);
        }
        setEditForm({...updatedEditForm});
      }



    //   submit button function of the form*************************************************

const onSubmit = (event) => {
    event.preventDefault();
    
    const data=Services.transformData(editForm)
    const dataValue={"id":params.id,"badge":"new","rating":"1.0",...data}
    props.EditListAction(dataValue)
   // console.log(dataValue)
    // refreshFields()
    // navigate("/dashboard-listings",{replace:true})
}

const checkSubmit=(event)=>{
    event.preventDefault()
    if(validator.current.allValid()) {
  
       $('#modal-item-info').show()
      
    } else {
          validator.current.showMessages();
          setRefresh(true);
 }
}
  

// setting values of socialmedia fields to the addform state**************************************
  const onOtherChange=(event,index,keydata)=>{
    const target=event.target
    let targets=target.name
    targets=targets.includes('openinghour') ? 'openinghour': targets.includes('closinghour') ? 'closinghour' : targets.includes('isclose') ? 'isclose' : targets
    const updatedEditForm={...editForm}
    if (index>=0) {
      updatedEditForm[keydata].value[index][targets]=target.type==='checkbox' ? target.checked:target.value;  
      console.log(parseFloat(target.value)) 
    }
    else{
      updatedEditForm[keydata].value[targets]=target.value    
    }
    setEditForm({...updatedEditForm})
    if (target.checked && targets.includes('isclose')) {
       updatedEditForm.openHours.value[index].openinghour=''
       updatedEditForm.openHours.value[index].closinghour=''
       setEditForm({...updatedEditForm})
       // $('#modal-item-remove').show()
    }  
  console.log(editForm['faqs'].value)
  }
//******************function to verify if enter's data on openHours's fields are include in 24 hours (a day) ******************************************************************************************* */
//   function(()=>{
//     if (editForm['openHours'].value[0].openinghour !=="" && editForm['openHours'].value[0].closinghour !=="" ) {
//         const startime=editForm['openHours'].value[0].openinghour
//         const closetime=editForm['openHours'].value[0].closinghour
//         console.log(startime,'Close====>',closetime)
//         if (parseFloat(startime)>=parseFloat(closetime)) {
//             const updatedEditForm={...editForm}
//             updatedEditForm['openHours'].classes = 'col-sm-6 invalid';
//             validator.current.showMessageFor('closinghour0');
//             setEditForm({...updatedEditForm})
//             //$('#modal-item-info').show()         
//         }else{
//             const updatedEditForm={...editForm}
//             updatedEditForm['openHours'].classes = 'col-sm-6';
//             validator.current.hideMessageFor('closinghour0'); 
//             setEditForm({...updatedEditForm})
//         }            
//     }
//   },[editForm['openHours'].value[0].openinghour,editForm['openHours'].value[0].closinghour])




  //social media manager of fields***********************************************************
const addNewFields=(event,key)=>{
    event.preventDefault()
    const updatedEditForm={...editForm}
    const value=key==='social_media'? {label:'',url:''} : key==='faqs' ? {question:'',answer:''} :  key==='features' ? {feature:'',description:''} : null
    updatedEditForm[key].value=[...updatedEditForm[key].value,value]
    setEditForm({...updatedEditForm})
  }

  //deleting ligns's fields of social media****************************************************************

const removeFields=(keytitle,keyvalue,index)=>{
    const updatedEditForm={...editForm}
    updatedEditForm[keytitle][keyvalue].splice(index,1)
    if (keytitle==='img') {
      updatedEditForm[keytitle].value.splice(index,1)
    }
    setEditForm({...updatedEditForm}) 
  }


  const handleChange=(value)=>{
    console.group('value changed')
    console.log(value)
    console.groupEnd()
    const updatedTagValue={...tagValue}
    updatedTagValue.value=value
    setTagValue({...updatedTagValue})
  }
    
  
  const handleKeyDown=(event)=>{
    console.log(tagValue)
    if (!tagValue.inputValue) {
      return;
    }
    switch (event.key) {
      case 'Enter':
        case ' ':
          case 'Tab':
              console.log(tagValue)
              setTagValue({
                inputValue:'',
                value:[...tagValue.value,createOption(tagValue.inputValue)],
              })
              event.preventDefault()
          break
      default:
          break       
    }
  
  }
  
  useEffect(()=>{
    setEditForm({
      ...editForm,
      tagline:{
        ...editForm.tagline,
        inputValue:'',
        value:[...tagValue.value],
      }
    })
  },[tagValue.value])
  
  
  const handleInputChange=(inputValue)=>{
    const updatedTagValue={...tagValue}
    updatedTagValue.inputValue=inputValue
    setTagValue({...updatedTagValue})
  }
  
  
  const createOption=label=>({
    label,
    value:label
  
  })
      
//************************************useEffect to manage rules of socialmedia************************************************* */

useEffect(()=>{
    if (editForm['social_media'].value.length>1) {
        const updatedEditForm={...editForm}
        updatedEditForm['social_media'].rules.rules1='required'
        updatedEditForm['social_media'].rules.rules2='required|url'
        setEditForm({...updatedEditForm})  
          
    }else{
        const updatedEditForm={...editForm}
        updatedEditForm['social_media'].rules.rules1='string'
        updatedEditForm['social_media'].rules.rules2='url'
        setEditForm({...updatedEditForm}) 
    }
    console.log(editForm['social_media'].rules)
    console.log(editForm['social_media'].value.length)
},[editForm['social_media'].value.length])


useEffect(()=>{
    if (editForm['faqs'].value.length>1) {
        const updatedEditForm={...editForm}
        updatedEditForm['faqs'].rules.rules1='required|string'
        updatedEditForm['faqs'].rules.rules2='required|string'
        setEditForm({...updatedEditForm})  
          
    }else{
        const updatedEditForm={...editForm}
        updatedEditForm['faqs'].rules.rules1='string'
        updatedEditForm['faqs'].rules.rules2='string'
        setEditForm({...updatedEditForm}) 
    }
    console.log(editForm['faqs'].rules)
    console.log(editForm['faqs'].value.length)
},[editForm['faqs'].value.length])


//*******************use effect to manage pricing checkbox ans fields */


  //**************************************************************************** */

  const onFileChange=(event,key,keyvalue)=>{
    event.preventDefault()
    const {files}=event.target
    console.log(files)
    const validImagesFiles=[]
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file.type.match(imageTypeRegex)){
        validImagesFiles.push(file)
      }
      console.log(files[index])   
    }
    console.log(validImagesFiles)
    if(validImagesFiles.length){
      const updatedForm={...editForm}
      updatedForm[key][keyvalue]=[...validImagesFiles]
      setEditForm({...updatedForm})
    }
    console.log(editForm.img.value)
  }

  //******************************Launch input file for img*******************************************************************

  const triggerInputFile=(event,datafileSelect,datafileElem)=>{
      event.preventDefault()
    const fileSelect=document.getElementById(datafileSelect)
    const fileElem=document.getElementById(datafileElem)
    fileSelect.addEventListener("click",(event)=>{
        event.preventDefault()
        if (fileElem) {
         
            fileElem.click()
           
        }
    },false)
  }

  //********************************************************************************************************* */

  //use effect for previewing image***********************************************************
useEffect(()=>{

    const imgs=[],fileReaders=[],updatedForm={...editForm}
    let isCancel=false
    if (updatedForm['img'].imgFile.length) {
      updatedForm['img'].imgFile.forEach((file)=>{
    
        const fileReader=new FileReader()
        fileReaders.push(fileReader)
        fileReader.onload=(event)=>{
          const {result}=event.target
          if (result) {
            console.log(result)
            imgs.push(result)
        }
        if(imgs.length===updatedForm['img'].imgFile.length && !isCancel){
          updatedForm['img'].value=[...imgs]
          setEditForm({...updatedForm})
          console.log(editForm)
        }
      }
        fileReader.readAsDataURL(file)
      })    
    }
    console.log(editForm['img'].value)
    
    return () =>{
      isCancel=true
      fileReaders.forEach(fileReader=>{
        if (fileReader.readyState===1) {
          fileReader.abort()    
        }
      })
    }
    },[editForm.img.imgFile])

  //use effect for previewing image for SLIDER IMG***********************************************************
  useEffect(()=>{

    const imgs=[],fileReaders=[],updatedForm={...editForm}
    let isCancel=false
    if (updatedForm['sliderImg'].imgPreview.length) {
      updatedForm['sliderImg'].imgPreview.forEach((file)=>{
    
        const fileReader=new FileReader()
        fileReaders.push(fileReader)
        fileReader.onload=(event)=>{
          const {result}=event.target
          if (result) {
            console.log(result)
            imgs.push(result)
        }
        if(imgs.length===updatedForm['sliderImg'].imgPreview.length && !isCancel){
          updatedForm['sliderImg'].value=[...imgs]
          setEditForm({...updatedForm})
          console.log(editForm)
        }
      }
        fileReader.readAsDataURL(file)
      })    
    }
    
    return () =>{
      isCancel=true
      fileReaders.forEach(fileReader=>{
        if (fileReader.readyState===1) {
          fileReader.abort()    
        }
      })
    }
    },[editForm.sliderImg.imgPreview])

const getValueTranslation = (data) =>{
    switch (data) {
        case "saturday":
            return t('Edit.listing.saturday');
        case "sunday":
            return t('Edit.listing.sunday');
        case "monday":
            return t('Edit.listing.monday');
        case "tuesday":
            return t('Edit.listing.tuesday');
        case "wednesday":
            return t('Edit.listing.wednesday');
        case "thursday":
            return t('Edit.listing.thursday');
        case "friday":
            return t('Edit.listing.friday');
    
        default:
            break;
    }

}
    

const data={onSubmit}


        return (
            <Fragment>
                {/* Header section start */}
                <section className="header-breadcrumb bgimage overlay overlay--dark">
                    <div className="bg_image_holder"><img src="./assets/img/breadcrumb1.jpg" alt="" /></div>
                    <div className="mainmenu-wrapper">
                        <Header logo={light} class="menu--dark" />                    
                    </div>
                    {/* <!-- ends: .mainmenu-wrapper --> */}
                    <BreadcrumbWraper title={t("Edit.listing.title")} />
                </section>
                {/* Header section end */}
                <form onSubmit={checkSubmit}>
                    <section className="add-listing-wrapper border-bottom section-bg section-padding-strict">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="atbd_content_module">
                                        <div className="atbd_content_module__tittle_area">
                                            <div className="atbd_area_title">
                                                <h4><span className="la la-user"></span>{t('Edit.listing.generalInfo')}</h4>
                                            </div>
                                        </div>
                                        <div className="atbdb_content_module_contents">
                                           
                                                <div className={editForm['title'].classes}>
                                                    <label htmlFor="title" className="form-label">{t('Edit.listing.subtitle')}</label>
                                                    <input type="text"
                                                    name={editForm['title'].name}
                                                    value={editForm['title'].value}
                                                    onBlur={onBlur.bind(this)}
                                                    onChange={onChange.bind(this)}
                                                    className="form-control" id="title" placeholder={t("Enter Title")} />
                                                    {validator.current.message(editForm['title'].name,editForm['title'].value,editForm['title'].rules)}
                                                </div>
                                                <div className={editForm['description'].classes}>
                                                    <label htmlFor="desc" className="form-label">{t('Edit.listing.longdescription')}</label>
                                                    <textarea id="desc" rows="8" 
                                                    name={editForm['description'].name}
                                                    value={editForm['description'].value}
                                                    onBlur={onBlur.bind(this)}
                                                    onChange={onChange.bind(this)}
                                                    className="form-control" placeholder={t('Edit.listing.plcholder.description')}></textarea>
                                                    {validator.current.message(editForm['description'].name,editForm['description'].value,editForm['description'].rules)}
                                                </div>
                           {/* ******************************************************************************************                      */}
                                                <div className={editForm['category'].classes}>
                                                    <label htmlFor="categroy00" className="form-label">{t('Edit.listing.selectcategory')}</label>
                                                    <div className="select-basic">
                                                        <select className="form-control"
                                                         name={editForm['category'].name}
                                                         value={editForm['category'].value}
                                                         onBlur={onBlur.bind(this)}
                                                         onChange={onChange.bind(this)}
                                                        id="categroy000">
                                                            <option>{t('Select category')}</option>
                                                            { categories ?
                                                                Object.values(categories).map((cat,index)=>{
                                                                    console.log(cat)
                                                                  return  (<option key={index} value={cat.id} >{cat.label}</option>)

                                                                }) : null
                                                            }
                                                        </select>
                                                        {validator.current.message(editForm['category'].name,editForm['category'].value,editForm['category'].rules)}
                                                    </div>
                                                </div>
                                                <div className={editForm['tagline'].classes}>
                                                    <label htmlFor="tagline" className="form-label">{t('Edit.listing.tagline')}</label>
                                                    <CreatableSelect
                                                    isMulti
                                                    isClearable
                                                    components={
                                                        {DropdownIndicator:null}
                                                      }
                                                      inputValue={tagValue.inputValue}
                                                      menuIsOpen={false}
                                                      onChange={handleChange}
                                                      placeholder='type something and press Enter..'
                                                      onKeyDown={handleKeyDown}
                                                      onInputChange={handleInputChange}
                                                      value={tagValue.value}
                                                    />
                                                    {/* <input type="text" 
                                                     name={editForm['tagline'].name}
                                                     value={editForm['tagline'].value}
                                                     onBlur={onBlur.bind(this)}
                                                     onChange={onChange.bind(this)}
                                                    className="form-control" id="tagline" placeholder={t("Your Listing Motto or Tagline")} required /> */}
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{t('Edit.listing.pricing')}</label>
                                                    <div className="pricing-options">
                                                        <div className=" custom-checkbox checkbox-outline checkbox-outline-primary">
                                                            <input type="checkbox" className="custom-control-input" id="price_one"
                                                            name={editForm['checkPriceUno'].name} 
                                                            onChange={onChange.bind(this)}
                                                             value={editForm['checkPriceUno'].value} checked={editForm['checkPriceUno'].value} />
                                                            <label className="custom-control-label" htmlFor="price_one">{t('Edit.listing.pricing')}</label>
                                                            {validator.current.message(editForm['checkPriceUno'].name,editForm['checkPriceUno'].value,editForm['checkPriceUno'].rules)}
                                                        </div>
                                                        <span>{t('Edit.listing.or')}</span>
                                                        <div className="custom-checkbox checkbox-outline checkbox-outline-primary">
                                                            <input type="checkbox" className="custom-control-input"
                                                            name={editForm['checkPriceDue'].name} 
                                                            onChange={onChange.bind(this)}
                                                            value={editForm['checkPriceDue'].value}  id="price_two" checked={editForm['checkPriceDue'].value} />
                                                            <label className="custom-control-label" htmlFor="price_two">{t('Edit.listing.PriceRange')}</label>
                                                            {validator.current.message(editForm['checkPriceDue'].name,editForm['checkPriceDue'].value,editForm['checkPriceDue'].rules)}
                                                        </div>
                                                    </div>
                                                    <div className="pricing-option-inputs">
                                                        {editForm['checkPriceUno'].value && (<input type="text" id="price-input000"
                                                        name={editForm['pricing'].name}
                                                        onChange={onChange.bind(this)}
                                                        value={editForm['pricing'].value}
                                                        className="form-control" placeholder={t("Price of this listing Eg. 100")} />)}
                                                        {editForm['checkPriceDue'].value && 
                                                        (<div className="select-basic" id="price-input-range000">
                                                            <select 
                                                            name={editForm['pricing'].name}
                                                            value={editForm['pricing'].value}
                                                            onChange={onChange.bind(this)}
                                                            className="form-control">
                                                                <option>{t('Select Price Range')}</option>
                                                                {
                                                                    Object.values(priceRange).map((item,i)=>{
                                                                        return <option key={i} value={item.id}>{t(item.label)}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>)}
                                                    </div>
                                                </div>
                                                {/* <div className={editForm['shortdescription'].classes}>
                                                    <label htmlFor="short_desc" className="form-label">{t('Edit.listing.ShortDescription')}</label>
                                                    <textarea id="short_desc"
                                                    name={editForm['shortdescription'].name}
                                                    value={editForm['shortdescription'].value}
                                                    onBlur={onBlur.bind(this)}
                                                    onChange={onChange.bind(this)}
                                                    rows="5" className="form-control" placeholder={t("Edit.shortdescription.placeholder")}></textarea>
                                                    {validator.current.message(editForm['shortdescription'].name,editForm['shortdescription'].value,editForm['shortdescription'].rules)}
                                                </div> */}
{/* **********select option redudent****************************************                                           */}
                                                {/* <div className="form-group">
                                                    <label htmlFor="custom-field" className="form-label">{t('Custom Select Option')}</label>
                                                    <div className="select-basic">
                                                        <select className="form-control" id="custom-field000">
                                                            <option>{t('Male')}</option>
                                                            <option>{t('Female')}</option>
                                                            <option>{t('Other')}</option>
                                                        </select>
                                                    </div>
                                                </div> */}

                                                {/* <div className="form-group">
                                                    <label htmlFor="cf1" className="form-label">{t('Custom Field 1')}</label>
                                                    <input type="text" className="form-control" id="cf1" placeholder={t("Enter Title")} required />
                                                </div> */}
                                                <div className={editForm['cdate']}>
                                                    <label htmlFor="cdate" className="form-label">{t('Edit.listing.Date')}</label>
                                                    <input type="date"
                                                     name={editForm['cdate'].name}
                                                     value={editForm['cdate'].value}
                                                     onBlur={onBlur.bind(this)}
                                                     onChange={onChange.bind(this)}
                                                    className="form-control" id="cdate" />
                                                    {validator.current.message(editForm['cdate'].name,editForm['cdate'].value,editForm['cdate'].rules)}
                                                </div><br/>
                                                {/* <div className="form-group">
                                                    <label className="form-label">{t('Custom Radio')}</label>
                                                    <div className="atbdp-radio-list">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" id="customRadioOne" name="customRadioInline1" className="custom-control-input" />
                                                            <label className="custom-control-label" htmlFor="customRadioOne">{t('Male')}</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" id="customRadioTwo" name="customRadioInline1" className="custom-control-input" />
                                                            <label className="custom-control-label" htmlFor="customRadioTwo">{t('Female')}</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" id="customRadioThree" name="customRadioInline1" className="custom-control-input" />
                                                            <label className="custom-control-label" htmlFor="customRadioThree">{t('Other')}</label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className={editForm['country'].classes}>
                                                    <label htmlFor="country000" className="form-label">{t('Edit.listing.country')}</label>
                                                    <div className="select-basic">
                                                        <select className="form-control"
                                                         name={editForm['country'].name}
                                                         value={editForm['country'].value}
                                                         onBlur={onBlur.bind(this)}
                                                         onChange={onChange.bind(this)}
                                                        id="country00000">
                                                             <option>{t('Edit.listing.selectcountry')}</option>
                                                            {
                                                                countries && countries.length>0 ?
                                                                Object.values(countries).map((country,index)=>{
                                                                   return ( <option key={index} value={country.id} >{country.name}</option>)
                                                                }):null
                                                            }
                                                            
                                                        </select>
                                                        {validator.current.message(editForm['country'].name,editForm['country'].value,editForm['country'].rules)}
                                                    </div>
                                                </div>
                                                <div className={editForm['state'].classes}>
                                                    <label htmlFor="state" className="form-label">{t('Edit.listing.state')}</label>
                                                    <div className="select-basic">
                                                        <select className="form-control"
                                                        name={editForm['state'].name}
                                                        value={editForm['state'].value}
                                                        onBlur={onBlur.bind(this)}
                                                        onChange={onChange.bind(this)}
                                                        id="state00">
                                                             <option>{t('Edit.listing.selectstate')}</option>
                                                           {
                                                           Locations && Locations.length>0 ?
                                                           Object.values(Locations).map((locat,index)=>{
                                                               
                                                            return (<option key={index} value={locat.id}>{locat.name}</option>)

                                                           }):null
                                                           }   
                                                        </select>
                                                        {validator.current.message(editForm['state'].name,editForm['state'].value,editForm['state'].rules)}
                                                    </div>
                                                </div>
                                                <div className={editForm['city'].classes}>
                                                    <label htmlFor="city" className="form-label">{t('Edit.listing.city')}</label>
                                                    <div className="select-basic">
                                                        <select className="form-control" 
                                                        name={editForm['city'].name}
                                                        value={editForm['city'].value}
                                                        onBlur={onBlur.bind(this)}
                                                        onChange={onChange.bind(this)}
                                                        id="city">
                                                            <option>{t('Edit.listing.selectcity')}</option>
                                                             
                                                           { cities && cities.length >0 ?
                                                           Object.values(cities).map((city,index)=>{
                                                               return (<option key={index} value={city.id}>{city.name}</option>)
                                                           }):null 
                                                           }  
                                                        </select>
                                                        {validator.current.message(editForm['city'].name,editForm['city'].value,editForm['city'].rules)}
                                                    </div>
                                                </div>
  {/* //**************************************************************************************************************** */}
                                                {/* <div className="form-group">
                                                    <div className="select-basic">
                                                        <label htmlFor="tags" className="form-label">{t('Edit.listing.tags')}</label>
                                                        <select className="form-control" id="tags" multiple="multiple">
                                                            <option>orange</option>
                                                            <option>white</option>
                                                            <option>purple</option>
                                                        </select>
                                                    </div>
                                                </div> */}
 {/* *************************************************************************************************************************************/}
                                            {/*<!-- ends: .form-group -->*/}
                                        </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                                    </div>{/*<!-- ends: .atbd_content_module -->*/}
                                </div>{/*<!-- ends: .col-lg-10 -->*/}
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="atbd_content_module">
                                        <div className="atbd_content_module__tittle_area">
                                            <div className="atbd_area_title">
                                                <h4><span className="la la-user"></span>{t('Edit.listing.contactinformation')}</h4>
                                            </div>
                                        </div>
                                        <div className="atbdb_content_module_contents">
                                            
                                                <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary m-bottom-20">
                                                    <input type="checkbox" className="custom-control-input" 
                                                    defaultChecked={editForm['hideInfo'].value}
                                                    name={editForm['hideInfo'].name}
                                                    onChange={onChange.bind(this)}
                                                    id="hide_contace_info" />
                                                    <label className="custom-control-label" htmlFor="hide_contace_info">
                                                       {t('Edit.listing.check.title')}</label>
                                                </div>
                                                <div className={editForm['adress'].classes}>
                                                    <label htmlFor="address" className="form-label">{t('Edit.listing.address.label')}</label>
                                                    <input type="text" 
                                                    name={editForm['adress'].name}
                                                    value={editForm['adress'].value}
                                                    onBlur={onBlur.bind(this)}
                                                    onChange={onChange.bind(this)}
                                                    placeholder={t("Edit.listing.address.placeholder")} id="address" className="form-control" required />
                                                    {validator.current.message(editForm['adress'].name,editForm['adress'].value,editForm['adress'].rules)}
                                                </div>
                                                <div className='form-group'>
                                <label className="form-label">{t('Edit.listing.phoneNumber')}</label>
                                  <div >
                                  {/*<Formfield fields={fields} handleChange={handleChange} socialMedias={socialMedias} onBlur={onBlur} t={t}/> */}
                                   
                                      <div className="directorist ">
                                          <div className="row m-bottom-20">
                                              <div className="col-sm-3">

                                                  <div className={editForm['dialcode'].classes}>
                                                      <div className="select-basic">
                                                          <input type='text' className="form-control"
                                                          name={editForm['dialcode'].name}
                                                          disabled
                                                          onBlur={event=>onBlur(event,null)}
                                                          onChange={onChange.bind(this)} 
                                                          value={editForm['dialcode'].value}/>
                                                        {validator.current.message(editForm['dialcode'].name,editForm['dialcode'].value,editForm['dialcode'].rules)}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-sm-9">
                                                  <div className={editForm['phoneNumber'].classes}>
                                                      <input type="text" 
                                                      name={editForm['phoneNumber'].name}
                                                      value={editForm['phoneNumber'].value}
                                                      onChange={onChange.bind(this)}
                                                      onBlur={event=>onBlur(event,null)}
                                                      placeholder={("Edit.listing.phoneNumber")} className="form-control"/>
                                                      {validator.current.message(editForm['phoneNumber'].name, editForm['phoneNumber'].value, editForm['phoneNumber'].rules)}
                                                      
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                                                <div className={editForm['email'].classes}>
                                                    <label htmlFor="contact_email" className="form-label">{t('Edit.listing.email')}</label>
                                                    <input type="email" id="contact_email"
                                                    name={editForm['email'].name} value={editForm['email'].value} onChange={onChange.bind(this)}
                                                    onBlur={event=>onBlur(event,null)}  
                                                    className="form-control" placeholder={t("Edit.listing.emailplaceholder")} required />
                                                    {validator.current.message(editForm['email'].name, editForm['email'].value, editForm['email'].rules)}
                                                </div>
                                                <div className={editForm['website'].classes}>
                                                    <label htmlFor="website_address" className="form-label">{t('Edit.listing.website')}</label>
                                                    <input type="text" id="website_address" 
                                                     name={editForm['website'].name} value={editForm['website'].value} onChange={onChange.bind(this)}
                                                     onBlur={event=>onBlur(event,null)} 
                                                    className="form-control" placeholder={t("Edit.listing.websiteplaceholder")} />
                                                    {validator.current.message(editForm['website'].name, editForm['website'].value, editForm['website'].rules)}
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{t('Edit.listing.socialinformation')}</label>
                                                    <div id="social_info_sortable_container">
                                                        { 
                                                       
                                                            Object.values(editForm['social_media'].value).map((socialmedia,index)=>{
                                                return (<div className="directorist social_field_wrapper" key={index}>
                                                            <div className="row m-bottom-20" id="social-form-fields">
                                                                <div className="col-sm-4">
                                                                    <div className={editForm['social_media'].classes}>
                                                                        <div className="select-basic">
                                                                            <select className="form-control"
                                                                            value={editForm['social_media'].value[index].label}
                                                                            onBlur={event=>onBlur(event,index)}
                                                                            onChange={event=>onOtherChange(event,index,'social_media')}
                                                                            name='label'>
                                                                                <option>{t('Edit.listing.mediaplaceholder')}</option>
                                                                               {
                                                                                socialmedias && socialmedias.length>0 ?
                                                                                Object.values(socialmedias).map((item,i)=>{
                                                                                    return ( <option key={i}  value={item.id}>{item.label}</option>)
                                                                                }):null
                                                                               }

                                                                            </select>
                                                                            {validator.current.message('label',editForm['social_media'].value[index].label,editForm['social_media'].rules.rules1)} 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className={editForm['social_media'].classes}>
                                                                        <input type="text" 
                                                                        name='url'
                                                                        value={editForm['social_media'].value[index].url}
                                                                        onChange={event=>onOtherChange(event,index,'social_media')}
                                                                        onBlur={event=>onBlur(event,index)}
                                                                        className="form-control" placeholder={t("Edit.listing.socialmediaadress")} />
                                                                        {validator.current.message('url',editForm['social_media'].value[index].url,editForm['social_media'].rules.rules2)} 
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-2 d-flex align-items-center">
                                                                    <span onClick={editForm['social_media'].value.length>1 ? ()=>removeFields('social_media','value',index) : null} className="removeSocialField btn-danger"  title={t("Edit.listing.remove")}><i className="la la-times"></i></span>
                                                                    <span className="adl-move-icon btn-secondary"><i className="la la-arrows"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>)

                                                            })
                                                        }


                                                    </div>
                                                    <button onClick={validator.current.fieldValid('url') && validator.current.fieldValid('label') && editForm['social_media'].value.length < 5 ? (event)=> addNewFields(event,'social_media') : null} className="copy-btn btn btn-sm btn-secondary"><i className="la la-plus"></i> {t('Edit.listing.addnew')}</button>
                                                </div>
                                            
                                        </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                                    </div>{/*<!-- ends: .atbd_content_module -->*/}
                                </div>{/*<!-- ends: .col-lg-10 -->*/}
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="atbd_content_module">
                                        <div className="atbd_content_module__tittle_area">
                                            <div className="atbd_area_title">
                                                <h4><span className="la la-calendar-check-o"></span>{t('Edit.listing.Opening')}</h4>
                                            </div>
                                        </div>
                                        <div className="atbdb_content_module_contents">
                                            <div className="business-hour">
                                                <div className="row">
                                                    <div className="col-md-12 m-bottom-20">
                                                        <div className="enable247hour custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                            <input type="checkbox" 
                                                            value={editForm['v4v47j7j'].value}
                                                            onChange={onChange.bind(this)}
                                                            className="custom-control-input" name={editForm['v4v47j7j'].name} id="enable247hour" checked={editForm['v4v47j7j'].value} />
                                                            <label htmlFor="enable247hour" className="not_empty custom-control-label"> {t('Edit.listing.v4v4')} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        {   Object.values(editForm['openHours'].value).map((hour,index) => {

                                                        return (<div className="form-group" key={index}>
                                                            <label htmlFor={"bdbh_"+hour.day} className="atbd_day_label form-label">{getValueTranslation(hour.day)}</label>
                                                            <div className="row atbd_row_bg">
                                                                <div className={editForm['openHours'].classes}>
                                                                    <label htmlFor={"bdbh_"+hour.day.toLowerCase()} className="not_empty">{t('Edit.listing.startime')}</label>
                                                                    <input type="time" id={"bdbh_"+hour.day.toLowerCase()}
                                                                    value={editForm['openHours'].value[index].openinghour}
                                                                    name={nameofField('openinghour',index)}
                                                                    onBlur={event=>formFeedBack(event,index)}
                                                                    onChange={event=>onOtherChange(event,index,'openHours')}
                                                                    className="form-control directory_field" disabled={editForm['v4v47j7j'].value ? editForm['v4v47j7j'].value : editForm['openHours'].value[index].isclose} />
                                                                    {validator.current.message(nameofField('openinghour',index),editForm['openHours'].value[index].openinghour,editForm['openHours'].rules.rules1)} 
                                                                </div>
                                                                <div className={editForm['openHours'].classes + " mt-3 mt-sm-0"}>
                                                                    <label htmlFor={"bdbh_"+hour.day.toLowerCase()+"_cls"} className="not_empty">{t('Edit.listing.closetime')}</label>
                                                                    <input type="time" id={"bdbh_"+hour.day.toLowerCase()+"_cls"}
                                                                    name={nameofField('closinghour',index)}
                                                                    onBlur={event=>formFeedBack(event,index)}
                                                                    onChange={event=>onOtherChange(event,index,'openHours')}
                                                                    value={editForm['openHours'].value[index].closinghour}
                                                                    className="form-control directory_field" disabled={editForm['v4v47j7j'].value ? editForm['v4v47j7j'].value : editForm['openHours'].value[index].isclose} />
                                                                    {validator.current.message(nameofField('closinghour',index),editForm['openHours'].value[index].closinghour,editForm['openHours'].rules.rules1)} 
                                                                </div>
                                                            </div>
                                                            <div className="atbd_mark_as_closed custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                                <input type="checkbox" className="custom-control-input"
                                                                value={editForm['openHours'].value[index].isclose}
                                                                onBlur={event=>formFeedBack(event,index)}
                                                                onChange={event=>onOtherChange(event,index,'openHours')}
                                                                name={nameofField('isclose',index)} id={hour.day.toLowerCase()+"_cls"} checked={editForm['v4v47j7j'].value ? editForm['v4v47j7j'].value : editForm['openHours'].value[index].isclose} />
                                                                <label htmlFor={hour.day.toLowerCase()+"_cls"} className="not_empty custom-control-label"> {t('Edit.listing.markasclosed')} </label>
                                                            </div>
                                                        </div>)
                                                        })
                                                        }
                                                        {/*<!-- ends: .form-group -->*/}
                                                        {/*<!-- ends: .form-group -->*/}
                                                        {/*<!-- ends: .form-group -->*/}
                                                    </div>
                                                    {/*<!--ends col-md-6 col-sm-12-->*/}
                                                </div>
                                                {/*<!--ends .row-->*/}
                                            </div>
                                        </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                                    </div>{/*<!-- ends: .atbd_content_module -->*/}
                                </div>{/*<!-- ends: .col-lg-10 -->*/}
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="atbd_content_module">
                                        <div className="atbd_content_module__tittle_area">
                                            <div className="atbd_area_title">
                                                <h4><span className="la la-calendar-check-o"></span> {t('Edit.listing.faqs')}</h4>
                                            </div>
                                        </div>
                                        <div className="atbdb_content_module_contents">
                                            <div id="faqs_info_sortable_container" className="ui-sortable">
                                                {editForm['faqs'].value &&
                                                Object.values(editForm['faqs'].value).map((item,index)=>{

                                                return (<div className="directorist row atbdp_faqs_wrapper" key={index} id="faqsID-0">
                                                            <div className="col-md-3 col-sm-12">
                                                                <div className={editForm['faqs'].classes}>
                                                            {/*<!-- <label></label>-->*/}
                                                                    <input type="text" placeholder={t("Edit.listing.faqquestionplaceholder")}
                                                                    value={editForm['faqs'].value[index].question}
                                                                    onBlur={event=>onBlur(event,index)}
                                                                    onChange={event=>onOtherChange(event,index,'faqs')}
                                                                    name="question"
                                                                    className="form-control atbdp_faqs_quez" />
                                                                    {validator.current.message("question",editForm['faqs'].value[index].question,editForm['faqs'].rules.rules1)} 
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12">
                                                                <div className={editForm['faqs'].classes}>
                                                            {/*<!--<label></label>-->*/}
                                                                    <textarea rows="3" placeholder={t("Edit.listing.faqanswerplaceholder")}
                                                                    value={editForm['faqs'].value[index].answer}
                                                                    onChange={event=>onOtherChange(event,index,'faqs')}
                                                                    onBlur={event=>onBlur(event,index)}
                                                                    name="answer" className="form-control directory_field atbdp_faqs_input"></textarea>
                                                                    {validator.current.message("answer",editForm['faqs'].value[index].answer,editForm['faqs'].rules.rules2)} 
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-12">
                                                                <span className="removeFAQSField btn-danger" onClick={editForm['faqs'].value.length>1 ? ()=>removeFields('faqs','value',index) : null} id="removeFAQ" title={t("Edit.listing.remove")}><i className="la la-times"></i></span>
                                                                <span className="adl-move-icon btn-secondary"><i className="la la-arrows"></i></span>
                                                            </div>
                                                            <br/> <br/> <br/> <br/>
                                                        </div>
                                                        
                
                                                    )
                                                })
                                                
                                                }
                                            </div>
                                            <button type="button" className="btn btn-secondary btn-sm m-top-20" onClick={validator.current.fieldValid("question") && validator.current.fieldValid("answer") ? event=>addNewFields(event,'faqs') : null} id="addNewFAQS">
                                                <span className="plus-sign">+</span>
                                                {t('Edit.listing.addnew')}
                                            </button>
                                        </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                                    </div>{/*<!-- ends: .atbd_content_module -->*/}
                                </div>{/*<!-- ends: .col-lg-10 -->*/}

                                <div className="col-lg-10 offset-lg-1">
                                    <div className="atbd_content_module">
                                        <div className="atbd_content_module__tittle_area">
                                            <div className="atbd_area_title">
                                                <h4><span className="la la-calendar-check-o"></span> {t('Edit.listing.features')}</h4>
                                            </div>
                                        </div>
                                        <div className="atbdb_content_module_contents">
                                            <div id="faqs_info_sortable_container" className="ui-sortable">
                                                { editForm['features'].value &&
                                                Object.values(editForm['features'].value).map((item,index)=>{

                                                return (<div className="directorist row atbdp_faqs_wrapper" key={index} id="featID-0">
                                                            <div className="col-md-3 col-sm-12">
                                                                <div className={editForm['features'].classes}>
                                                            {/*<!-- <label></label>-->*/}
                                                            <div className="select-basic">
                                                                            <select className="form-control"
                                                                            value={editForm['features'].value[index].feature}
                                                                            onBlur={event=>onBlur(event,index)}
                                                                            onChange={event=>onOtherChange(event,index,'features')}
                                                                            name='feature'>
                                                                                <option>{t('Edit.listing.featuress')}</option>
                                                                               {
                                                                                features && Object.values(features).length>0 ?
                                                                                Object.values(features).map((item,i)=>{
                                                                                    return ( <option key={i}  value={item.id}>{item.label}</option>)
                                                                                }):null
                                                                               }

                                                                            </select>
                                                                            {validator.current.message('feature',editForm['features'].value[index].feature,editForm['features'].rules.rules1)} 
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12">
                                                                <div className={editForm['features'].classes}>
                                                            {/*<!--<label></label>-->*/}
                                                                    <textarea rows="3" placeholder={t('Edit.listing.featuredescription')}
                                                                    value={editForm['features'].value[index].definition}
                                                                    onChange={event=>onOtherChange(event,index,'features')}
                                                                    onBlur={event=>onBlur(event,index)}
                                                                    name="definition" className="form-control directory_field atbdp_faqs_input"></textarea>
                                                                    {validator.current.message("definition",editForm['features'].value[index].definition,editForm['features'].rules.rules2)} 
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 col-sm-12">
                                                                <span className="removeFAQSField btn-danger" onClick={editForm['features'].value.length>1 ? ()=>removeFields('features','value',index) : null} id="removeFAQ" title={t("Edit.listing.remove")}><i className="la la-times"></i></span>
                                                                <span className="adl-move-icon btn-secondary"><i className="la la-arrows"></i></span>
                                                            </div>
                                                            <br/> <br/> <br/> <br/>
                                                        </div>
                                                        
                
                                                    )
                                                })
                                                
                                                }
                                            </div>
                                            <button type="button" className="btn btn-secondary btn-sm m-top-20" onClick={validator.current.fieldValid("feature") && validator.current.fieldValid("definition") ? event=>addNewFields(event,'features') : null} id="addNewFeatures">
                                                <span className="plus-sign">+</span>
                                               {t('Edit.listing.addnew')}
                                            </button>
                                        </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                                    </div>{/*<!-- ends: .atbd_content_module -->*/}
                                </div>{/*<!-- ends: .col-lg-10 -->*/}
                                
                                {/* <div className="col-lg-10 offset-lg-1">
                                    <div className="atbd_content_module">
                                        <div className="atbd_content_module__tittle_area">
                                            <div className="atbd_area_title">
                                                <h4><span className="la la-calendar-check-o"></span> {t('Edit.listing.location.maptitle)')}</h4>
                                            </div>
                                        </div>
                                       {! editForm['hideMap'].value && ( <div className="atbdb_content_module_contents">
                                            <label className="not_empty form-label">Set the Marker by clicking anywhere on the Map</label>
                                            <div className="map" id="map-one" style={{position: 'relative'}}>
                                               { dataCoordonates ? <Map1 data={dataCoordonates} /> : <Map1/>}
                                            </div>
                                                    
                                            <div className={"cor-wrap "+ editForm['iscoordo'].classes}>
                                                <div className=" custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                    <input type="checkbox" className="custom-control-input"
                                                    onChange={onChange.bind(this)}
                                                    name={editForm['iscoordo'].name} value={editForm['iscoordo'].value} id="manual_coordinate" />
                                                    <label htmlFor="manual_coordinate" className="not_empty custom-control-label">Or Enter Coordinates (latitude and longitude) Manually. </label>
                                                </div>
                                            </div>
                                            <div className="cor-form">
                                               {editForm['iscoordo'].value && ( <div className="row m-bottom-30">
                                                    <div className="col-sm-6">
                                                        <div className={editForm['latitude'].classes}>
                                                            <label htmlFor="manual_lat" className="not_empty"> {t('Edit.listing.latitude')} </label>
                                                            <input type="text" name={editForm['latitude'].name} 
                                                            value={editForm['latitude'].value}
                                                            onBlur={onBlur.bind(this)}
                                                            onChange={onChange.bind(this)}
                                                            id="manual_lat" className="form-control directory_field" placeholder="Enter Latitude eg. 24.89904" />
                                                            {validator.current.message(editForm['latitude'].name,editForm['latitude'].value,editForm['latitude'].rules)} 
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mt-3 mt-sm-0">
                                                        <div className={editForm['longitude'].classes}>
                                                            <label htmlFor="manual_lng" className="not_empty"> {t('Edit.listing.longitude')}</label>
                                                            <input type="text" name={editForm['longitude'].name}
                                                            value={editForm['longitude'].value}
                                                            onBlur={onBlur.bind(this)}
                                                            onChange={onChange.bind(this)}
                                                            id="manual_lng" className="form-control directory_field" placeholder="Enter Longitude eg. 91.87198" />
                                                            {validator.current.message(editForm['longitude'].name,editForm['longitude'].value,editForm['longitude'].rules)}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="form-group lat_btn_wrap m-top-15">
                                                            <button className="btn btn-primary" 
                                                            onClick={validator.current.fieldValid(editForm['latitude'].name) && validator.current.fieldValid(editForm['longitude'].name) ? (event)=>generateLocation(event,editForm['latitude'].value,editForm['longitude'].value):null}
                                                            id="generate_admin_map">{t('Edit.listing.generateonmap')}
                                                            </button>
                                                        </div>
                                                    </div>  */}
                                                    {/*<!-- ends #hide_if_no_manual_cor-->*/}
                                                {/* </div>)}

                                            </div>
                                        </div>)} */}
                                        {/*<!-- ends: .atbdb_content_module_contents -->*/}
                                        {/* <div className="atbdb_content_module_contents">
                                        <div className="form-group">
                                                    <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                        <input type="checkbox" className="custom-control-input"
                                                        onChange={onChange.bind(this)}
                                                        name={editForm['hideMap'].name} value={editForm['hideMap'].value} id="hide_map" />
                                                        <label htmlFor="hide_map" className="not_empty custom-control-label">{t('Edit.listing.hidemap')}</label>
                                                    </div>
                                        </div>
                                        </div> */}
                                    {/* </div> */}
                                    {/*<!-- ends: .atbd_content_module -->*/}
                                {/* </div> */}
                                {/*<!-- ends: .col-lg-10 -->*/}


                                <div className="col-lg-10 offset-lg-1">
                                    <div className="atbd_content_module">
                                        <div className="atbd_content_module__tittle_area">
                                            <div className="atbd_area_title">
                                                <h4><span className="la la-calendar-check-o"></span> {t('Edit.listing.imagevideo')}</h4>
                                            </div>
                                        </div>
                                        <div className="atbdb_content_module_contents">
                                            <div id="_listing_gallery">
                                                <div className="add_listing_form_wrapper" id="gallery_upload">
                                                    <div className="form-group text-center">
                                                        {/*<!--  add & remove image links -->*/}
                                                        <p className="hide-if-no-js">
                                                            <input type="file" id='fileElem' className='input-file form-group btn btn-outline' onChange={event=>onFileChange(event,'img','imgFile')} accept="image/png, image/jpg, image/jpeg" style={{display:"none"}}/>
                                                            <button id="fileSelect" onClick={event=>triggerInputFile(event,"fileSelect","fileElem")} className="upload-header btn btn-outline-secondary">{t('Edit.uploadpreviewimage')}</button>
                                                        </p>
                                                    </div>
                                                    <div className="form-group text-center">
                                                        {/*<!-- image container, which can be manipulated with js -->*/}
                                                        <div className="listing-img-container">
                                                            {/* <img src={editForm['img'].value} alt="No_Image_Found" style={{width:100,height:80}}/>
                                                            
                                                            <p>{editForm['img'].imgFile.length>0 && editForm['img'].imgFile[0].name}
                                                            <span onClick={()=>removeFields('img','value',1)} className="removeSocialField btn-danger" id="removeSocial" title="Remove this item"><i className="la la-times"></i></span></p> */}
                                                            {Object.values(editForm['img'].value).length>0 ?
                                                            <table className="table table-hover">
                                                                <thead>
                                                                <tr>
                                                                    <th>{t('Edit.listing.prev.image')}</th>
                                                                    {/* <th>{t('Edit.listing.sli.name')}</th> */}
                                                                    <th>{t('Edit.listing.prev.action')}</th>
                                                                </tr>
                                                                    
                                                                </thead>
                                                                <tbody>
                                                            {
                                                                Object.values(editForm['img'].value).map((imgs,index)=>{
                                                                return (<tr key={index}>
                                                                    <td><img src={imgs} alt="No_Image_Found" style={{width:100,height:80}} /></td>
                                                                    {/* <td>{editForm['img'].imgFile.length>0 && editForm['img'].imgFile[0].name}</td> */}
                                                                    <td><span onClick={()=>removeFields('img','value',index)} className="removeSocialField btn-danger"  title={t("Edit.listing.remove")}><i className="la la-times"></i></span></td>
                                                                </tr>)
                                                                }
                                                            )
                                                            }
                                                                </tbody>
                                                                    
                                                            </table>:null
                                                                

                                                            }<br/><br/><br/>
                                                       </div>
                                                    </div>
                                                    
                                                        {/*<!--  add & remove image links -->*/}
                                                    <div className='form-group text-center'>
                                                        <p className="hide-if-no-js">
                                                            <input type="file" id='file_elem_img' className='input-file form-group btn btn-outline' onChange={event=>onFileChange(event,'sliderImg','imgPreview')} accept="image/png, image/jpg, image/jpeg" multiple style={{display:"none"}}/>
                                                            <button  id="file_listing_image_btn" onClick={event=>triggerInputFile(event,"file_listing_image_btn","file_elem_img")} className="btn btn-outline-primary m-right-10">
                                                                <span className="dashicons dashicons-format-image"></span> {t('Edit.listing.uploadsliderimages')}
                                                            </button>
                                                            {/* <a id="delete-custom-img" className="btn btn-outline-danger hidden" href="# "> Remove Images</a> */}
                                                            <br />
                                                        </p>
                                                    </div>
                                                    <div className="form-group text-center">
                                                        <div className="listing-img-container">
                                                            {editForm['sliderImg'].value.length>0 ?
                                                    
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t('Edit.listing.slider.image')}</th>
                                                                       {/* // <th>{t('Edit.listing.sli.name')}</th> */}
                                                                        <th>{t('Edit.listing.slider.action')}</th>
                                                                    </tr>
                                                                    
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    Object.values(editForm['sliderImg'].value).map((imgs,index)=>{
                                                                    return (<tr key={index}>
                                                                    <td><img src={imgs} alt="No_Image_Found" style={{width:100,height:80}} /></td>
                                                                    {/* <td>{editForm['sliderImg'].imgPreview.length>0 && editForm['sliderImg'].imgPreview[index].name}</td> */}
                                                                    <td><span onClick={()=>removeFields('sliderImg','value',index)} className="removeSocialField btn-danger" title="Remove this item"><i className="la la-times"></i></span></td>
                                                                    </tr>)
                                                                    }
                                                                )
                                                                }
                                                                </tbody>
                                                                    
                                                            </table>
                                                                    :null
                                                                }<br/>
                                                            </div>
                                                        </div>
                                                </div>
                                                {/*<!--ends add_listing_form_wrapper-->*/}
                                            </div>
                                            <div className={editForm['videoUrl'].classes +" m-top-30"}>
                                                <label htmlFor="videourl" className="not_empty form-label">{t('Edit.listing.videourl')}</label>
                                                <input type="text" id="videourl"
                                                 name={editForm['videoUrl'].name}
                                                 value={editForm['videoUrl'].value}
                                                 onBlur={onBlur.bind(this)}
                                                 onChange={onChange.bind(this)}
                                                 className="form-control directory_field" placeholder={t("Edit.listing.video")} />
                                                 {validator.current.message(editForm['videoUrl'].name,editForm['videoUrl'].value,editForm['videoUrl'].rules)} 
                                            </div>
                                        </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                                    </div>{/*<!-- ends: .atbd_content_module -->*/}
                                </div>{/*<!-- ends: .col-lg-10 -->*/}
                                <div className="col-lg-10 offset-lg-1 text-center">
                                    <div className="atbd_term_and_condition_area custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                        <input type="checkbox" className="custom-control-input"
                                        defaultChecked={editForm['agree_tc'].value}
                                        name={editForm['agree_tc'].name}
                                        onChange={onChange.bind(this)}
                                        id="listing_t" />
                                        <label htmlFor="listing_t" className="not_empty custom-control-label">{t('addlisting.iagreewithall')}<a href=" " id="listing_t_c"> {t('addlisting.termsandconditions')}</a></label>
                                        {validator.current.message(editForm['agree_tc'].name,editForm['agree_tc'].value,editForm['agree_tc'].rules)} 
                                    </div>
                                    <div className="btn_wrap list_submit m-top-25">
                                        <button type="submit" className="btn btn-primary btn-lg listing_submit_btn">{t("addlisting.submit")}</button>
                                    </div>
                                </div>{/*<!-- ends: .col-lg-10 -->*/}
                            </div>
                        </div>
                    </section>{/*<!-- ends: .add-listing-wrapper -->*/}
                </form>
                <ModalConfirmation data={data} />
               <Footer />
            </Fragment>
        )
    
}


const mapStateToProps = state => {
    console.log(state)
    return {

        list: state.list,
        login : state.login,
        logo: state.logo,
        category: state.category,
        country: state.setup.countries,
        socialmedia:state.socialmedia.socialmedialist,
        states:state.states,
        cities:state.cities,
        priceRange:state.pricingRange,
        features:state.features
    }
}


const mapDispatchToProps=dispatch=>{
    return{
      EditListAction:(data)=>{dispatch({type:EDIT_LIST,data})},  
      statesAction:(id)=>{dispatch({type:GET_STATES_BY_ID,id})},
      getCitiesAction:(data)=>{dispatch({type:GET_CITIES_BY_ID,data})},
      
    };
    
  }

export default connect(mapStateToProps,mapDispatchToProps)(EditListing);