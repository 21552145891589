import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup'
// import * as Yup from 'yup'
import { connect } from 'react-redux'
import { setProfil } from '../../../store/action/profileAction'
import { useTranslation } from 'react-i18next'
function AuthorPassword({users,currentUserId,setProfil}) {
    const {t}=useTranslation()
    const [alertSave,setAlertSave]=useState(false)
    let [err,setErr]=useState(false)
      const formOptions = { mode:"all" }
      const { register, handleSubmit,  formState } = useForm(formOptions)
      const { errors } = formState

      function onSubmit(data) {
        if(data.password!==data.confirmPwd)
        {
         setErr(!false)
        
        }else{
            setErr(false)
         const list= Object.values(users).filter(u=>{
            if(u.email===currentUserId)
            {
                u.password=data.password
            }
            return u
        })
        setProfil(list) 
        setAlertSave(!false) 
      }
    }
  return (
    <div className="tab-pane fade p-bottom-30" id="password" role="tabpanel" aria-labelledby="password-tab">
    
    <div className="container">
         <div className="row">
         <div className="col-md-6">
         { alertSave &&<div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <strong>{t('author.profile.message.success.title')} </strong> {t('author.profile.message.success.content')}
                                            <button type="button" onClick={()=>setAlertSave(false)} className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            </div>}
            <div className="form-group">
            <div className="atbd_author_module">
                <div className="atbd_content_module">
                    <div className="atbd_content_module__tittle_area">
                        <div className="atbd_area_title">
                            <h4><span className="la la-user"></span>{t('author.change.password')}</h4>
                        </div>
                    </div>
                    <div className="atbdb_content_module_contents">
                    <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="new_pass" className="not_empty">{t('author.new.password')}</label>
                <input id="new_pass"  type="password" placeholder={t('author.new.password.placeholder')}
                 {...register('password',{required:"Password is required",minLength:{value:8,message: 'Password must be at 8 char long'}})}
                 className={`form-control ${errors.password ? 'is-invalid' : ''}`}
               />
                 <div className="invalid-feedback">{errors.password?.message}</div>
                    <br/>
                <label htmlFor="confirm_pass" className="not_empty">{t('author.confirm.password')}</label>
                <input id="confirm_pass"  type="password" placeholder={t('author.confirm.password.placeholder')}
                {...register('confirmPwd',{required:"confirm your password ",minLength:{value:8}})}
                className={`form-control ${errors.confirmPwd ? 'is-invalid' : ''}`} />
                <div className="invalid-feedback">{errors.confirmPwd?.message}</div>
                <div className="form-text text-danger">{err && "Passwords does not match"}</div>
            <br/>
        
        <button type="submit" className="btn btn-primary" id="update_user_profile">{t("author.profile.button.save")}</button> <br/>
       </form> 
       </div>
        </div>
    </div>
    </div>
    </div>
    {/* <div className="col-md-6">
        Question de securité
    </div> */}
    </div>
    </div>
    </div>
  )
}
const mapStateToProps = state => {
    return {
        users: state.users,
        login : state.login,
        authorProfiles:state.authorProfiles
    }
}
const mapDispatchToProp = dispatch => {
    return {
        setProfil : (profilUser) => dispatch(setProfil(profilUser)),
     
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(AuthorPassword);
