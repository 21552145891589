import{SET_DETAIL_PROFIL} from '../reducers/author-profilReducer'


export const setDetailProfil=data=>({
    type: SET_DETAIL_PROFIL,  
        payload:data
})
        
    

    
