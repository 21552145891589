import { FETCH_REVIEW,GET_ALL_REVIEW,SET_REVIEW} from "../reducers/reviewReducer"

export const fetchReview=(id)=>({
    type:FETCH_REVIEW,
    payload:id
})

export const setReview =( data) => {  
    return (dispatch) => {  
        dispatch( {
            type: SET_REVIEW,  
            payload:data
        })
        
    }
   
}
export const getAllReview=()=>({
     type:GET_ALL_REVIEW
})