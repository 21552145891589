import initState1 from '../../softdata.json';
import { GET_ID_LIST } from "../action/listingAction";
import { ADD_NEW_LIST,EDIT_LIST,DELETE_LIST } from "../action/listingAction";
const initState ={ 
    Listing:initState1[0].listing,
    List:[]
  }

const listingReducer = (state = initState, action) => {

    switch (action.type) {
      
        case ADD_NEW_LIST:
            const ID=state.Listing[state.Listing.length-1].id
            console.log(state)
            state.Listing=[...state.Listing,{"id":String(parseInt(ID)+1),"badge":"New","rating":"1.0",...action.data}]
            return state

        case EDIT_LIST:
            const updatedList=Object.values(state.Listing).map(item=>item.id===action.data.id ? action.data :item) 
            state.Listing=[...updatedList]     
            return state

        case DELETE_LIST:
            state.Listing =Object.values(state.Listing).filter(item => item.id !== action.data.listId) 
            return state

        case GET_ID_LIST:
            state.List=Object.values(state.Listing).filter(item => item.id===action.data.id)
            return state
        default:
      return state;  
           
    }
}
export default listingReducer;