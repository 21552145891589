import initState1 from '../../softdata.json';
import { ADD_MY_FAVOURITE,DELETE_MY_FAVOURITE,GET_MY_FAVOURITE,REMOVE_MY_FAVOURITE,DEL_MY_FAVOUR } from "./../action/favouriteAction";

const initState = {DataFavourite:[...initState1[0].myFavouritesList],MyFavourite:[]}


const favouriteReducer = (state = initState, action) => {
    switch (action.type) {
        
        case ADD_MY_FAVOURITE:
            
            const ID=state.DataFavourite.length>0 ? String(parseInt(state.DataFavourite[state.DataFavourite.length-1].id)+1) : "1"
            state.DataFavourite=[...state.DataFavourite,{id:ID,...action.data}];
            return state

        case DELETE_MY_FAVOURITE:
            state.MyFavourite= Object.values(state.MyFavourite).filter(item=> item.id !==action.data.id )
            return state

        case REMOVE_MY_FAVOURITE:
                console.log(action)
                state.DataFavourite=Object.values(state.DataFavourite).filter(item=>{
                   return item.listId !==action.data.listId && item.email===action.data.email
                })    
                return state

        case GET_MY_FAVOURITE:
                state.MyFavourite=Object.values(state.DataFavourite).filter(item=>{
                return item.email===action.data.email
            })
            return state

        case DEL_MY_FAVOUR:
            state.MyFavourite=Object.values(state.MyFavourite).filter(item => item.listId !== action.data.listId && item.email===action.data.email)
            return state
        default:
            return state; 
    }
   
}
export default favouriteReducer;