import React, {  Fragment,useMemo,useState} from 'react';
import { NavLink,useNavigate,} from 'react-router-dom';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import {components} from 'react-select'
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

function AdvSearch(props){
    const { t } = useTranslation();
    const [valueSearch,setValueSearch]=useState('')
    const [valueCategory,setValueCategory]=useState('All')
    const [valuePlace,setValuePlace]=useState('All')
    const [places,setPlaces]=useState(props.towns)
    const navigate=useNavigate()
   

    useMemo(()=>{
         setPlaces(props.towns)
    },[props.towns])
  
    const handleSearch=e=>{
        e.preventDefault();
        let new_valueCategory=valueCategory.replaceAll('&','_')
        let new_valuePlace=valuePlace.replaceAll('&','_')
       

            navigate(`/all-listings-list?q=${valueSearch===''?null:valueSearch}&cat=${new_valueCategory}&pl=${new_valuePlace}`)
       

         }
 
        const generateLink=name=>{
            const mylink=`/all-listings-list?cat=${name.replaceAll('&','_')}`;
           //console.log(mylink)
            return mylink
        }
           //cat=category
        const filterCategories = (cat) => {
          
            return Object.values(categories).filter((i) =>
              i.label?.toLowerCase().includes(cat?.toLowerCase())
            );
          };
          const loadOptionsCategories = (
           cat,
            callback
          ) => {
            setTimeout(() => {
                
              callback(filterCategories(cat));
            }, 1000);
          };
         
         //console.log(valueCategory)
   const customStyles = {
    control: base => ({
      ...base,
      height: 48,
      minHeight: 48
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
      },
      
  };
const { Option } = components;
const IconOption = props => (
  <Option {...props}>
    <span
      className={props.data.icon}
      style={{ width: 36 }}
      alt={props.data.label}
    ></span>
    {props.data.label}
  </Option>
);
      const categories=props.category
     

 
    return (
            <Fragment>
                <div className="directory_content_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="search_title_area">
                                    <h2 className="title">{t('home.search.title')}</h2>
                                    <p className="sub_title">{t('home.search.subtitle')}</p>
                                </div>{/* ends: .search_title_area */}
                                <form action="/" className="search_form">
                                    <div className="atbd_seach_fields_wrapper">
                                        <div className="single_search_field search_query">
                                            <input className="form-control search_fields" type="text" name="valueSeach" value={valueSearch} onChange={(e)=>setValueSearch(e.target.value)} placeholder={t('home.input.search.title')} />
                                        </div>
                                        <div className="single_search_field search_category  ">
                                           
                                           <AsyncSelect 
                                           cacheOptions
                                           defaultOptions
                                           getOptionValue={e=>e.label}
                                           onChange={(value)=>setValueCategory(value.label)}
                                           loadOptions={loadOptionsCategories}
                                           placeholder={t('home.select.category')}
                                          styles={customStyles}
                                           name="valueCategory"
                                           components={{ Option: IconOption }}
                                            />
                                                    
                                        </div>
                                      
                                        <div className="single_search_field search_location">
                                           
                                          
                                            <Select
                                            cacheOptions
                                             defaultOptions
                                             getOptionLabel={e=>e.name}
                                            onChange={(value)=>setValuePlace(value.name)}
                                             styles={customStyles}
                                            options={places}
                                            placeholder={t('home.select.places')}
                                            name="valuePlaces"
                                            />
                                        </div>
                                        <div className="atbd_submit_btn">
                                            <button type="submit" style={{zIndex:0}} onClick={handleSearch} className="btn btn-block btn-gradient btn-gradient-one btn-md btn_search">{t('home.search.button')}</button>
                                        </div>
                                    </div>
                                </form>{/* ends: .search_form */}
                                <div className="directory_home_category_area">
                                    <ul className="categories">
                                        <li> 
                                            <NavLink  to={generateLink('Restaurants')}>
                                                <span className="color-primary"><i className="la la-cutlery" /></span>
                                              {t('home.restaurauts')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={generateLink('Places & Destination')}>
                                                <span className="color-success"><i className="la la-map-marker" /></span>
                                                {t('home.Places')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink  to={generateLink('Shopping & Store')}>
                                                <span className="color-warning"><i className="la la-shopping-cart" /></span>
                                                {t('home.shopping')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink  to={generateLink("Hotel & Travel")}>
                                                <span className="color-danger"><i className="la la-bed" /></span>
                                                {t('home.hotels')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>{/* ends: .directory_home_category_area */}
                            </div>{/* ends: .col-lg-10 */}
                        </div>
                    </div>
                </div>{/* ends: .directory_search_area */}
            </Fragment>
        )
    }



 const mapStateToProps =  (state) => {
    return {
        category : state.category,
        country:state.country
    }
}

export default connect(mapStateToProps)(AdvSearch);