import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import filterRating from '../helpers/filterRating'
import Moment from 'react-moment'
import AddComment from './addComment'
// import './reviewStyle.css'
function ReadOnlyReview({login,review,users,replies,currentUserId,activeComment,setActiveComment,parentId=null,deleteComment,addComment,updateComment}) {
   // const {id,userId,date,rating,content}=props.review
  
 let infoUser=users ? Object.values(users).filter(user=>user?.email===review.userId.toString()) :[]
  const Login=()=>{
        return login
  }
  const canReply=Boolean(currentUserId) && Login()
  const canEdit=currentUserId===review.userId && Login()
  const canDelete=currentUserId===review.userId && Login()
  const isReplying=activeComment?.type==='replying' && activeComment.id===review.id && Login()
  const isEditing=activeComment?.type==='editing' && activeComment.id===review.id && Login()
  const replyId= parentId? parentId : review.id 

  return (
    <Fragment>
    <div>
                    <div className="atbd_review_top" >
                                    <div className="atbd_avatar_wrapper">
                                        <div className="atbd_review_avatar"><img alt="" src="./assets/img/review-author-thumb.jpg" className="avatar avatar-32 photo" /></div>
                                        <div className="atbd_name_time">
                                            <p>{infoUser[0]?.fullName}</p>
                                            <span className="review_time"><Moment fromNow ago>{review?.createAt}</Moment> ago</span>
                                        </div>
                                    </div>
                                    <div className="atbd_rated_stars">
                                        <ul>
                                          {review.hasRating && filterRating(review.rating).map(r=>(
                                            <li><span className={r}></span></li>
                                          ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="review_content">
                                {!isEditing && <p>{review.content}</p>}
                                {isEditing && (
                                <AddComment
                                    submitLabel="Update"
                                    hasCancelButton
                                    initialText={review.content}
                                    
                                    handleSubmit={(text) => updateComment(text, review.id)}
                                    handleCancel={() => {
                                    setActiveComment(null);
                                    }}

                                    />
                                )}
                                    {review.editAt && <div className='comment-actions text-secondary'><span className="text-dark">Derniere modification: </span> {<Moment fromNow ago>{review.editAt}</Moment>}</div>}
                                     <div className='comment-actions'>
                                        {canReply && <div className='comment-action' onClick={()=>setActiveComment({id:review.id, type:"replying"})}>Reply</div>} 
                                        {canEdit && <div className='comment-action' onClick={()=>setActiveComment({id:review.id, type:"editing"})}>Edit</div>}
                                        {canDelete && <div className='comment-action' onClick={()=>deleteComment(review.id)}>Delete</div>}
                                    </div><br/>
                                </div>
                                {
                                    isReplying && (
                                    <AddComment submitLabel="Reply" 
                                    handleSubmit={(text)=>addComment(text,replyId)
                                    } 
                                   
                                    />
                                    )
                                }         
                                { replies.length>0 &&(
                                   <div className="media-depth2">
                                   <div className="atbd_single_review"> 
                                    {
                                       replies
                                       .sort(
                                        (a, b)=>
                                        new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime()
                                        )
                                        .map(reply=>(
                                           //console.log(reply.userId)
                                            <ReadOnlyReview 
                                            key={reply.id}
                                            review={reply}
                                            currentUserId={currentUserId}
                                            activeComment={activeComment}
                                            setActiveComment={setActiveComment}
                                            deleteComment={deleteComment}
                                            updateComment={updateComment}
                                            users={users} 
                                            replies={[]}
                                            addComment={addComment}
                                            parentId={review.id}
                                            login={login}
                                            />
                                        ))
                                    }
                                   </div>
                                   </div>
                                )}
                            {/* <AddComment key={id} id_review={id}  />
                            {
                                Object.values(props.comment.commentList).filter(c=>c.id_review===id).map(
                                    (item,index)=><ReadOnlyComment comment={item} key={index} />
                                )
                            } */}
   </div>
   </Fragment>
  
    
  )
}
const mapStateToProps=(state)=>{
    return{
        users:state.users,
        comment:state.comment,
        login:state.login
    }
}

export default connect(mapStateToProps)(ReadOnlyReview)