import React, {  Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux'
import moment from 'moment/moment';
const noAction = e => e.preventDefault();
function ListingCardGrid6(props) {
    const plusIcon="la la-heart-o"
    const minusIcon="la la-heart"
    
//******************check if a list is on favourite username's list************************************************************************************************************** */
const checkMyFavourite=(identifiant) =>{
    let result=[]
    const favList=props.favourites.MyFavourite
    result=Object.values(favList).filter(item => item.listId === identifiant)
    return result.length >0
}
   
        const { list, logdIn } = props;
        const getCategoryName=(id)=>{
            return Object.values(props.cat).filter(cat=>cat.id===id)[0].label
        }
        
        return (
            <Fragment>
            {
                Object.values(list).map((value, key) => {
                    const { img, category, icon, rating, badge, title, location, price, phone, opCl, id,createAt} = value;
                    let  item=props.countView?Object.values(props.countView).filter(u=>u?.listingID===id):[]
                    return (
                    
                        <div className="col-lg-6 col-sm-6" key={key}>
                            <div className="atbd_single_listing ">
                                <article className="atbd_single_listing_wrapper">
                                    <figure className="atbd_listing_thumbnail_area">
                                        <div className="atbd_listing_image">
                                            <a href=" ">
                                                <img src={img} alt="listingimage" />
                                            </a>
                                        </div>{/*<!-- ends: .atbd_listing_image -->*/}
                                        <div className="atbd_author atbd_author--thumb">
                                            <a href=" ">
                                                <img src="./assets/img/author-thumb2.jpg" alt="AuthorImage" />
                                                <span className="custom-tooltip">Jeffery A, Owner</span>
                                            </a>
                                        </div>
                                        <div className="atbd_thumbnail_overlay_content">
                                            <ul className="atbd_upper_badge">
                                                <li><span className={"text-capitalize atbd_badge atbd_badge_"+badge}>{badge}</span></li>
                                            </ul>{/*<!-- ends .atbd_upper_badge -->*/}
                                        </div>{/*<!-- ends: .atbd_thumbnail_overlay_content -->*/}
                                    </figure>{/*<!-- ends: .atbd_listing_thumbnail_area -->*/}
                                    <div className="atbd_listing_info">
                                        <div className="atbd_content_upper">
                                            <h4 className="atbd_listing_title">
                                                <NavLink to={"/listing-details"+id}>{title}</NavLink>
                                            </h4>
                                            {
                                                logdIn === null ? (
                                                    <div className="atbd_listing_meta">
                                                        <span className="atbd_meta atbd_listing_rating">{rating}<i className="la la-star"></i></span>
                                                        <span className="atbd_meta atbd_listing_price">{"$ "+price}</span>
                                                        <span className={"atbd_meta atbd_badge_"+opCl}>{opCl} Now</span>
                                                        {/* <NavLink className=" btn btn-sm atbd_meta atbd_listing_rating" to={"/listing-details"+id}><span className="la la-eye"></span>show</NavLink> */}
                                                    </div>
                                                ) : (

                                                    <div className="atbd_card_action">
                                                        <div className="atbd_listing_meta">
                                                            <span className="atbd_meta atbd_listing_rating">4.5<i className="la la-star"></i></span>
                                                        </div>{/*<!-- ends: .atbd listing meta -->*/}
                                                        <div className="db_btn_area">
                                                            <div className="dropup edit_listing">
                                                                <NavLink onClick={noAction} to="/at_demo" role="button" className="btn btn-sm btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Edit</NavLink>
                                                                <div className="dropdown-menu">
                                                                    <NavLink onClick={noAction} className="dropdown-item" to="/at_demo"><span className="la la-edit color-primary"></span> Edit Your Listing</NavLink>
                                                                    <NavLink onClick={noAction} className="dropdown-item" to="/at_demo"><span className="la la-money color-secondary"></span> Change Your Plan</NavLink>
                                                                </div>
                                                            </div>
                                                            <NavLink onClick={noAction} to="/at_demo" className="directory_remove_btn btn btn-sm btn-outline-danger" data-toggle="modal" data-target="#modal-item-remove">Delete</NavLink>
                                                        </div> {/*<!--ends .db_btn_area-->*/}
                                                    </div>
                                                )
                                            }

                                            <div className="atbd_listing_data_list">
                                                <ul>
                                                    <li>
                                                        <p><span className="la la-map-marker"></span>{location}</p>
                                                    </li>
                                                    <li>
                                                        <p><span className="la la-phone"></span>{phone}</p>
                                                    </li>
                                                    <li>
                                                        <p><span className="la la-calendar-check-o"></span>{moment(createAt).format("DD MMMM YYYY")}</p>
                                                    </li>
                                                </ul>
                                            </div>{/*<!-- End atbd listing meta -->*/}
                                        </div>{/*<!-- end .atbd_content_upper -->*/}
                                        <div className="atbd_listing_bottom_content">
                                            <div className="atbd_content_left">
                                                <div className="atbd_listing_category">
                                                    <a href=" "><span className={"la "+icon}></span>{getCategoryName(category)}</a>
                                                </div>
                                            </div>
                                            <ul className="atbd_content_right">
                                                <li className="atbd_count"><span className="la la-eye"></span>{item.length?item[0].view:'0'}</li>
                                                <li className="atbd_save">
                                                <span title={!checkMyFavourite (id) ? "Not in your favourite !" : "In your favourite !"} className={ ! checkMyFavourite(id) ? plusIcon : minusIcon }></span>
                                                </li>
                                            </ul>
                                        </div>{/*<!-- end .atbd_listing_bottom_content -->*/}
                                    </div>{/*<!-- ends: .atbd_listing_info -->*/}
                                </article>{/*<!-- atbd_single_listing_wrapper -->*/}
                            </div>
                        </div>                        
                    )
                })
            }
            </Fragment>
        )
    }

const mapStateToProps=(state)=>{
    return{
        countView:state.countView,
        cat:state.category,
        favourites:state.favourites,
        
    }
}
export default connect(mapStateToProps)(ListingCardGrid6)
