import React, {  Fragment,useMemo,useState} from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';



function CategoryCardGrid4(props) {
    const {t}=useTranslation()
    const generateLink=name=>{
        const mylink=`/all-listings-list?cat=${name?.replaceAll('&','_')}`;
        return mylink
    }
        const { category } = props;
        
        const [listTowns,setListowns]=useState(props.towns.map(t=>t.name?.toLowerCase()))
         useMemo(()=>{
            setListowns(props.towns.map(t=>t.name.toLowerCase()))
       },[props.towns])
        const count=(cate)=>{
            
           return Object.values(props.list).filter(item=>item.category===cate && listTowns.includes(item.location?.toLowerCase())).length

        }

     
     
        return (
            <Fragment>
            {
                Object.values(category).slice(0, 9).map((value, key) => {
                    const { img,  label, icon,id } = value;
                    return (
                        <div className="col-lg-4 col-sm-6" key={key}>
                            <div className="category-single category--img">
                                <figure className="category--img4">
                                    <img src={img} alt="" />
                                    <figcaption className="overlay-bg">
                                        <NavLink  to={generateLink(label)} className="cat-box">
                                            <div>
                                                <div className="icon">
                                                    <span className={"la "+icon}></span>
                                                </div>
                                                <h4 className="cat-name">{label}</h4>
                                                <span className="badge badge-pill badge-success">{count(id)} {t('listing')}</span>
                                            </div>
                                        </NavLink>
                                    </figcaption>
                                </figure>
                            </div>{/*<!-- ends: .category-single -->*/}
                        </div>
                    )
                })
            }
            </Fragment>
        )
    }

const mapStateToProps =  (state) => {
    return {
        category : state.category,
        list:state.list.Listing,
       
       
    }
}

export default connect(mapStateToProps)(CategoryCardGrid4)
