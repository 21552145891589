import { COUNTRY_ACTION, SET_NAME_COUNTRY,SET_LABEL_COUNTRY} from "../reducers/countryReducer";

export const getCountry=(country)=>({
    type:COUNTRY_ACTION,
    payload:country

})

export const setNameCountry=(name)=>({
    type:SET_NAME_COUNTRY,
    payload:name
})
export const setLabelCountry=(name)=>({
    type:SET_LABEL_COUNTRY,
    payload:name
})



