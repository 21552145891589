import initState1 from '../../softdata.json';

const initState={
   commentList:initState1[0].comment,
   
}
var id=2;
export const FETCH_COMMENT='FETCH_COMMENT'
export const ADD_COMMENT='ADD_COMMENT'
 const commentReducer=(state=initState,action)=>{

    switch(action.type){
        case FETCH_COMMENT:
            return state;
        case  ADD_COMMENT:
            //console.log(action.payload)
            state.commentList=[...state.commentList,{id:(++id),date:Date.now(),...action.payload}]
            console.log(state)
        return state
        default:
        return state
    }
  
}
export default commentReducer