import React, { Fragment} from 'react';
//import { NavLink,Link } from 'react-router-dom';

//const noAction = e => e.preventDefault();

const ContactInfo2=({data})=> {

 const getIconClass=(currentSocialMediaName)=>{
    switch (currentSocialMediaName) {
        case 'Facebook':
            return "fab fa-facebook";
        
        case 'Twitter':
            return "fab fa-twitter";

        case 'Instagram':
            return "fab fa-instagram";
            
        case 'Youtube':
            return "fab fa-youtube";

        case 'LinkedIn':
            return "fab fa-linkedin-in";
    
        default:
           return null
    }
    
 }   

console.log(data)
if (data){
    const {adress,dialcode,phoneNumber,social_media,website,email}=data.filter[0]

    const http='https://'
   
        return (
            <Fragment>
                <div className="widget-body atbd_author_info_widget">

                    {/* <!-- ends: .atbd_avatar_wrapper --> */}
                    <div className="atbd_widget_contact_info">
                        <ul>
                            <li>
                                <span className="la la-map-marker"></span>
                                <span className="atbd_info">{adress}</span>
                            </li>
                            <li>
                                <span className="la la-phone"></span>
                                <span className="atbd_info">{dialcode+' '+phoneNumber}</span>
                            </li>
                            <li>
                                <span className="la la-envelope"></span>
                                <span className="atbd_info">{email}</span>
                            </li>
                            <li>
                                <span className="la la-globe"></span>
                                <a href={http+website} className="atbd_info">{website}</a>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- ends: .atbd_widget_contact_info --> */}
                    <div className="atbd_social_wrap">
                        {
                          social_media && social_media.length>0 ? 
                         Object.values(social_media).map((item,index)=>{
                            if (item.label!=='' && item.url!=='') {
                                const currentSoMe=Object.values(data.props.socialmedia).filter(media=>{
                                    return media.id===item.label
                                })[0].label
                                const currentIcon=getIconClass(currentSoMe)
                                return(<p key={index}><a href={item.url}><span className={currentIcon}></span></a></p>)                              
                            }
                            return null
                         }
                      ):null
                    }
                    </div>
                    {/* <!-- ends: .atbd_social_wrap --> */}
                    {/* <NavLink onClick={noAction} to="/profile" className="btn btn-outline-primary btn-block">View Profile</NavLink> */}
                </div>
            </Fragment>
        )
    
    }
    
}

export default ContactInfo2