import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';
import frJson from '../../../page/add-listing/validation-fr.json';
import $ from "jquery";
import Services from '../../../../store/service/services';
import moment from 'moment/moment';
const noAction = e => e.preventDefault();
const Report = ({data}) => {
    const {t}=useTranslation()
    const [refresh, setRefresh] = useState(false);
    const loginIn=data.props.login !== null && typeof(data.props.login.data)==='string' ? JSON.parse(data.props.login.data): data.props.login !== null ? data.props.login : null
    console.log(loginIn)
    const [reportForm,setReportForm]=useState({
    
        content:{
            name:'content',
            value:'',
            classes:'form-group',
            rules:'required|min:20|max:60'
        },
        username:{
            value:loginIn !==null ? loginIn.email : null
        },
        listId:{
            value:data.id
        },
        date:{
            value:''
        }

    })

    const [activ,setActiv]=useState(false)
   
    SimpleReactValidator.addLocale('fr',{frJson})
    const validator = useRef(new SimpleReactValidator({locale: 'fr'}));



    useEffect(() => {
        document.title = 'Report complain - HOGO Places';
      }, [refresh]);

    const onBlur = (event) => {
        const target = event.target;
        const inputIdentifier = target.name;
    
        refreshFormFeedBack(inputIdentifier);
      }

      const onChange = (event) => {
        const target = event.target;
        const inputIdentifier = target.name;
    
        const updatedForm = {...reportForm};
        const value = target.type==='checkbox' ? target.checked:target.value;
        console.log("value =>", value);
        updatedForm[inputIdentifier].value = value;
        setReportForm({...updatedForm});
        refreshFormFeedBack(inputIdentifier);
      }

      const refreshFormFeedBack = (inputIdentifier) => {
        const updatedForm = {...reportForm};
        console.log(inputIdentifier)
        const value = updatedForm[inputIdentifier].value;
        const rules = updatedForm[inputIdentifier].rules;
        const valid = validator.current.check(value, rules);
        if(valid) {
          updatedForm[inputIdentifier].classes = 'form-group';
          validator.current.hideMessageFor(reportForm[inputIdentifier].name);
        } else {
          updatedForm[inputIdentifier].classes = 'form-group invalid';
          validator.current.showMessageFor(reportForm[inputIdentifier].name);
        }
        setReportForm({...updatedForm});
      }

      const onSubmit = (event) =>{
        event.preventDefault()
        if (validator.current.allValid()) {
            setActiv(true)
            $('#modal-item-info').show()
        }else{
            validator.current.showMessages()
            setRefresh(true)
        }

      }

      const postComplain = (event) =>{
        event.preventDefault()
        const updatedForm={...reportForm}
        updatedForm['date']=moment().format('L')
        setReportForm({...updatedForm})
        const complain=Services.transformData(reportForm)
        data.props.addNewReport(complain)
        updatedForm['content'].value=''
        setReportForm({...reportForm})
        $('#modal-item-info').hide()
        //$('#report-abuse-modal').hide()
      }

                    
        return (
            <Fragment>
                <div className="modal fade" id="report-abuse-modal" tabIndex="-1" role="dialog" aria-hidden="true" aria-labelledby="atbdp-report-abuse-modal-label">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form action="/" id="atbdp-report-abuse-form" className="form-vertical">
                                <div className="modal-header">
                                    <h3 className="modal-title" id="atbdp-report-abuse-modal-label">{t('Listing.details.reportabuse')}</h3>
                                    <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <div className={reportForm['content'].classes}>
                                        <label htmlFor="atbdp-report-abuse-message" className="not_empty">{t('Listing.details.yourcomplain')}<span className="atbdp-star">*</span></label>
                                        <textarea className="form-control" 
                                        value={reportForm['content'].value}
                                        name={reportForm['content'].name}
                                        onBlur={onBlur.bind(this)}
                                        onChange={onChange.bind(this)}
                                        id="atbdp-report-abuse-message" rows="4" placeholder={t('Listing.details.messageplaceholder')} ></textarea>
                                        {validator.current.message(reportForm['content'].name,reportForm['content'].value,reportForm['content'].rules)}

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={noAction} className="btn btn-danger btn-sm" data-dismiss="modal">{t('Report.close')}</button>
                                    <button type="submit" onClick={event=>onSubmit(event)} disabled={activ} className="btn btn-secondary btn-sm">{t('Report.submit')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> 
                <div className="modal fade show" id="modal-item-info" tabIndex="-1" role="dialog" style={{display: 'none', paddingRight: '17px'}} aria-modal="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body text-center p-top-20 p-bottom-30">
                <span className="la la-exclamation-circle color-warning"></span>
                <h1 className="display-3 m-bottom-10">{t('footer.areYouSure')}</h1>
                <p className="m-bottom-30">{t('Listing.details.postcomplain')} </p>
                <div className="d-flex justify-content-center">
                  <button type="button" className="btn btn-secondary btn-sm m-right-15" data-dismiss="modal">{t('Listing.details.cancel')}</button>
                  <button type="button" onClick={event=>postComplain(event)} className="btn btn-danger btn-sm">{t('Listing.details.post')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>               
            </Fragment>
        )
    
}

export default Report;