import React, {  Fragment,  useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchReview,setReview} from '../../../store/action/reviewAction';
import AddReview from './reviewComponents/addReview';
import './reviewComponents/reviewStyle.css'
import ReadOnlyReview from './reviewComponents/readOnlyReview';


 function Review (props) {
        const [activeComment,setActiveComment]=useState(null)
        const [review,setReview]=useState([])
        const [currentPage,setCurrentPage]=useState(1)
        const [reviewsPerPage]=useState(5)
        const {id}=useParams()
 
        //Rappel: ici j'ai juste fait la gestion de l'etat, et le reducer n'ai encore apte

       useEffect(()=>{
            props.fetchReview(id)
            setReview(props.review.reviewList)
            },[props,id]) 

        const rootReview=Object.values(review).filter(
            review=>review.parentId===null
        )
    
      
   

       const len=useMemo(()=>{return Object.values(rootReview).length},[rootReview])
     
       //console.log('review',review)

       const getReplies=(commentId)=>{
        return review
        .filter((review)=>review.parentId===commentId)
        .sort(
            (a, b)=>
            new Date(a.createdAt).getTime()-new Date(b.createdAt).getTime()
            )
      }
      const addReview=(data)=>{
            console.log(data)
           setReview([data,...review])
           //props.setReviewApi(data)//Juste pour le teste
          
          
      }
      const deleteComment=(commentId)=>{

         setReview(review.filter((review)=>review.id!==commentId))
            
      }
      const addComment=(text,parentId)=>{
       const data={id:Math.random().toString(36).substr(2, 9),userId:props.currentUserId,parentId:parentId,hasRating:false,rating:null,content:text,createAt:new Date().toISOString(),editAt:null,listing_id:id}

       setReview([...review,data])
       setActiveComment(null)

      }
      const updateComment = (text, commentId) => {
       
          const updatedBackendComments =  review.map((reviewComment) => {
            if (reviewComment.id === commentId) {
              return { ...reviewComment, content: text,editAt:new Date().toISOString()};
            }
            return reviewComment;
          });
          setReview(updatedBackendComments);
          setActiveComment(null);
        
      };
      //Get current posts
      const indexOfLastReview=currentPage * reviewsPerPage;
      const indexOfFirstReview=indexOfLastReview - reviewsPerPage
      const currentReviews=rootReview.slice(indexOfFirstReview,indexOfLastReview)
      //Change page
      const [currentNumber,setCurrentNumber]=useState(1)
        const paginate=(pageNumber,e)=>{
            e.preventDefault()
            setCurrentPage(pageNumber)
            setCurrentNumber(pageNumber)
        }
        return (
            <Fragment>
                <div className="atbd_content_module atbd_review_module">
                    <div className="atbd_content_module__tittle_area">
                        <div className="atbd_area_title">
                            <h4><span className="la la-star-o"></span>{len} Review{len>1?"s":""}</h4>
                            <label htmlFor="review_content" className="btn btn-secondary btn-icon-left btn-sm not_empty"><span className="la la-star-o"></span> Add a review</label>
                        </div>
                    </div>
                    <div className="atbdb_content_module_contents">
                        <div id="client_review_list">
                            
                                {
                                    Object.values(currentReviews).map((review)=>(
                                    <Fragment key={review.id}>
                                    <div className="atbd_single_review atbdp_static">
                                        <ReadOnlyReview 
                                        key={review.id}
                                        review={review} 
                                        currentUserId={props.currentUserId}
                                        activeComment={activeComment}
                                        setActiveComment={setActiveComment}
                                        deleteComment={deleteComment}
                                        updateComment={updateComment}
                                        replies={getReplies(review.id)}
                                        addComment={addComment}
                                        />
                                    </div>
                                    </Fragment>
                                    ))

                                    
                                }
                                
                            
                            {/*<!-- ends:.atbd_single_review -->*/}
                          
                        </div>{/*<!-- ends: .client_review_list -->*/}
                          <Pagination reviewsPerPage={reviewsPerPage} totalReviews={rootReview.length} paginate={paginate} currentNumber={currentNumber}/>
                    </div>
                </div>
                {/* <!-- ends: .atbd_content_module --> */}
                <AddReview handleSubmit={addReview} currentUserId={props.currentUserId} />
                
            </Fragment>
        )
    }
const mapStateToProps=(state)=>{

   return{
    review:state.review,
    comment:state.comment,
    users: state.users
   }
    
}
const mapDispatchToProps=(dispatch)=>{

    return{
        fetchReview:(id)=>dispatch(fetchReview(id)),
       setReviewApi:dataReview=>dispatch(setReview(dataReview))
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Review)


const Pagination=({reviewsPerPage,totalReviews,paginate,currentNumber})=>{
    const pageNumbers=[]
    for(let i=1;i<=Math.ceil(totalReviews/reviewsPerPage);i++)
    {
        pageNumbers.push(i)
    }
    
    return (
        <div className="review_pagination"> 
         <ul className="pagination">
            {pageNumbers.map(number=>(
                          
                             <li className={currentNumber===number?"page-item active":"page-item "}key={number}>
                                <a className='page-link' onClick={(e)=>paginate(number,e)} href="!#">
                                    {number}
                                </a>
                            </li>
               
            ))}
             </ul>        
        </div>
    )
}