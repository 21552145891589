
import React, {Fragment,useCallback,useEffect,useState} from 'react';

import Header from '../layout/header';
import { Footer } from '../layout/footer';
import { connect } from "react-redux";
import  BreadcrumbSingle  from '../content/element/breadcrumb';
import  {ContentStory}  from '../content/element/listing-details-story';
import Gallery from '../content/element/carousel/gallery';
import  ListingFetures  from '../content/element/listing-fetures';
import { Video, Video2 } from '../content/element/video';
// import Map1 from '../content/element/map';
// import { ContactInfo } from '../content/element/contact-info';
import  Accordion  from '../content/element/accordion';
import  Review from '../content/element/review';
//import DefinitionStory from '../content/element/listing-details-story'


import ContactInfo2  from '../content/element/contact-info2';


import {BusinessInfo, TagStyle,CategoryWithIcon, SimilarListing, PopularListing } from '../content/element/widget';

import { NavLink, useParams } from 'react-router-dom';
import { ContactForm } from '../content/element/contact-form';
import Report from '../content/element/modal/report';
import Clime from '../content/element/modal/clime-list';
import { setCount } from '../../store/action/countAction';
import { ADD_NEW_CLAIM } from '../../store/reducers/claimReducer';
import { ADD_COMPLAIN } from "../../store/action/reportAction";
import { ADD_MY_FAVOURITE, GET_MY_FAVOURITE, REMOVE_MY_FAVOURITE } from "../../store/action/favouriteAction";
import { t } from 'i18next';

const noAction = e => e.preventDefault();

const ListingDetails = (props) => {
    const light = props.logo[0].light;
    //const[refresh,setRefresh]=useState(false)
    //const id = props.match.params.id;
    const params = useParams();
    const id = params.id;
    const dataForm={props,id}
    const filter = Object.values(props.list).filter((value) => {
        return value.id === id;
    })
    
    


    useEffect(() => {
        document.title = 'Listing details - HOGO Places';
      }, []);


    // useEffect(()=>{

    // })
    
   const countSystem=()=>{
        if(props.countView===null)
        {
            const init={listingID:id,view:1}
           props.setCount([init])
           console.log('null')
        }
        else{
          
            const info=Object.values(props.countView).filter(u=>u?.listingID===id)
          
            if(info.length===1)
        { 
        const exist= Object.values(props.countView).filter(u=>{
            if(u.listingID===id)
            {
                u.view++
            }
            return u
            
        })
        props.setCount(exist)
       
        
        }else{
            const vue=props.countView
            vue.push({listingID:id,view:1})
            setCount([...vue])
            
        }
    }

    }

        useEffect(()=>{
            countSystem()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])


  

    const [userConnected,setUserConnected] = useState({})

    const logIn=useCallback(()=>{
        return props.login
    },[props])
    
    useEffect(()=>{
        if(logIn())
        {
            const user=JSON.parse(localStorage.getItem('login'))
            setUserConnected(user.email)
            console.log('user',user.email)
        }   
            
        },[logIn])
   
  //*************************************************************************************************************** */
  


      const currentData={id,filter,props}
    return (
        <Fragment>
            {/* Header section start */}
            <section className="listing-details-wrapper bgimage">
                <div className="bg_image_holder"><img src="./assets/img/details-img.jpg" alt="" />
                
                </div>
                <div className="mainmenu-wrapper">
                    <Header logo={light} class="menu--dark"  />                    
                </div>
                {/* <!-- ends: .mainmenu-wrapper --> */}
                <div className="listing-info content_above">
                    <div className="container">
                        <div className="row">
                            <BreadcrumbSingle data={currentData} />
                        </div>
                    </div>
                </div>
            </section>
            {/* Header section end */}
            <section className="directory_listiing_detail_area single_area section-bg section-padding-strict">
        
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <ContentStory data={filter} /> 
                            {/* <DefinitionStory data={filter} />  pricing policy description  */}
                            <div className="atbd_content_module atbd_listing_gallery">
                                <div className="atbd_content_module__tittle_area">
                                    <div className="atbd_area_title">
                                        <h4><span className="la la-image"></span>{t('Listing.details.gallery')}</h4>
                                    </div>
                                </div>
                                <Gallery data={filter}/>
                            </div> 
                            {/* Gallery end */}

                            <ListingFetures data={currentData}/>

                            <div className="atbd_content_module atbd_custom_fields_contents">
                                <div className="atbd_content_module__tittle_area">
                                    <div className="atbd_area_title">
                                        <h4><span className="la la-youtube-play"></span>{t('Listing.details.video')}</h4>
                                    </div>
                                </div>
                                <Video data={filter}/>
                            </div>
                            {/* video end */}

                            {/* <div className="atbd_content_module">
                                <div className="atbd_content_module__tittle_area">
                                    <div className="atbd_area_title">
                                        <h4><span className="la la-map-o"></span>{t('Listing.details.location')}</h4>
                                    </div>
                                </div>
                                <div className="atbdb_content_module_contents">
                                    <div className="map" id="map-one" style={{position: 'relative'}}>
                                        <Map1 />
                                    </div>
                                </div>
                            </div> */}
                            {/* Map end */}

                            {/* <div className="atbd_content_module atbd_contact_information_module">
                                <div className="atbd_content_module__tittle_area">
                                    <div className="atbd_area_title">
                                        <h4><span className="la la-headphones"></span>Contact Information</h4>
                                    </div>
                                </div>
                                <ContactInfo2 data={filter}/>
                            </div> */}
                            {/* Contact Info end */}

                            <div className="atbd_content_module atbd_faqs_module">
                                <div className="atbd_content_module__tittle_area">
                                    <div className="atbd_area_title">
                                        <h4><span className="la la-question-circle"></span>{t('Listings.details.faqs')}</h4>
                                    </div>
                                </div>                               
                                <Accordion data={filter}/>
                            </div>
                            {/* <!-- ends: .atbd_content_module --> */}
                            <Review currentUserId={userConnected}/>
                            {/* <AddReview/>                             */}
                        </div>
                        <div className="col-lg-4">
                            <div className="widget atbd_widget widget-card">
                                <div className="atbd_widget_title">
                                    <h4><span className="la la-headphones"></span>{t('Listing.details.contactinformation')}</h4>
                                </div>
                                {/* <!-- ends: .atbd_widget_title --> */}
                                <ContactInfo2 data={currentData} />
                            </div>
                            {/* end seller info */}

                            <div className="widget atbd_widget widget_claim_listing">
                                <NavLink onClick={noAction} to="/at_demo" className="btn btn-block btn-gradient btn-gradient-two btn-lg claim-btn" data-toggle="modal" data-target="#moda_claim_listing">{t('Listing.details.claim')}</NavLink>
                            </div>{/*<!-- ends: .widget -->*/}
                            <BusinessInfo data={filter} />
                            <CategoryWithIcon category={props.cat} />
                            <TagStyle data={filter}/>

                            {/* <div className="widget atbd_widget widget-card">
                                <div className="atbd_widget_title">
                                    <h4><span className="la la-map-marker"></span> Sidebar Map</h4>
                                </div><!-- ends: .atbd_widget_title -->
                                <div className="widget-body atbdb_content_module_contents">
                                    <div className="map" id="map-two" style={{position: 'relative'}}>
                                        <Map1 styles="true" />
                                    </div>
                                </div><!-- ends: .atbdb_content_module_contents -->
                            </div><!-- ends: widget --> */}
                            <Video2 data={filter}/>

                            <div className="widget atbd_widget widget-card">
                                <div className="atbd_widget_title">
                                    <h4><span className="la la-envelope"></span> {t('Listing.details.sidebarform')}</h4>
                                </div>{/*<!-- ends: .atbd_widget_title -->*/}
                                <div className="atbdp-widget-listing-contact">
                                    <ContactForm />
                                </div>
                            </div>
                            {/* end sidebar contact */}

                            <div className="widget atbd_widget widget-card">
                                <div className="atbd_widget_title">
                                    <h4><span className="la la-list-alt"></span> Similar Listings</h4>
                                    <NavLink to="/all-listings-grid">View All</NavLink>
                                </div>{/*<!-- ends: .atbd_widget_title -->*/}
                                <div className="atbd_categorized_listings atbd_similar_listings">
                                    <SimilarListing list={props.list} />
                                </div>
                            </div>
                            {/* end similar listing */}

                            <div className="widget atbd_widget widget-card">
                                <div className="atbd_widget_title">
                                    <h4><span className="la la-list-alt"></span> Popular Listings</h4>
                                    <NavLink to="/all-listings-grid">View All</NavLink>
                                </div>{/*<!-- ends: .atbd_widget_title -->*/}
                                <div className="atbd_categorized_listings atbd_popular_listings">
                                    <PopularListing list={props.list} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           <Report data={currentData}/>
           <Clime data={dataForm}/>
           <Footer/>
        </Fragment> 
    )
}
const mapStateToProps = (state) => {
   
    return {

        list : state.list.Listing,
        logo: state.logo,
        claims:state.claim,
        category:state.category,
        country:state.setup.countries,
        socialmedia:state.socialmedia.socialmedialist,
        features:state.features,
        login:state.login,
        favourites:state.favourites,
        reports:state.reports,
        cat:state.category,
        users:state.users,

        countView:state.countView
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        setCount:(data)=>dispatch(setCount(data)),
        claimAction:data=>{dispatch({type:ADD_NEW_CLAIM,data})},
        addToMyFavourite:data => dispatch({type:ADD_MY_FAVOURITE,data}),
        removeListFromMyFavourite:data => dispatch({type:REMOVE_MY_FAVOURITE,data}),
        getMyFavourite:data => dispatch({type:GET_MY_FAVOURITE,data}),
        addNewReport : data => dispatch ({type:ADD_COMPLAIN,data})
    }
}



export default connect(mapStateToProps,mapDispatchToProps)(ListingDetails);