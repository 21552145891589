class Services{

  filterData(data,key,ID) {
    if (key) {
      return Object.values(data).filter(item=>{
          return item.id===ID
      })[0][key]
      
    } else {
      return Object.values(data).filter(item=>{
        return item.id===ID
    })[0]
    }
}

filterByName(data,key,name) {
  if (key) {
    return Object.values(data).filter(item=>{
        return item.day===name
    })[0][key]
    
  } else {
    return Object.values(data).filter(item=>{
      return item.day===name
  })[0]
  }
}


  getLocations(data,key,ID) {
    
        for (let index = 0; index < data.length; index++) {
          if (data[index].id===ID) {
           // setDialcodes(mylocation[index].dial_code)
            return data[index][key]
          }
        }
  } 
      
//*************************************************************************************** */
  assignDialcode=(Form,data,key,prop)=> {
      const updatedForm={...Form}
      if (prop){
          updatedForm[key].value[prop]=data
      }else{
          updatedForm[key].value=data
      }
      return updatedForm
  }


 // function prepare formdata for request**********************************************

    transformData=(datas)=>{
      let keyArray=Object.keys(datas)
      let newArray=Object.values(datas)
      let dataValue={}

      for (let index = 0; index < keyArray.length; index++) {
            dataValue[keyArray[index]] = newArray[index].value 
      }
      return dataValue
  }
    

}

export default new Services()