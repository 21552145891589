import initState1 from '../../softdata.json';
const initState = {
    ...initState1[0].listing
}
const categoryState={
    ...initState1[0].category
}

export  const SEARCH_ACTION='SEARCH_ACTION';
export  const SEARCH_CATEGORIES_ACTION ='SEARCH_CATEGORIES_ACTION ';
export  const SEARCH_PLACES_ACTION='SEARCH_PLACES_ACTION' 
export  const FETCH_DATA='FETCH_DATA' ; 
export const searchReducer = (state = initState, action) => {
    switch(action.type){
        case FETCH_DATA:
            state=initState
            return state
       
        case SEARCH_ACTION:
            
        const var_state= Object.values(initState);
       
                 let {search,category,place}=RestoreValue(action.payload)
                 
                if(search && category!=='All' && place!=='All')
                {
                state=var_state.filter(item=>{return item.title.toLowerCase().startsWith(search) 
                 || (item.category===category && item.location.startsWith(place))} )
               
                }
                if(search && category==='All' && place!=='All' )
                {
               
                state=var_state.filter(item=>{return item.title.toLowerCase().startsWith(search) 
                                       || item.location.startsWith(place)} )
                                             
                }
                if( search && category!=='All' && place==='All' )
                { 
                    state=var_state.filter(item=>{return item.title.toLowerCase().startsWith(search) 
                        || item.category===category} )
                              
                }
                if(search &&  category==='All' && place==='All')
                {
                    state=initState
                    return state
                }
                const params=Object.entries(action.payload)
                if(params.length===1)
                {
                    
                    switch(params[0][0])
                    {
                        case'cat':
                        
                            state=Object.values(initState).filter(item=>{return item.category===(category) });
                            return state;
                        case 'pl':
                            state=Object.values(initState).filter(item=>{return item.location.startsWith(place) });   
                            return state;
                        default:
                            return state
                    }
                }
               
            return state

         default:
                return state; 
            } 
 }
export default searchReducer;

const RestoreValue=(data)=>{
   let q= data.q?.q==="null"?'':data?.q
  let cat=data.cat?.replace('_','&')
   cat=cat?.toLowerCase()==="all"?"All":Object.values(categoryState).filter(c=>c.label===cat)[0]?.id
   let pl=data.pl?.replace('_','&')
   return {search:q,category:cat,place:pl}
}
