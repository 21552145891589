import React, {Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
export function ContentBlockHome (){
const {t}=useTranslation()

        return (
            <Fragment>
                <section className="cta section-padding border-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2>{t('home.block.content.title.why')} <span>HOGO Places</span> {t('home.block.content.title.foryourbusiness')}</h2>
                                    <p>{t('home.block.content.title')}</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-6">
                                        <img src="./assets/img/svg/illustration-1.svg" alt="" className="svg" />
                                    </div>
                                    <div className="col-lg-5 offset-lg-1 col-md-6 mt-5 mt-md-0">
                                        <ul className="feature-list-wrapper list-unstyled">
                                            <li>
                                                <div className="icon"><span className="circle-secondary"><i className="la la-check-circle"></i></span></div>
                                                <div className="list-content">
                                                    <h4>{t('home.block.content.claim.listing.title')}</h4>
                                                    <p>{t('home.block.content.claim.listing.content')}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon"><span className="circle-success"><i className="la la-money"></i></span></div>
                                                <div className="list-content">
                                                    <h4>{t('home.block.content.claim.paid.title')}</h4>
                                                    <p>{t('home.block.content.claim.paid.content')}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon"><span className="circle-primary"><i className="la la-line-chart"></i></span></div>
                                                <div className="list-content">
                                                    <h4>{t('home.block.content.claim.promote.title')}</h4>
                                                    <p>{t('home.block.content.claim.promote.content')}</p>
                                                </div>
                                            </li>
                                        </ul>{/*<!-- ends: .feature-list-wrapper -->*/}
                                        <ul className="action-btns list-unstyled">
                                            <li><NavLink onClick={noAction} to="/at_demo" className="btn btn-success">{t('home.block.content.button1')}</NavLink></li>
                                            <li><NavLink onClick={noAction} to="/at_demo" className="btn btn-primary">{t('home.block.content.button2')}</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }


export const ContentBlockAbout= ({data}) => {
    const {t}=useTranslation()
    const {about}=data
    const {About2}=about
        return (
            <Fragment>
                <section className="about-contents section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 contents-wrapper">
                                <div className="contents">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 col-sm-6">
                                            <img src={About2.image2} alt="" />
                                        </div>
                                        <div className="col-lg-6 offset-lg-1 col-sm-6 mt-5 mt-md-0">
                                            <h1>{About2.title2}</h1>
                                            <p>{About2.content2}</p>
                                        </div>
                                    </div>
                                </div>{/*<!-- ends: .contents -->*/}
                                <div className="contents">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 col-sm-6">
                                            <h1>{t('Why List on')} <span>HOGO Places</span></h1>
                                            <ul className="list-unstyled list-features p-top-15">
                                                
                                               {
                                               Object.values(About2.list).map((item,i) => {

                                                    return ( 
                                                        <li key={i}>
                                                            <div className="list-count"><span>{item.id}</span></div>
                                                            <div className="list-content">
                                                                <h4>{item.title}</h4>
                                                                <p>{item.content}</p>
                                                            </div>
                                                        </li>
                                                        )

                                               })
                                                
                                                }
                                                
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 offset-lg-1 text-right col-sm-6 mt-5 mt-md-0">
                                            <img src={About2.image3} alt="" />
                                        </div>
                                    </div>
                                </div>{/*<!-- ends: .contents -->*/}
                            </div>{/*<!-- ends: .content-block -->*/}
                        </div>
                    </div>
                </section>
                
            </Fragment>
        )
    
}

