/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import Header from '../layout/header';
import { Footer } from '../layout/footer';
import { BreadcrumbWraper } from '../content/element/breadcrumb';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import CardListingGrid4 from '../content/element/card/card-listing-grid-4';
import { LogOut } from '../../store/action/logoutAction';
import AuthorProfil from '../content/element/author-profilDetails';
import AuthorPassword from '../content/element/author-password';
import { useTranslation } from 'react-i18next';
import { DELETE_LIST } from "../../store/action/listingAction";
import Services from '../../store/service/services';
import  $  from "jquery";
import { DELETE_MY_FAVOURITE, GET_MY_FAVOURITE, REMOVE_MY_FAVOURITE,DEL_MY_FAVOUR } from '../../store/action/favouriteAction';

const noAction = e => e.preventDefault();

const AuthDeshbord = (props) => {

        const [state,setState]=useState({
            Listing:props.list.Listing,
            category:props.category,
            favouriteList:props.favourites.DataFavourite,
            myFavourite:props.favourites.MyFavourite
        })
        const {t}=useTranslation()

        const logdIn = () => {
            return props.login
        }
        const light = props.logo[0].light;
        const logOut = (e) => {
            e.preventDefault();
            props.logOutdata(null);

       }
       
      
        const userConnected = logdIn() && JSON.parse(localStorage.getItem('login')).email
  
      

       
     useMemo(() => {
        if (logdIn()) {        
            const data={email:userConnected}
            props.getMyFafouvourite(data)
            const updatedList={...state}
            updatedList.myFavourite=props.favourites.MyFavourite
            setState({...updatedList})
        }
       },[userConnected])
//*************************************************************************************************************************** */
      // const favourites={...props.favourites}

      useEffect(()=>{
        const updatedList={...state}
        updatedList.Listing=props.list.Listing
        updatedList.favouriteList=props.favourites.DataFavourite
        updatedList.myFavourite=props.favourites.MyFavourite
        setState({...updatedList})
        console.log(state.Listing)
      },[state.Listing])
//************************************************************************************************************************************** */
       const deleteMyFavourite = (event,identifiant,idList) =>{
        event.preventDefault()
        let data={id:identifiant}
        props.deleteMyFavourite(data) //delete it  favourite's list of the user
        data={listId:idList,email:userConnected}
        props.removeListFromMyFavourite(data)//remove favourite on the general data favourite list
        const updatedList={...state}
        updatedList.myFavourite=props.favourites.MyFavourite
        updatedList.favouriteList=props.list.Listing
        setState({...updatedList})
       }
//*********************************************************************************************************************************** */

       const deleteList = (event) => {
        event.preventDefault()
        let data={listId:props.list.List[0].id,email:userConnected}
        props.deleteList(data) // delete the list
        props.removeListFromMyFavourite(data) // remove the deleted list from favourite list
        props.deleteFavour(data)
        $('#modal-item-remove').hide()
        const updatedList={...state}
        updatedList.Listing=props.list.Listing
        updatedList.favouriteList=props.favourites.DataFavourite
        updatedList.myFavourite=props.favourites.MyFavourite
        setState({...updatedList})
        console.log(state.Listing)

       }



        return (
            <Fragment>
                {/* Header section start */}
                <section className="header-breadcrumb bgimage overlay overlay--dark">
                    <div className="bg_image_holder"><img src="./assets/img/breadcrumb1.jpg" alt="" /></div>
                    <div className="mainmenu-wrapper">
                        <Header logo={light} class="menu--dark" />                    
                    </div>
                    {/* <!-- ends: .mainmenu-wrapper --> */}
                    <BreadcrumbWraper title={t('Dashboard.firsttitle')} />
                </section>
                {/* Header section end */}
    
                {
                    logdIn() !== null ? (
                        <section className="dashboard-wrapper section-bg p-bottom-70">

                            <div className="dashboard-nav">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="dashboard-nav-area">
                                                <ul className="nav" id="dashboard-tabs" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="all-listings" data-toggle="tab" href="#listings" role="tab" aria-controls="listings" aria-selected="true">{t('author.mylisting')}</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">{t('author.profile')}</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="faborite-listings" data-toggle="tab" href="#favorite" role="tab" aria-controls="favorite" aria-selected="false">{t('author.favorite.listing')}</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="faborite-listings" data-toggle="tab" href="#password" role="tab" aria-controls="favorite" aria-selected="false">{t('author.change.password')}</a>
                                                    </li>
                                                </ul>
                                                <div className="nav_button">
                                                    <NavLink to="/add-listing" className="btn btn-primary"><span className="la la-plus"></span> {t('author.add.listing')}</NavLink>
                                                    <NavLink to="/at_deo" onClick={logOut} className="btn btn-secondary">{t('author.log.out')}</NavLink>
                                                </div>
                                            </div>
                                        </div>{/*<!-- ends: .col-lg-12 -->*/}
                                    </div>
                                </div>
                            </div>{/*<!-- ends: .dashboard-nav -->*/}
                            <div className="tab-content p-top-100" id="dashboard-tabs-content">
                                <div className="tab-pane fade show active" id="listings" role="tabpanel" aria-labelledby="all-listings">
                                    <div className="container">
                                        <div className="row">
                                            <CardListingGrid4 currentUserId={userConnected} logdIn={logdIn()} />
                                        </div>
                                    </div>
                                </div>{/*<!-- ends: .tab-pane -->*/}
                                <AuthorProfil currentUserId={userConnected}/>
                                <AuthorPassword currentUserId={userConnected}/>
                              
                                <div className="tab-pane fade p-bottom-30" id="favorite" role="tabpanel" aria-labelledby="faborite-listings">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="atbd_saved_items_wrapper">
                                                    <div className="atbd_content_module">
                                                        <div className="atbd_content_module__tittle_area">
                                                            <div className="atbd_area_title">
                                                                <h4><span className="la la-list"></span>{t('author.favorite.listing')}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="atbdb_content_module_contents">
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">{t('Dashboard.listing.name')}</th>
                                                                            <th scope="col">{t('Dashboard.category')}</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                            {
                                                                        Object.values(state.myFavourite).length>0 ?
                                                                            Object.values(state.myFavourite).map((item,i) => {
                                                                                console.log(state.myFavourite)
                                                                                return (
                                                                        <tr key={i}>

                                 <td><NavLink to={"/listing-details"+item.listId}>{Services.filterData(state.Listing,'title',item.listId)}</NavLink></td>
                                 <td><span className={Services.filterData(state.category,'icon',Services.filterData(props.list.Listing,'category',item.listId))}></span> <NavLink to="/at_demo" onClick={noAction}>{Services.filterData(state.category,'label',Services.filterData(state.Listing,'category',item.listId))}</NavLink></td>
                                 <td><NavLink to="/at_demo" onClick={event=>deleteMyFavourite(event,item.id,item.listId)} className="remove-favorite"><span className="la la-times"></span></NavLink></td>
                                                                            
                                                                        </tr>
                                                                                )
                                                                            })  :null  
                                                                           
                                                                        }
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>{/*<!-- ends: .atbdb_content_module_contents -->*/}
                                                    </div>
                                                </div>{/*<!--  ends: .atbd_saved_items_wrapper -->*/}
                                            </div>{/*<!-- ends: .col-lg-12 -->*/}
                                        </div>
                                    </div>
                                </div>{/*<!-- ends: .tab-pane -->*/}
                            </div>        
                        </section>
                    ) : (
                        <section className="dashboard-wrapper section-bg p-bottom-70 p-top-70">
                            <div className="container">
                                <div className="alert alert-danger" role="alert">
                                {t('message.log.in')}
                                </div>                                
                            </div>                        
                        </section>
                    )
                }

               <Footer data={deleteList}/>
            </Fragment>
        )
    }

const mapStateToProps = state => {
    return {
        list: state.list,
        login : state.login,
        logo: state.logo,
        users:state.users,
        favourites:state.favourites,
        category:state.category
    }
}
const mapDispatchToProp = dispatch => {
    return {
        logOutdata : (login) => dispatch(LogOut(login)),
        deleteMyFavourite : data => dispatch({type:DELETE_MY_FAVOURITE,data}),
        removeListFromMyFavourite:data => dispatch({type:REMOVE_MY_FAVOURITE,data}),
        getMyFafouvourite : data => dispatch({type:GET_MY_FAVOURITE,data}),
        deleteList : (data) => dispatch({type:DELETE_LIST,data}),
        deleteFavour : data => dispatch({type:DEL_MY_FAVOUR,data})
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(AuthDeshbord);