import React, { useState,Fragment } from 'react'

import $ from 'jquery'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function RecoverPassword() {
  const navigate=useNavigate()
   const {t}=useTranslation()
     const formOptions = { mode:"all" }
      const {register,handleSubmit,formState: { errors,isSubmitting,isValid}}=useForm(formOptions)

 const [alertSave,setAlertSave]=useState(false)
 const [email,setEmail]=useState(false)
   const submitEmail=(data)=>{

        console.log(data.email)   
        setEmail(data.email)  
        setAlertSave(!false)

        setTimeout(() => {
            $("#recover_modal").click();
            setAlertSave(false)
            navigate('/reset-password')
        }, 4000);
 }


  return (
    <Fragment>
          <div className="modal fade" id="recover_modal" tabIndex={-1} role="dialog" aria-labelledby="recover_modal_label" aria-hidden="true">
         
          <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="recover_modal_label"><i className="la la-lock" />{t('forgot.password')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            { alertSave &&<div className="alert alert-success alert-dismissible fade show" role="alert">
                                            {t('message.send')}<b>{email}</b>
                                            <button type="button" onClick={()=>setAlertSave(false)} className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            </div>}
                                <form action="/" onSubmit={handleSubmit(submitEmail)} id="recover-form">
                                    <div className='form-group'>
                                        <label>{t("author.profile.email")}</label>
                                    <input type="email" 
                                        className="form-control"
                                        name='email'
                                        autoComplete='off'
                                        {...register('email',{required:t('author.profile.email.message')})} />
                                        <div  className="form-text text-danger">{errors.email?.message}</div>

                                    </div>

                                    <button type="submit" disabled={isSubmitting || !isValid}   className="btn btn-block btn-lg btn-gradient btn-gradient-two">submit</button>
                                </form>
                                
                            </div>
                        </div>
                    </div>
          </div>
    </Fragment>
  )
}
