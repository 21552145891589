import React, { useState ,Fragment} from 'react'
import { useForm } from 'react-hook-form'
import Header from '../../../layout/header';
import { Footer } from '../../../layout/footer';
import { setProfil } from '../../../../store/action/profileAction';
import { connect } from 'react-redux';
import { BreadcrumbWraper } from '../breadcrumb';
import { t } from 'i18next';



function ResetPassword(props){
    const light = props.logo[0].light;
    const [alertSave,setAlertSave]=useState(false)

      const formOptions = { mode:"onChange" }
      const { register, handleSubmit,  formState } = useForm(formOptions)
      const { errors } = formState
        let [err,setErr]=useState(false)
      function onSubmit(data) {
       console.log(data)
       if(data.password!==data.confirmPwd)
       {
        setErr(!false)
       
       }else{
        setErr(false)
        setAlertSave(!false) 
       }
        
      }

    return(
        <Fragment>
           
           {/* Header section start */}
           <section className="header-breadcrumb bgimage overlay overlay--dark">
                    <div className="bg_image_holder"><img src="./assets/img/breadcrumb1.jpg" alt="" /></div>
                    <div className="mainmenu-wrapper">
                        <Header logo={light} class="menu--dark" />                    
                    </div>
                    {/* <!-- ends: .mainmenu-wrapper --> */}
                    <BreadcrumbWraper title="Author Deshbord" />
                </section>
                {/* Header section end */} <br/>
                <div className="container">
         <div className="row">
         <div className="col-md-8">
         { alertSave &&<div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <strong>{t('author.profile.message.success.title')}</strong> {t('author.profile.message.success.content')}
                                            <button type="button" onClick={()=>setAlertSave(false)} className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            </div>}
            <div className="form-group">
            <div className="atbd_author_module">
                <div className="atbd_content_module">
                    <div className="atbd_content_module__tittle_area">
                        <div className="atbd_area_title">
                            <h4><span className="la la-user"></span>{t('author.change.password')}</h4>
                        </div>
                    </div>
                    <div className="atbdb_content_module_contents">
                    <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="new_pass" className="not_empty">{t('author.new.password')}</label>
                <input id="new_pass"  type="password" placeholder={t('author.new.password.placeholder')}
                 {...register('password',{required:t('author.password.required'),minLength:{value:8,message:t("author.password.required.length")}})}
                 className={`form-control ${errors.password ? 'is-invalid' : ''}`}
               />
              
 
                 <div className="form-text text-danger">{errors.password?.message}</div>

                <label htmlFor="confirm_pass" className="not_empty">{t('author.confirm.password')}</label>
   
                <input id="confirm_pass"  type="password" placeholder={t("author.confirm.password.placeholder")} 
                {...register('confirmPwd',{required:"confirm your password ",minLength:{value:8}})}
                className={`form-control ${errors.confirmPwd ? 'is-invalid' : ''}`} />
                <div className="form-text text-danger">{errors.confirmPwd?.message}</div>
                <div className="form-text text-danger">{err && t('author.password.confirm.error')}</div>
            <br/>
        
        <button type="submit" className="btn btn-primary" id="update_user_profile">{t("author.change.profile.button.save")}</button> <br/>
       </form> 
       </div>
        </div>
    </div>
    </div>
    </div>
    <div>
       
    </div>
    </div>
    </div>
                    
           <Footer />
        </Fragment>
      

       
    )

}
const mapStateToProps = state => {
    return {        
        logo: state.logo,
        login : state.login,
        authorProfiles:state.authorProfiles,
        users: state.users,
    }
}
const mapDispatchToProp = dispatch => {
    return {
        setProfil : (profilUser) => dispatch(setProfil(profilUser)),
     
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(ResetPassword);

