import initState1 from '../../softdata.json';
import Services from '../service/services';
const initState = {
    dataCountries:initState1[0].countries, dataStates:[]
}

export const GET_STATES_BY_ID='GET_STATES_BY_ID'


const statesReducer = (state = initState, action) => { 
    switch (action.type) {
        case 'GET_STATES_BY_ID':
            state['dataStates']=Services.filterData(state['dataCountries'],'states',action.id)
            console.log(state.dataStates)
            return state;
    
        default:
            return state;
    }   
          
}
export default statesReducer;