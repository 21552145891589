/* eslint-disable react-hooks/exhaustive-deps */
//import moment from 'moment';
import React, { Fragment,useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink} from 'react-router-dom';
import Services from '../../../store/service/services';
import { FacebookShareButton,FacebookShareCount,LinkedinShareButton,TwitterShareButton } from "react-share"
import { t } from 'i18next';

const noAction = e => e.preventDefault();

export function BreadcrumbWraper(props) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="breadcrumb-wrapper content_above">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="page-title">{props.title}</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><NavLink to="/">{t('home')}</NavLink></li>
                  <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div> 
      </div>
    </Fragment>
  )
}

const BreadcrumbSingle=({data})=> {
  const { t } = useTranslation();
  const shareUrl=window.location.href
  console.log(shareUrl)
  const loginIn=data.props.login !== null && typeof(data.props.login.data)==='string' ? JSON.parse(data.props.login.data): data.props.login !== null ? data.props.login : null
  const [changeLabel,setChangeLabel]=useState('Save')
  const [changeIcon,setChangeIcon]=useState('-o')
  const { category, rating, badge, title, pricing, city, country, state,tagline,description} = data.filter[0];
  console.log(state)
  const currentCategory=Services.filterData(data.props.category,null,category)
  const currentCountry=Services.filterData(data.props.country,null,country)
  const currentState=Services.filterData(currentCountry.states,null,state)
  console.log(currentCountry)
  console.log(currentState)
  const currentCity=Services.filterData(currentState.towns,null,city)
  //const currentdate=cdate!=='' ? cdate.split('-') : null
  let taglines=[]
   tagline!==null && Object.values(tagline).length>0 && tagline[0].label !== null ? 
                Object.values(tagline).map((item,i) => {
                  return  taglines.push(item.label)
                }) : taglines=null

  //***************************************************************************************************** */

  const saveMyFavourites = (event) => {
    event.preventDefault()
    let datsa=null
    if (loginIn !== null) {
        switch (changeLabel) {
            case 'Save':
                console.log(data.props.login)
                datsa={listId:data.id,email:loginIn.email}
                data.props.addToMyFavourite(datsa)
                setChangeLabel('Remove')
                setChangeIcon('')
                break;
            case 'Remove':    
                datsa={listId:data.id,email:loginIn.email}
                data.props.removeListFromMyFavourite(datsa)
                setChangeLabel('Save')
                setChangeIcon('-o')
            break
            
            default:
                break;
        }
    }
    
  }

  useEffect(()=>{
    if (loginIn !==null) {
        const datsa={email:loginIn.email}
        data.props.getMyFavourite(datsa)
        const MyFavourite=data.props.favourites.MyFavourite
        console.log(MyFavourite)
        const value=Object.values(MyFavourite).filter(item=>{
            return item.listId===data.id
        })
        console.log(value)
        if (value.length===0) {
            setChangeLabel('Save')
            setChangeIcon('-o')
        }else{
            setChangeLabel('Remove')
            setChangeIcon('')
        }
    }
  },[changeLabel])


  const getTranslation = (label)=>{
    switch (label) {
      case 'Save':
        return t('Bread.save')
      case 'Remove':
        return t('Bread.remove')  
    
      default:
        break;
    }
}

  return (
    <Fragment>
      <div className="col-lg-8 col-md-7">
        <ul className="list-unstyled listing-info--badges">
          <li><span className={"atbd_badge atbd_badge_"+badge}>{badge}</span></li>
          <li><span className="atbd_badge atbd_badge_popular">{t('Bread.popular')}</span></li>
        </ul>
        <ul className="list-unstyled listing-info--meta">
          <li>{/**/}
              <span className="atbd_meta atbd_listing_average_pricing" data-toggle="tooltip" data-placement="top" title="" data-original-title="Average">
                  {pricing!=='' ? "$ "+pricing : t("Bread.pricing")}
              </span>
          </li> 
          <li>
              <div className="average-ratings">
                  <span className="atbd_meta atbd_listing_rating">{rating}<i className="la la-star"></i></span>
                  <span><strong>26</strong> {t('listing.detail.review')}</span>
              </div>
          </li>
          <li>
              <div className="atbd_listing_category">
                  <a href=" "><span className={currentCategory.icon}></span>{currentCategory?.label}</a>
              </div>
          </li>
        </ul>{/*<!-- ends: .listing-info-meta -->*/}
        <h1>{title}</h1>
        <p className="subtitle">{currentCity?.name}, {currentState?.name}, {currentCountry?.name}</p>
      </div>
        <div className="col-lg-4 col-md-5 d-flex align-items-end justify-content-start justify-content-md-end">
            <div className="atbd_listing_action_area">
                <div className="atbd_action atbd_save">
                    <div className="action_button">
                        <a href=" " onClick={event=>saveMyFavourites(event)} className="atbdp-favourites"><span className={"la la-heart"+changeIcon}></span> {getTranslation(changeLabel)}</a>
                    </div>
                </div>
                <div className="atbd_action atbd_share dropdown">
                    <span className="dropdown-toggle" id="social-links" data-toggle="dropdown" role="menu">
                        <span className="la la-share"></span>{t('Bread.share')}
                    </span>
                    <div className="atbd_director_social_wrap dropdown-menu" aria-labelledby="social-links">
                        <ul className="list-unstyled">
                            <li>
                                <FacebookShareButton url={shareUrl}
                                quote={title}
                                hashtag={'#'+currentCategory.label}
                                >
                                  <FacebookShareCount url={shareUrl}>
                                  {shareCount =><span className="fab fa-facebook-f color-facebook">{shareCount}</span>}
                                  </FacebookShareCount>
                                   <a onClick={noAction} href=" " target="_blank"><span className="fab fa-facebook-f color-facebook"></span>Facebook</a>
                                </FacebookShareButton>
                            </li>
                            <li>
                                <TwitterShareButton url={shareUrl}
                                title={title}
                                hashtags={taglines !==null ? taglines : []}
                                via="HOGO - Places"
                                >    
                                  <a onClick={noAction} href=" " target="_blank"><span className="fab fa-twitter color-twitter"></span>Twitter</a>
                                </TwitterShareButton>
                            </li>
                            <li>
                                <LinkedinShareButton url={shareUrl}
                                title={title}
                                source="HOGO-Places"
                                summary={description}
                                >
                                    <a onClick={noAction} href=" " target="_blank"><span className="fab fa-linkedin-in color-linkedin"></span>LinkedIn</a>
                                </LinkedinShareButton>
                            </li>
                        </ul>
                    </div>
                    {/* <!--Ends social share--> */}
                </div>
                {/* <!-- Report Abuse--> */}
                <div className="atbd_action atbd_report">
                    <div className="action_button">
                        <a href=" " data-toggle="modal" data-target="#report-abuse-modal"><span className="la la-flag-o"></span> {t('Bread.report')}</a>
                    </div>
                    {/* <!-- Modal (report abuse form) --> */}
                </div>
            </div>
            {/* <!-- ends: .atbd_listing_action_area --> */}
        </div>

    </Fragment>
  )
}

export default BreadcrumbSingle

export function BreadcrumbAbout({data}) {
  const {about}=data
  console.log(about)
  const {About1}=about
  return (
    <Fragment>
      <div className="about-intro content_above">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <h1>{About1.title1}</h1>
              <a href={About1.video1} className="video-iframe play-btn-two">
                <span className="icon"><i className="la la-youtube-play"></i></span>
                <span>{t('Listing.details.playvideo')}</span>
              </a>
            </div>
            <div className="col-lg-6 offset-lg-1 col-md-6 offset-md-0 col-sm-8 offset-sm-2">
              <img src={About1.image1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
