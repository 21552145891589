import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import RecoverPassword from '../../content/element/modal/recoverPassword';


import LogIn from '../../content/element/modal/signIn';
import Register from '../../content/element/modal/signUp'
import $ from "jquery";;

const noAction = e => e.preventDefault();

export function Footer({data}) {
  const { t, i18n } = useTranslation();
  const hogoPlaces = "HOGO Places";

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const onClose =(event) => {
    event.preventDefault()
    $('#modal-item-remove').hide()
  }

  return (
      <Fragment>
        <div className="modal fade show" id="modal-item-remove" tabIndex="-1" role="dialog" style={{display: 'none', paddingRight: '17px'}} aria-modal="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body text-center p-top-40 p-bottom-50">
                <span className="la la-exclamation-circle color-warning"></span>
                <h1 className="display-3 m-bottom-10">{t('footer.areYouSure')}</h1>
                <p className="m-bottom-30">{t('Footer.doyou')}</p>
                <div className="d-flex justify-content-center">
                  <button type="button" onClick={event => onClose(event)} className="btn btn-secondary m-right-15" >{t('Footer.cancel')}</button>
                  <button type="button" onClick={event =>data(event)} className="btn btn-danger">{t('Footer.yesdelit')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-three footer-grey p-top-95">
            <div className="footer-top p-bottom-25">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-sm-5">
                            <div className="widget widget_pages">
                                <h2 className="widget-title">{t('footer.companyInfo')}</h2>
                                <ul className="list-unstyled">
                                    <li className="page-item"><NavLink to="/about">{t('footer.aboutUs')}</NavLink></li>
                                    <li className="page-item"><NavLink to="/contact">{t('footer.contactUs')}</NavLink></li>
                                    <li className="page-item"><NavLink to="/pricing-plans">{t('footer.companyInfo.ourPricings')}</NavLink></li>
                                    <li className="page-item"><NavLink to="/contact">{t('footer.companyInfo.support')}</NavLink></li>
                                    <li className="page-item"><NavLink  to="/privacy-policy">{t('footer.companyInfo.privacyPolicy')}</NavLink></li>
                                </ul>
                            </div>
                        </div>{/* ends: .col-lg-3 */}
                        <div className="col-lg-5 d-flex justify-content-lg-center  col-sm-6">
                            <div className="widget widget_pages">
                                <h2 className="widget-title">{t('footer.helpfulLinks')}</h2>
                                <ul className="list-unstyled">
                                    <li className="page-item"><NavLink to="/about" data-toggle="modal" data-target="#signup_modal" onClick={noAction}>{t('footer.helpfulLinks.join', {hogoPlaces})}</NavLink></li>
                                    <li className="page-item"><NavLink to="/about" data-toggle="modal" data-target="#login_modal" onClick={noAction}>{t('footer.helpfulLinks.signIn')}</NavLink></li>
                                    <li className="page-item"><NavLink to="/about" >{t('footer.helpfulLinks.howItWork')}</NavLink></li>
                                    <li className="page-item"><NavLink to="/advantages" >{t('footer.helpfulLinks.advantages')}</NavLink></li>
                                </ul>
                            </div>
                        </div>{/* ends: .col-lg-3 */}
                        <div className="col-lg-5 col-sm-6">
                            <div className="widget widget_social">
                                <h2 className="widget-title">{t('footer.connectionWithUs')}</h2>
                                <ul className="list-unstyled social-list">
                                    <li><NavLink  to="/contact"><span className="mail"><i className="la la-envelope" /></span> {t('footer.contactSupport')}</NavLink></li>
                                    <li><a onClick={noAction} target="_blank" rel="noreferrer" href="/twitter"><span className="twitter"><i className="fab fa-twitter" /></span> Twitter</a></li>
                                    <li><a target="_blank" rel="noreferrer" href="https://web.facebook.com/profile.php?id=100036866695653"><span className="facebook"><i className="fab fa-facebook-f" /></span> Facebook</a></li>
                                    <li><a onClick={noAction} target="_blank" rel="noreferrer"  href="/instagram"><span className="instagram"><i className="fab fa-instagram" /></span> Instagram</a></li>
                                </ul>
                            </div>{/* ends: .widget */}
                        </div>{/* ends: .col-lg-3 */}
                        {/* <div className="col-lg-4 col-sm-6">
                            <div className="widget widget_text">
                                <h2 className="widget-title">{t('footer.onMobile', {hogoPlaces})}</h2>
                                <div className="textwidget">
                                    <p>{t('footer.onMobile.download')}</p>
                                    <ul className="list-unstyled store-btns">
                                    <li><NavLink onClick={noAction} to="/app-store" className="btn-gradient btn-gradient-two btn btn-md btn-icon icon-left"><span className="fab fa-apple" /> App Store</NavLink></li>
                                    <li><NavLink onClick={noAction} to="/google-play" className="btn btn-dark btn-md btn-icon btn-icon"><span className="fab fa-android" /> Google Play</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>{/* ends: .footer-top */}
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-bottom--content">
                                <NavLink to="/" className="footer-logo"><img src="./assets/img/logo/logo-transparent.png" alt="" /></NavLink>
                                <p className="m-0 copy-text">©2021 HOGO Places. {t('footer.made-with')} <span className="la la-heart-o" /> {t('by')} <a target="_blank" rel="noreferrer"  href="www.hogomarket.com">HOGO Market</a></p>
                                <ul className="list-unstyled lng-list">
                                    <li><NavLink onClick={() => changeLanguage('en')} to="#">English</NavLink></li>
                                    <li><NavLink onClick={() => changeLanguage('fr')} to="#">Français</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/* ends: .footer-bottom */}
        </footer>{/* ends: .footer */}
       
        <Register />
        <LogIn />
        <RecoverPassword/>
   
      </Fragment>
  )
}

