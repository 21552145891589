var item=localStorage.getItem("view")
const initState = JSON.parse(item)

export const COUNT_ACTION='COUNT_ACTION'

const countReducer = (state = initState, action) => {
    switch(action.type){
        case COUNT_ACTION :   
       // console.log(action.data)
            localStorage.setItem("view", action.data);
        return JSON.parse(localStorage.getItem('view'));  
        default:
            return state;
    }
}
export default countReducer