import initState1 from '../../softdata.json';
// const initPlace = {
//     ...initState1[0].place
// }
const initState={
   list:initState1[0].countries,
   name:'Cameroon',
   label:'cmr',
   towns:[]
}
export const COUNTRY_ACTION='COUNTRY_ACTION'
export const SET_NAME_COUNTRY="SET_NAME_COUNTRY"
export const SET_LABEL_COUNTRY="SET_LABEL_COUNTRY"
const countryReducer = (state = initState, action) => {
    switch(action.type)
    {
    case COUNTRY_ACTION:   
    
    let CountrySelected=Object.values(initState.list).filter(country=>country.label?.toLowerCase()===action.payload )
 
    let sortTowns=[]
    if(CountrySelected.length!==0)
   { Object.values(CountrySelected).forEach(item=>{
  
            item.states.map(s=>s.towns.map(t=>sortTowns.push(t)))
        }
       )
    state.name=CountrySelected[0].name
    state.label=action.payload  
    }
    else{
        CountrySelected=Object.values(initState.list).filter(country=>country.label?.toLowerCase()===initState.label )
        Object.values(CountrySelected).forEach(item=>{
  
            item.states.map(s=>s.towns.map(t=>sortTowns.push(t)))
        }
       )
       
    }
    state.towns=sortTowns
   
    return state ;
    case SET_NAME_COUNTRY:
        state.name=action.payload
        return state;
    case SET_LABEL_COUNTRY:
      state.label=action.payload
            return state
    
    default:
    return state; 
    }
    
}
export default countryReducer;