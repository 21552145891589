import React from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const ModalConfirmation=({data})=>{
    const {t}=useTranslation()
    const navigate=useNavigate()
if (data) {
    console.log(data)
    
}
        return( <div className="modal fade show" id="modal-item-info" tabIndex="-1" role="dialog" style={{display: 'none', paddingRight: '17px'}} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                                <div className="modal-body text-center p-top-40 p-bottom-50">
                                        <span className="la la-exclamation-circle color-warning"></span>
                                        <h1 className="display-3 m-bottom-10">{t('footer.areYouSure')}</h1>
                                        <p className="m-bottom-30">{t('Listing.details.doyou')}</p>
                                        <div className="d-flex justify-content-center">
                                            <button type="button" onClick={event=>{event.preventDefault();data.onSubmit(event); $('#modal-item-info').hide();navigate("/dashboard-listings",{replace:true})}} className="btn btn-primary m-right-15">{t('modal.Ok')}</button>
                                            {/* <button type="button" className="btn btn-danger">Yes, Delete it!</button> */}
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>
)
}

export default ModalConfirmation