import { t } from 'i18next';
import React, { Fragment } from 'react';

export const Video=({data})=> {

  const {img,videoUrl}=data[0]
        return (
            <Fragment>               
                {img && videoUrl!=='' ?
                (<div className="atbdb_content_module_contents">
                    <div className="video-wrapper">
                        <figure>
                            <img src={img} alt="" style={{width:670,height:440}} />
                            <figcaption>
                                <a href={videoUrl} className="video-iframe play-btn">
                                    <span className="la la-youtube-play"></span>
                                </a>
                            </figcaption>
                        </figure>
                    </div>
                </div>):
                            <div className="container">
                                <div className="alert alert-danger" role="alert">
                                {t('Listing.details.alertmessagevideo')}
                                </div>                                
                            </div>                        
                       
                }                
            </Fragment>
        )
    
}



export const Video2 = ({data}) => {
    const {img,videoUrl}=data[0]
        return (
            <Fragment>

                 {
                img && videoUrl!=='' ? 
                (<div className="widget atbd_widget widget-card">
                    <div className="atbd_widget_title">
                        <h4><span className="la la-youtube"></span>{t('Listing.details.sidebarvideo')}</h4>
                    </div>
                    <div className="widget-body atbdp-video">
                        <figure>
                            <img src={img} alt="" style={{width:290,height:180}} />
                            <figcaption>
                                <a href={videoUrl} className="video-iframe play-btn play-btn-sm"><span><i className="la la-play"></i></span></a>
                            </figcaption>
                        </figure>
                    </div>
                </div>) : null
                }
            </Fragment>
        )
    
}
